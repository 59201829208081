import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  Comment,
  ConfirmModal,
  ContentContainer,
  SessionDetails,
  ShotDetails,
  Title
} from 'shared/components';
import * as Styled from './styles';
import { PageHeader, Table } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useAppDispatch, useAppSelector, useNotifications, useReport } from 'shared/hooks';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import {
  ESnackbarStyle,
  EUserRole,
  PostedShot,
  ReportStatus,
  Session,
  SessionComment,
  ShotComment,
  User
} from 'shared/types';
import { checkRolePermission } from 'utils/role-utils';
import { Redirect } from 'react-router-dom';
import { REPORT_STATUSES, REPORT_TITLES, REPORT_TYPES } from 'shared/constants/reports';
import { parseISODateFormat } from 'utils/date-utils';
import { REPORT_COLUMNS } from './constants';
import { getReportColor } from 'utils/report-utils';
import { deleteReport, updateReport } from '../../services/api/reportsService';

type QueryParams = {
  reportId: string;
};

type TableRow = {
  key: string;
  value: string | ReactNode;
};

const REPORT_BUTTONS: { status: ReportStatus; color: string; title: string }[] = [
  { status: 'valid', color: '#52C41A', title: 'Approve' },
  { status: 'invalid', color: '#FF4F4F', title: 'Decline' },
  { status: 'pending', color: '#1677ff', title: 'Pending' }
];

const ReportPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { reportId } = useParams<QueryParams>();
  const { openNotification } = useNotifications();
  const { report, reportedObject, isReportLoading, fetchReport } = useReport();

  const user = useAppSelector((state): User | null => state.auth.user);

  useEffect((): void => {
    fetchReport(reportId, true);
  }, [fetchReport, reportId]);

  const handleOpenUser = useCallback(
    (userId: number): void => history.push(`/bow-users/${userId}`),
    [history]
  );

  const tableRows = useMemo(
    (): TableRow[] =>
      report
        ? [
            { key: 'Type', value: REPORT_TYPES[report.reportObjectType] },
            { key: 'Category', value: REPORT_TITLES[report.category] },
            {
              key: 'Status',
              value: (
                <Styled.StatusRow color={getReportColor(report.status)}>
                  {REPORT_STATUSES[report.status]}
                </Styled.StatusRow>
              )
            },
            { key: 'Created At', value: parseISODateFormat(report.createdAt, true) },
            { key: 'Description', value: report.description || '--' },
            {
              key: 'Creator',
              value: (
                <Styled.ReportLink onClick={(): void => handleOpenUser(report.reporterId)}>
                  Creator
                </Styled.ReportLink>
              )
            },
            {
              key: 'Reported User',
              value: (
                <Styled.ReportLink onClick={(): void => handleOpenUser(report.accusedId)}>
                  Reported User
                </Styled.ReportLink>
              )
            }
          ]
        : [],
    [report, handleOpenUser]
  );

  const goBack = (): void => {
    history.goBack();
  };

  const showRemoveUserModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this report?'
          confirmAction={async (): Promise<void> => {
            try {
              dispatch(closeModal());
              await deleteReport(reportId);
              history.push('/reports');
              openNotification(ESnackbarStyle.SUCCESS, 'Report successfully deleted');
            } catch (e) {
              openNotification(ESnackbarStyle.ERROR, e.message);
            }
          }}
        />
      )
    );
  };

  const showChangeStatusModal = (reportId: string, status: ReportStatus): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title={`${
            status === 'valid'
              ? 'You agree that the comment or image does not comply with the rules. After changing the status to Approved, the image or comment will be hidden'
              : 'You do not agree that the comment or image does not comply with the rules. After changing the status to Decline, the image or comment will continue to be displayed in the application'
          }. The status can be changed only once.`}
          modalTitle={`Change status to ${status === 'valid' ? 'Approved' : 'Decline'}?`}
          confirmAction={async (): Promise<void> => {
            try {
              dispatch(closeModal());
              await updateReport(reportId, { status });
              history.push('/reports');
              openNotification(ESnackbarStyle.SUCCESS, 'Report successfully updated');
            } catch (e) {
              openNotification(ESnackbarStyle.ERROR, e.message);
            }
          }}
        />
      )
    );
  };

  const buttons = useMemo(
    (): typeof REPORT_BUTTONS =>
      report ? REPORT_BUTTONS.filter((button): boolean => button.status !== report.status) : [],
    [report]
  );

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.master_admin, EUserRole.admin_user, EUserRole.moderator],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Report Details' />
        {report && reportedObject && (
          <Styled.ReportDetails>
            <Styled.TableContainer>
              <Table
                columns={REPORT_COLUMNS}
                dataSource={[
                  ...tableRows,
                  {
                    key: 'Actions',
                    value: (
                      <Styled.ReportActions>
                        {report.status === 'pending' &&
                          buttons.map(
                            (button): JSX.Element => (
                              <Styled.ReportAction
                                type='primary'
                                onClick={(): void =>
                                  showChangeStatusModal(`${report.id}`, button.status)
                                }
                                loading={isReportLoading}
                                disabled={isReportLoading}
                                color={button.color}
                              >
                                {button.title}
                              </Styled.ReportAction>
                            )
                          )}
                        <Styled.ReportAction
                          type='primary'
                          onClick={showRemoveUserModal}
                          disabled={isReportLoading}
                          loading={isReportLoading}
                          color='#FF4F4F'
                        >
                          Remove Report
                        </Styled.ReportAction>
                      </Styled.ReportActions>
                    )
                  }
                ]}
                loading={isReportLoading}
                pagination={false}
                size={'middle'}
                showHeader={false}
              />
            </Styled.TableContainer>
            <Styled.MobileContainer>
              <Styled.MobileView>
                {['picture_session', 'session'].includes(report.reportObjectType) ? (
                  <SessionDetails
                    session={reportedObject as Session}
                    reportObjectType={report.reportObjectType}
                  />
                ) : (
                  <ShotDetails
                    shot={reportedObject as PostedShot}
                    reportObjectType={report.reportObjectType}
                  />
                )}
              </Styled.MobileView>
              <Styled.MobileView>
                {reportedObject.comments.length ? (
                  (reportedObject.comments as (SessionComment | ShotComment)[]).map(
                    (comment, index): JSX.Element => {
                      const isLastComment = index === reportedObject.comments.length - 1;
                      const isReportedComment =
                        report.reportedShotCommentId === comment.id ||
                        report.reportedSessionCommentId === comment.id;

                      return (
                        <Comment
                          key={comment.id}
                          comment={comment}
                          containerStyles={{
                            margin: '0 -20px',
                            padding: `${!!index ? 15 : 0}px 20px ${!isLastComment ? 15 : 0}px`,
                            borderBottom: !isLastComment ? '1px solid #707070' : 'unset',
                            ...(isReportedComment && { border: '3px solid #1677FF' })
                          }}
                        />
                      );
                    }
                  )
                ) : (
                  <Title title='No Comments Yet' variant='title' centered />
                )}
              </Styled.MobileView>
            </Styled.MobileContainer>
          </Styled.ReportDetails>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default ReportPage;
