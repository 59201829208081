import styled from 'styled-components';
import { Button } from 'antd';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const PublicGroupDetails = styled.div`
  width: 800px;
`

export const TableContainer = styled.div`
  margin-top: 24px;
  overflow-y: auto;
`;

export const StatusRow = styled.div<{ color: string }>`
  color: ${({ color }): string => color};
`;

export const PublicGroupActions = styled.div`
  display: flex;
`;

export const PublicGroupAction = styled(Button)<{ color: string }>`
  background: ${({ color }): string => color};
  border-color: ${({ color }): string => color};

  & + & {
    margin-left: 10px;
  }

  &:hover, &:focus {
    background: ${({ color }): string => color};
    border-color: ${({ color }): string => color};
    opacity: 0.8;
  }
`;

export const Group = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 20px 0 30px;
  height: 205px;
  width: 390px;
  background-color: #363636;
`;

export const GroupImg = styled.img`
  height: 100%;
  width: 100%;
`;

export const GroupName = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px
`;

export const RadioTitle = styled.div`
 margin-bottom: 5px;
`

