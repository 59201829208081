import React, { useEffect, useState } from 'react';
import { Button, DatePicker, PageHeader, Table, Pagination, Collapse } from 'antd';
import { useHistory, useParams } from 'react-router';
import { ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { useFormik } from 'formik';
import { SHOT_COLUMNS, EVENT_COLUMNS } from './constants';
import moment from 'moment';
import { useEventsData, useNotifications, useShotsData } from 'shared/hooks';
import { modifyKeyToString } from 'utils/string-modifier-utils';
import { PaginationContainer } from 'shared/styles';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { handleSortAction } from 'utils/sorting-utils';
import { downloadDeviceData } from 'services/api/devicesService';
import { ESnackbarStyle } from 'shared/types';
import { ExportOutlined } from '@ant-design/icons';

type TableShot = {
  key: number;
  shotNumber: number;
  timestamp: string;
  deviceMode: string;
  gpsLocation: string;
  shotQuality: string;
  registered: string;
  shotScore: string;
  archived: string;
};

type TableEvent = {
  key: number;
  eventType: string;
  timestamp: string;
};

type FormValues = {
  startDate: string | null;
  endDate: string | null;
};

const BowDetailsPage = (): JSX.Element => {
  const { bowSerialNumber } = useParams<{ bowSerialNumber: string }>();
  const {
    fetchDeviceShots,
    requestShotsParams,
    setRequestShotsParams,
    shots,
    totalShots,
    setShotsSortParams,
    isShotsLoading
  } = useShotsData(bowSerialNumber);
  const {
    fetchDeviceEvents,
    requestEventsParams,
    setRequestEventsParams,
    events,
    totalEvents,
    setEventsSortParams,
    isEventsLoading
  } = useEventsData(bowSerialNumber);
  const { openNotification } = useNotifications();
  const history = useHistory();
  const { Panel } = Collapse;
  const [tableShots, setTableShots] = useState<TableShot[]>([]);
  const [tableEvents, setTableEvents] = useState<TableEvent[]>([]);

  useEffect((): void => {
    fetchDeviceShots();
  }, [fetchDeviceShots]);

  useEffect((): void => {
    fetchDeviceEvents();
  }, [fetchDeviceEvents]);

  useEffect((): void => {
    const modifiedShots = shots.map(
      (shot): TableShot => ({
        key: shot.id,
        shotNumber: shot.shotNumber,
        timestamp: moment(shot.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        deviceMode: modifyKeyToString(shot.deviceMode),
        gpsLocation: `${shot.location?.lat}, ${shot.location?.lon}`,
        shotQuality: modifyKeyToString(shot.shotQuality),
        registered: moment(shot.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        shotScore: shot.shotScore,
        archived: shot.archived
      })
    );
    setTableShots(modifiedShots);
  }, [shots]);

  useEffect((): void => {
    const modifiedEvents = events.map(
      (shot): TableEvent => ({
        key: shot.id,
        eventType: modifyKeyToString(shot.eventType),
        timestamp: moment(shot.timestamp).format('YYYY-MM-DD HH:mm:ss')
      })
    );
    setTableEvents(modifiedEvents);
  }, [events]);

  const initialValues: FormValues = {
    startDate: null,
    endDate: null
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      const { startDate, endDate } = values;
      const start = moment(startDate).toISOString();
      const end = moment(endDate).toISOString();
      setRequestShotsParams({ dateRange: { start, end }, currentPage: 1 });
      setRequestEventsParams({ dateRange: { start, end }, currentPage: 1 });
    },
    initialValues
  });

  const { startDate, endDate } = formik.values;

  const handleDateChange = (date: string, isStartDate: boolean): void => {
    formik.setFieldValue(isStartDate ? 'startDate' : 'endDate', date);
    if (!date) {
      setRequestShotsParams({ dateRange: { start: null, end: null }, currentPage: 1 });
      setRequestEventsParams({ dateRange: { start: null, end: null }, currentPage: 1 });
    }
  };

  const goBack = (): void => {
    history.goBack();
  };

  const handleExportButtonClick = async (): Promise<void> => {
    try {
      const response = await downloadDeviceData(bowSerialNumber);
      const fileName =
        response.headers['content-disposition'].split('filename=')[1].slice(1, -1) ||
        'firmware.xls';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title={`Bow details #${bowSerialNumber}`} />
        <Styled.HeaderDateForm onSubmit={formik.handleSubmit}>
          <Styled.DatePickersContainer>
            <DatePicker
              name={'startDate'}
              value={startDate ? moment(startDate) : null}
              onChange={(date, dateString): void => handleDateChange(dateString, true)}
              size='small'
              disabledDate={(current): boolean => current >= moment(endDate)}
              placeholder='Start date'
              inputReadOnly={true}
            />
            <DatePicker
              name={'endingDate'}
              value={endDate ? moment(endDate) : null}
              onChange={(date, dateString): void => handleDateChange(dateString, false)}
              size='small'
              disabledDate={(current): boolean => current <= moment(startDate)}
              placeholder='End date'
              inputReadOnly={true}
            />
          </Styled.DatePickersContainer>
          <Button
            type='primary'
            htmlType='submit'
            disabled={!(startDate && endDate) || formik.isSubmitting}
          >
            Apply
          </Button>
        </Styled.HeaderDateForm>
        <Styled.ShotTableContainer>
          <Collapse defaultActiveKey={['1']} ghost>
            <Panel header='Shots' key='1'>
              <Table
                columns={SHOT_COLUMNS}
                dataSource={tableShots}
                pagination={false}
                size={'middle'}
                loading={isShotsLoading}
                onChange={handleSortAction(setShotsSortParams)}
              />
              {totalShots > PAGE_SIZE && (
                <PaginationContainer>
                  <Pagination
                    current={requestShotsParams.currentPage}
                    total={totalShots}
                    onChange={(page: number): void =>
                      setRequestShotsParams({ ...requestShotsParams, currentPage: page })
                    }
                    pageSize={PAGE_SIZE}
                  />
                </PaginationContainer>
              )}
            </Panel>
          </Collapse>
        </Styled.ShotTableContainer>
        <Styled.TableContainer>
          <Collapse defaultActiveKey={['2']} ghost>
            <Panel header='Events' key='2'>
              <Table
                columns={EVENT_COLUMNS}
                dataSource={tableEvents}
                pagination={false}
                size={'middle'}
                loading={isEventsLoading}
                onChange={handleSortAction(setEventsSortParams)}
              />
              {totalEvents > PAGE_SIZE && (
                <PaginationContainer>
                  <Pagination
                    current={requestEventsParams.currentPage}
                    total={totalEvents}
                    onChange={(page: number): void =>
                      setRequestEventsParams({ ...requestEventsParams, currentPage: page })
                    }
                    pageSize={PAGE_SIZE}
                  />
                </PaginationContainer>
              )}
            </Panel>
          </Collapse>
        </Styled.TableContainer>
        <Styled.ExportButtonContainer>
          <Button icon={<ExportOutlined />} type='primary' onClick={handleExportButtonClick}>
            Export
          </Button>
        </Styled.ExportButtonContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default BowDetailsPage;
