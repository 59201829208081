import { AxiosResponse } from 'axios';
import ApiService from './api';
import { UpdatedUser, Pagination, User, NewUser } from 'shared/types';
import { getQueryParams, getSortQueryParams } from 'utils/query-utils';

type ChangeUserPasswordRequest = {
  updatedPassword: string;
  confirmPassword: string;
  sendEmail: boolean;
};

type UsersResponse = {
  items: User[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `users`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const updateUser = async (userId: string, user: UpdatedUser): Promise<AxiosResponse<User>> =>
  await ApiService.patch<UpdatedUser, User>(getURL(userId), user);

export const updateBowUser = async (
  userId: string,
  formData: FormData
): Promise<AxiosResponse<User>> => await ApiService.patch<FormData, User>(getURL(userId), formData);

export const changeUserPassword = async (
  userId: string,
  passwords: ChangeUserPasswordRequest
): Promise<AxiosResponse<User>> =>
  await ApiService.post<ChangeUserPasswordRequest, User>(getURL(userId, 'changePassword'), {
    ...passwords
  });

export const getUser = async (userId: string): Promise<AxiosResponse<User>> =>
  await ApiService.get<User>(getURL(userId));

export const getUsers = async (
  page: number,
  limit: number,
  sortParams: string[],
  search: string,
  showAll: boolean = false
): Promise<AxiosResponse<UsersResponse>> => {
  const params = getQueryParams({ page, limit, search, showAll });
  const urlSortParams = !!sortParams.length
    ? `&${getSortQueryParams(sortParams)}`
    : '&orderBy=-createdAt&withDisabled=true';
  return await ApiService.get(`/users?${params}${urlSortParams}`);
};

export const addUser = async (newUser: NewUser): Promise<AxiosResponse<void>> =>
  await ApiService.post<NewUser, void>(getURL('register'), newUser);

export const deleteUser = async (userId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL(userId));

export const activateUserAccount = async (userId: string): Promise<AxiosResponse<User>> =>
  await ApiService.patch<undefined, User>(getURL('confirm', userId), undefined);
