import { useCallback, useState } from 'react';
import { ESnackbarStyle, Statistics } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { getUserStatistics } from 'services/api/statisticsService';

type Result = {
  statistics: Statistics | null;
  isStatisticsLoading: boolean;
  fetchStatistics: () => Promise<void>;
};

const useStatistics = (): Result => {
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState<boolean>(true);
  const { openNotification } = useNotifications();

  const fetchStatistics = useCallback(async (): Promise<void> => {
    setIsStatisticsLoading(true);
    try {
      const response = await getUserStatistics();
      setStatistics(response.data.data);
    } catch (e: any) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsStatisticsLoading(false);
    }
  }, [openNotification]);

  return {
    statistics,
    isStatisticsLoading,
    fetchStatistics
  };
};

export default useStatistics;
