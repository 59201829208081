const SIZE = {
  tablet: '768px',
  tabletLarge: '992px',
  desktop: '1200px'
};

export const DEVICE = {
  tablet: `(max-width: ${SIZE.tablet})`,
  tabletLarge: `(max-width: ${SIZE.tabletLarge})`,
  desktop: `(max-width: ${SIZE.desktop})`
};
