import React, { useMemo } from 'react';
import { ReportObjectType, Session } from 'shared/types';
import _ from 'lodash';
import * as Styled from './styles';
import { Duration, Level, Steadiness, Torque, Target, Title } from 'shared/components';
import AvatarPlaceholder from 'assets/images/bow-ava.png';
import { formatTimestamp, getStatisticsItems, truncateName } from 'utils/report-utils';

type Props = {
  session: Session;
  reportObjectType: ReportObjectType;
};

const SessionDetails: React.FC<Props> = (props): JSX.Element => {
  const { session, reportObjectType } = props;

  const {
    shotLevel,
    shotDuration,
    shotSteadinessScore,
    shotSteadiness,
    shotTorque,
    bowLevelDegrees,
    aimTime,
    bowTorque,
    targetHitsStat,
    targetHits
  } = session.stats;

  const isOneDetail = useMemo(
    (): boolean =>
      [..._.values(session.details).filter(Boolean), !!session.sessionPictureS3Url].length === 1,
    [session.details, session.sessionPictureS3Url]
  );

  return (
    <Styled.Session>
      <Styled.UserInfo>
        <Styled.AvatarContainer>
          <Styled.Avatar src={session.user.profilePictureS3Url || AvatarPlaceholder} />
        </Styled.AvatarContainer>
        {session.user.username && (
          <Title
            title={truncateName(session.user.username, 40)}
            type='regular'
            size='lg'
            containerStyles={{ marginLeft: 7 }}
          />
        )}
      </Styled.UserInfo>
      <Styled.Date>
        <Title
          title={formatTimestamp(session.createdAt)}
          uppercase={false}
          type='defaultRegular'
          size='sm'
          color='#707070'
        />
      </Styled.Date>
      {session.details.summary && (
        <Styled.Summary>
          <Styled.Stats>
            {getStatisticsItems(session.stats).map(
              (item, index): JSX.Element => (
                <Styled.StatItem
                  key={`${item.title}_${index}`}
                  style={{ marginLeft: index > 0 ? 20 : 0 }}
                >
                  <Title
                    title={item.title}
                    variant='light'
                    titleStyles={{ marginRight: 5, paddingTop: 5 }}
                  />
                  <Title
                    title={item.value}
                    variant='light'
                    type='bold'
                    titleStyles={{ paddingTop: 5 }}
                  />
                </Styled.StatItem>
              )
            )}
          </Styled.Stats>
        </Styled.Summary>
      )}
      {!isOneDetail && <Styled.Separator />}
      {session.details.stat && (
        <Styled.ChartGroup>
          <Level value={shotLevel} bowLevelDegrees={bowLevelDegrees} />
          <Steadiness value={shotSteadinessScore} aimSteadinessValueDps={shotSteadiness} />
          <Torque value={shotTorque} bowTorque={bowTorque} />
          <Duration value={shotDuration} aimTime={aimTime} />
        </Styled.ChartGroup>
      )}
      {!isOneDetail && (session.details.target || !!session.sessionPictureS3Url) && (
        <Styled.Separator />
      )}
      {session.details.target && (
        <Styled.Target>
          <Target shoots={_.values(targetHitsStat)} targetHits={targetHits} />
        </Styled.Target>
      )}
      {!isOneDetail && !!session.sessionPictureS3Url && <Styled.Separator />}
      {session.sessionPictureS3Url && (
        <Styled.PictureSection withBorder={reportObjectType === 'picture_session'}>
          <Styled.PictureContainer>
            <Styled.Picture src={session.sessionPictureS3Url} />
          </Styled.PictureContainer>
        </Styled.PictureSection>
      )}
    </Styled.Session>
  );
};

export default SessionDetails;
