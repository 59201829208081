import { StatisticsUser } from 'shared/types';
import { AppVersionStatistics, DeviceStatistics } from './types';

export const categorizeUsersByDevice = (users: StatisticsUser[]): DeviceStatistics => {
  const categorizedUsers: DeviceStatistics = {
    iOS: [],
    Android: [],
    Unknown: []
  };

  users.forEach((user) => {
    const deviceSystemName = user.deviceInfo?.deviceSystemName?.toLowerCase();
    if (deviceSystemName === 'ios') {
      categorizedUsers.iOS.push(user);
    } else if (deviceSystemName === 'android') {
      categorizedUsers.Android.push(user);
    } else {
      categorizedUsers.Unknown.push(user);
    }
  });

  return categorizedUsers;
};

export const categorizeUsersByAppVersion = (users: StatisticsUser[]): AppVersionStatistics => {
  const categorizedUsers: AppVersionStatistics = {};

  // Extract unique app versions from users
  const appVersions = Array.from(
    new Set(users.map((user) => user.deviceInfo?.appVersion || 'unknown'))
  );

  // Initialize categories for each app version
  appVersions.forEach((version) => {
    categorizedUsers[version] = [];
  });

  users.forEach((user) => {
    const appVersion: string | undefined = user.deviceInfo?.appVersion;
    if (!!appVersion && categorizedUsers.hasOwnProperty(appVersion)) {
      categorizedUsers[appVersion].push(user);
    } else {
      categorizedUsers.unknown.push(user);
    }
  });

  return categorizedUsers;
};
