import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import * as Styled from './styles';
import { ContentContainer } from 'shared/components';
import { Button, PageHeader, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAppSelector, useBows } from 'shared/hooks';
import { EUserRole, User } from 'shared/types';
import Pagination from 'antd/lib/pagination';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { PaginationContainer } from 'shared/styles';
import { BOW_COLUMNS } from './constants';
import { checkRolePermission } from 'utils/role-utils';

type TableBow = {
  id: number;
  modelName: string;
  drawWeight: number[];
  drawLength: number[];
  letOff: number[];
  'lr/hr': string[];
};

type RowAction = {
  onClick: () => void;
};

const BowsPage = (): JSX.Element => {
  const history = useHistory();
  const [tableBows, setTableBows] = useState<TableBow[]>([]);

  const user = useAppSelector((state): User | null => state.auth.user);

  const { fetchBows, bows, isBowsLoading, currentPage, totalBows, setCurrentPage } = useBows();

  useEffect((): void => {
    fetchBows();
  }, [fetchBows]);

  useEffect((): void => {
    const modifiedBows = bows.map(
      (bow): TableBow => ({
        id: bow.id,
        modelName: bow.modelName,
        drawWeight: bow.drawWeight,
        drawLength: bow.drawLength,
        letOff: bow.letOff,
        'lr/hr': bow['lr/hr']
      })
    );
    setTableBows(modifiedBows);
  }, [bows]);

  const handleRowClick = (bow: TableBow): void => {
    history.push(`/bows/${bow.id}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Bows' />
        <Styled.PageHeader>
          <Styled.NewBowButton>
            <NavLink to='/add-bow'>
              {user?.role &&
                checkRolePermission([EUserRole.master_admin, EUserRole.admin_user], user.role) && (
                  <Button icon={<PlusCircleOutlined />}>New bow</Button>
                )}
            </NavLink>
          </Styled.NewBowButton>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            onRow={(bow): RowAction => ({ onClick: (): void => handleRowClick(bow) })}
            columns={BOW_COLUMNS}
            dataSource={tableBows}
            pagination={false}
            loading={isBowsLoading}
            size={'middle'}
          />
          {totalBows > PAGE_SIZE && (
            <PaginationContainer>
              <Pagination
                current={currentPage}
                total={totalBows}
                onChange={setCurrentPage}
                pageSize={PAGE_SIZE}
              />
            </PaginationContainer>
          )}
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default BowsPage;
