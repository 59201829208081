import { createAction, createReducer } from '@reduxjs/toolkit';
import { User, AuthState } from 'shared/types';

const initialState: AuthState = {
  isAuth: false,
  user: null
};

// Actions

export const loginUserSuccess = createAction<User | null>('auth/loginUserSuccess');
export const loginUserFail = createAction('auth/loginUserFail');
export const logoutUser = createAction('auth/logout');

// Reducer

export const authReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(
      loginUserSuccess,
      (state, action): AuthState => ({
        ...state,
        isAuth: true,
        user: action.payload
      })
    )
    .addCase(
      loginUserFail,
      (state): AuthState => ({
        ...state,
        isAuth: false,
        user: null
      })
    )

    .addCase(logoutUser, (state): AuthState => {
      localStorage.removeItem('token');
      return {
        ...state,
        isAuth: false,
        user: null
      };
    });
});
