import styled from 'styled-components';

export const Comment = styled.div`
  display: flex;
`

export const CommentDetails = styled.div`
  flex-shrink: 1;
  margin-left: 13px;
`

export const UserInfo = styled.div`
  display: flex;
`

export const AvatarContainer = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 20px;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;
