export const SESSIONS_COLUMNS = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    ellipsis: true,
    width: 50,
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    width: 130,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    width: 220
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Likes',
    dataIndex: 'likes',
    key: 'likes',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    width: 155
  },
];
