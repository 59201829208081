import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Event, EventGroup, EventUser } from 'shared/types';

const getURL = (...rest: string[]): string => {
  const mainUrl = `events`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getGroupsWithEvents = async (): Promise<AxiosResponse<EventGroup[]>> => {
  return ApiService.get(getURL('groups', 'groups-with-events'));
};

export const getGroupEvents = async (groupId: string): Promise<AxiosResponse<Event[]>> => {
  return ApiService.get(getURL(groupId, 'list-admin'));
};

export const getEventById = async (eventId: string): Promise<AxiosResponse<Event>> => {
  return ApiService.get(getURL(eventId));
};

export const getEventUsers = async (eventId: string): Promise<AxiosResponse<EventUser[]>> => {
  return ApiService.get(getURL(eventId, 'users-with-shots'));
};

export const deleteEvent = async (eventId: string): Promise<AxiosResponse<Event>> => {
  return ApiService.delete(getURL(eventId));
}
