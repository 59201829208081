import { createAction, createReducer } from '@reduxjs/toolkit';
import { SidebarState } from 'shared/types';

// Actions

export const openSidebar = createAction('sidebar/openSidebar');
export const closeSidebar = createAction('sidebar/closeSidebar');

const initialState: SidebarState = {
  isSidebarOpened: false
};

// Reducer

export const sidebarReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(openSidebar, (): SidebarState => {
      return { isSidebarOpened: true };
    })
    .addCase(closeSidebar, (): SidebarState => {
      return { isSidebarOpened: false };
    });
});
