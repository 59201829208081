import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import * as Styled from './styles';
import { ContentContainer } from 'shared/components';
import { Button, PageHeader, Table, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAppSelector, useUsers } from 'shared/hooks';
import { EUserRole, User } from 'shared/types';
import { format } from 'date-fns';
import Pagination from 'antd/lib/pagination';
import { PaginationContainer } from 'shared/styles';
import { ROLES } from 'shared/constants/roles';
import { handleSortAction } from 'utils/sorting-utils';
import { USER_COLUMNS } from './constants';
import { checkRolePermission } from 'utils/role-utils';

type TableUser = {
  id: number;
  key: number;
  firstName: string;
  role: string;
  createdAt: string;
};

type RowAction = {
  onClick: () => void;
};

const UsersPage = (): JSX.Element => {
  const history = useHistory();
  const [tableUsers, setTableUsers] = useState<TableUser[]>([]);
  const {
    users,
    pagination,
    searchValue,
    isUsersLoading,
    fetchUsers,
    setSortParams,
    setSearchValue,
    onChangePagination
  } = useUsers();
  const user = useAppSelector((state): User | null => state.auth.user);

  useEffect((): void => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect((): void => {
    const modifiedUsers = users.map(
      (user): TableUser => ({
        id: user.id,
        key: user.id,
        firstName: `${user.firstName} ${user.lastName}`,
        role: ROLES.find((item): boolean => item.value === user?.role)?.label || '',
        createdAt: format(new Date(user.createdAt), 'yyyy-MM-dd')
      })
    );
    setTableUsers(modifiedUsers);
  }, [users]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRowClick = (user: TableUser): void => {
    history.push(`/users/${user.id}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Users' />
        <Styled.PageHeader>
          <Styled.NewUserButton>
            <NavLink to='/add-user'>
              {user?.role &&
                checkRolePermission([EUserRole.master_admin, EUserRole.admin_user], user.role) && (
                  <Button icon={<PlusCircleOutlined />}>New user</Button>
                )}
            </NavLink>
          </Styled.NewUserButton>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            onRow={(user): RowAction => ({ onClick: (): void => handleRowClick(user) })}
            columns={USER_COLUMNS}
            dataSource={tableUsers}
            pagination={false}
            loading={isUsersLoading}
            size={'middle'}
            onChange={handleSortAction(setSortParams)}
          />
          <PaginationContainer>
            <Pagination
              current={pagination.currentPage}
              total={pagination.totalItemsCount}
              onChange={onChangePagination}
              pageSize={pagination.pageSize}
            />
          </PaginationContainer>
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default UsersPage;
