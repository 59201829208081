import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const HeaderDateForm = styled.form`
  display: flex;
  margin-top: 30px;

  & > button {
    margin-left: 30px;
  }
`;

export const DiagramContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;

  @media (max-width: 530px) {
    height: 370px;
    padding-top: 30px;
  }
`;

export const DatePickersContainer = styled.div`
  display: flex;

  & > div,
  input {
    cursor: pointer;
  }

  & > div:last-child {
    margin-left: 20px;
  }
`;

export const ShotTableContainer = styled.div`
  margin-top: 30px;
  overflow: auto;

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const TableContainer = styled.div`
  overflow: auto;

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const ExportButtonContainer = styled.div`
  margin-top: 24px;

  button {
    width: min-content;
  }
`;
