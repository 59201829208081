import { EUserRole } from 'shared/types';

type Role = {
  label: string;
  value: EUserRole;
};

export const ROLES: Role[] = [
  {
    label: 'User',
    value: EUserRole.support_user
  },
  {
    label: 'Admin',
    value: EUserRole.admin_user
  },
  {
    label: 'Moderator',
    value: EUserRole.moderator
  }
];
