import styled from 'styled-components';
import { Button, Input, Select } from 'antd';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormValuesContainer = styled.div`
  width: 100%;
  height: 88px;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  height: 68px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
`;

export const FormTags = styled.div`
  margin-top: 5px;
`;

export const FormInput = styled(Input)`
  height: 100%;
  margin-right: 20px;
`;

export const FormSelect = styled(Select)`
  font-size: 16px;
  width: 100%;
  .ant-select-selector {
    height: 40px;
  }
`

export const FormRange = styled.div`
  flex: 1;
  &:last-child {
    margin-left: 20px;
  }
`;

export const FormAddButton = styled(Button)`
  height: 100%;
`;
