import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const UploadForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
`;

export const UploadFormFieldContainer = styled.div`
  height: 68px;

  &.textarea {
    height: auto;
    margin-bottom: 24px;
  }
`;

export const UploadArea = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-bottom: 24px;
`;

export const FormatText = styled.span`
  position: absolute;
  right: 0;
  margin-top: 5px;
  color: #ccc;
  font-weight: bold;
`;

export const RadioGroupContainer = styled.div``;

export const ScheduledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-typography {
    margin-right: 10px;
  }

  .ant-picker-input > input {
    width: 150px;
    min-width: 114px;

    @media ${DEVICE.tablet} {
      width: 100%;
    }
  }
`;

export const RemoveButton = styled.div`
  margin-top: 30px;
  width: max-content;

  & button {
    background: #f5222d;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s;
  }

  & button:hover {
    opacity: 0.8;
    background: #f5222d;
    color: #fff;
    border: 1px solid #fff;
  }
`;

export const DownloadButton = styled.div`
  margin-top: 20px;
  width: max-content;
`;
