import React, { useEffect, useMemo, useRef, useState } from 'react';
import { pick, isUndefined } from 'lodash';
import { Card, Modal, Statistic, Table, Spin, Row, Col, Tooltip, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useUserDeviceStatistics } from 'shared/hooks/data-hooks/statistics';
import { categorizeUsersByAppVersion, categorizeUsersByDevice } from './helper';
import {
  columns,
  expandedRowColumns,
  defaultDeviceStatistics,
  defaultModalState
} from './constants';
import { UserDeviceStatisticsUser } from 'shared/types';
import { AppVersionStatistics, DeviceStatistics, ModalState } from './types';

const UserDeviceStatisticsPage = () => {
  const [modalState, setModalState] = useState<ModalState>(defaultModalState);
  const { fetchStatistics, statistics, isStatisticsLoading } = useUserDeviceStatistics();

  // @ts-ignore
  const searchInputRef = useRef<Input>(null);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  const deviceStatistics: DeviceStatistics = useMemo(() => {
    if (!statistics) return defaultDeviceStatistics;
    return categorizeUsersByDevice(statistics.users);
  }, [statistics]);

  const appVersionStatistics: AppVersionStatistics = useMemo(() => {
    if (!statistics) return {};
    return categorizeUsersByAppVersion(statistics.users);
  }, [statistics]);

  const defaultExpandedRowKeys = useMemo(
    () => modalState.data.map((record: UserDeviceStatisticsUser) => record.id),
    [modalState.data]
  );

  const handleDeviceCardClick = (deviceSystemName: keyof DeviceStatistics): void => {
    const data = deviceStatistics[deviceSystemName];
    if (data) {
      setModalState({
        title: `${deviceSystemName} Device users`,
        search: '',
        visible: true,
        data
      });
    }
  };

  const handleAppVersionCardClick = (appVersion: keyof AppVersionStatistics): void => {
    const data = appVersionStatistics[appVersion];
    if (data) {
      setModalState({
        title: `${appVersion} version users`,
        search: '',
        visible: true,
        data
      });
    }
  };

  const handleModalCancel = (): void => {
    setModalState(defaultModalState);
    if (searchInputRef.current) {
      searchInputRef.current.setValue(''); // Reset input value using ref
    }
  };

  const handleSearch = (search: string): void => {
    setModalState((prevState) => ({ ...prevState, search }));
  };

  const filteredData = useMemo(() => {
    if (!modalState.search) return modalState.data;
    return modalState.data.filter((record: UserDeviceStatisticsUser) =>
      Object.values(pick(record, ['username', 'email', 'first_name', 'last_name'])).some(
        (value) =>
          !isUndefined(value) &&
          String(value).toLowerCase().includes(modalState.search.toLowerCase())
      )
    );
  }, [modalState.search, modalState.data]);

  const renderTooltipIcon = (title: string) => (
    <Tooltip title={title}>
      <QuestionCircleOutlined style={{ position: 'absolute', top: 10, right: 10, fontSize: 16 }} />
    </Tooltip>
  );

  return (
    <Spin spinning={isStatisticsLoading} size='large' tip='Loading...'>
      <Row gutter={[16, 16]} style={{ padding: '16px 24px' }}>
        {/* Activity */}
        <Col span={8}>
          <h1>Activity</h1>
          <Card style={{ marginBottom: 16 }}>
            {renderTooltipIcon('Total number of users who have used the app')}
            <Statistic title='Total app users' value={statistics?.statistics.appUserCount || 0} />
          </Card>
          <Card style={{ marginBottom: 16 }}>
            {renderTooltipIcon(
              'Number of users who have posted at least one shot in the last month'
            )}
            <Statistic title='Active users' value={statistics?.statistics.activeUsersCount || 0} />
          </Card>
          <Card style={{ marginBottom: 16 }}>
            {renderTooltipIcon('Number of users who have not posted any shot in the last month')}
            <Statistic
              title='Not active users'
              value={statistics?.statistics.notActiveUsersCount || 0}
            />
          </Card>
          <Card style={{ marginBottom: 16 }}>
            {renderTooltipIcon('Number of users who have published at least one shot')}
            <Statistic
              title='Users with shots'
              value={statistics?.statistics.userWithShotsCount || 0}
            />
          </Card>
          <Card style={{ marginBottom: 16 }}>
            {renderTooltipIcon('Number of users who have not published any shot')}
            <Statistic
              title='Users without shots'
              value={statistics?.statistics.userWithoutShotsCount || 0}
            />
          </Card>
        </Col>

        {/* Device */}
        <Col span={8}>
          <h1>Device</h1>
          <Card
            onClick={() => deviceStatistics.iOS.length > 0 && handleDeviceCardClick('iOS')}
            style={{
              cursor: 'pointer',
              border: '1px solid #1890ff',
              marginBottom: 16
            }}
          >
            {renderTooltipIcon('Number of users using iOS devices')}
            <Statistic title='iOS users' value={deviceStatistics.iOS.length || 0} />
          </Card>
          <Card
            onClick={() => deviceStatistics.Android.length > 0 && handleDeviceCardClick('Android')}
            style={{
              cursor: 'pointer',
              border: '1px solid #1890ff',
              marginBottom: 16
            }}
          >
            {renderTooltipIcon('Number of users using Android devices')}
            <Statistic title='Android users' value={deviceStatistics?.Android.length || 0} />
          </Card>
          <Card
            onClick={() => deviceStatistics.Unknown.length > 0 && handleDeviceCardClick('Unknown')}
            style={{
              cursor: 'pointer',
              border: '1px solid #1890ff',
              marginBottom: 16
            }}
          >
            {renderTooltipIcon('Number of users with unknown devices')}
            <Statistic title='Unknown device users' value={deviceStatistics?.Unknown.length || 0} />
          </Card>
        </Col>

        {/* App version */}
        <Col span={8}>
          <h1>App version</h1>
          {Object.keys(appVersionStatistics).map((appVersion) => (
            <Card
              key={appVersion}
              style={{
                cursor: 'pointer',
                border: '1px solid #1890ff',
                marginBottom: 16
              }}
              onClick={() => handleAppVersionCardClick(appVersion)}
            >
              {renderTooltipIcon(`Number of users using ${appVersion} app version`)}
              <Statistic
                title={`${appVersion} version`}
                value={appVersionStatistics[appVersion].length}
              />
            </Card>
          ))}
        </Col>
      </Row>

      <Modal
        title={modalState.title}
        visible={modalState.visible}
        onOk={handleModalCancel}
        onCancel={handleModalCancel}
        width={'80%'}
        cancelButtonProps={{ hidden: true }}
      >
        <Input.Search
          ref={searchInputRef}
          placeholder='Search by username, first name, last name, or email'
          onSearch={(value) => handleSearch(value)}
          style={{ width: 440, marginBottom: 16, marginLeft: 24 }}
        />

        <Table
          dataSource={filteredData}
          columns={columns}
          expandable={{
            expandedRowRender: (record: UserDeviceStatisticsUser) =>
              record.devices.length > 0 ? (
                <Table
                  columns={expandedRowColumns}
                  dataSource={record.devices}
                  pagination={false}
                />
              ) : null,
            rowExpandable: (record) => record.devices.length > 0,
            defaultExpandedRowKeys
          }}
          rowKey={'id'}
          scroll={{ x: 850 }}
        />
      </Modal>
    </Spin>
  );
};

export default UserDeviceStatisticsPage;
