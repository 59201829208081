import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { AuthState, EUserRole } from 'shared/types';

const NotGuardedRoute: React.FC<RouteProps> = ({ ...routeProps }): JSX.Element => {
  const { isAuth, user } = useAppSelector((state): AuthState => state.auth);

  if (isAuth && user)
    return <Redirect to={user.role === EUserRole.moderator ? 'reports' : '/sessions'} />;

  return <Route {...routeProps} />;
};

export default NotGuardedRoute;
