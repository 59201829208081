import React, { useEffect, useState } from 'react';
import Pagination from 'antd/lib/pagination';
import { PageHeader, Table, Typography } from 'antd';
import * as Styled from './styles';
import { ConfirmModal, ContentContainer } from 'shared/components';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';
import { useSessionComments, useAppDispatch, useNotifications } from 'shared/hooks';
import { useParams } from 'react-router';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { COMMENTS_COLUMNS } from './constants';
import { useHistory } from 'react-router-dom';

type TableComment = {
  id:            number;
  content:        string;
  createdAt:      string;
  updatedAt:      string;
  user: {
    email:    string;
    username: string;
  }
}

type QueryParams = {
  sessionId: string;
};

const SessionCommentsPage = (): JSX.Element => {
  const history = useHistory();
  const [tableComments, setTableComments] = useState<TableComment[]>([]);
  const { sessionId } = useParams<QueryParams>();
  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const {
    sessionComments,
    removeSessionComment,
    isSessionLoading,
    totalComments,
    currentPage,
    setCurrentPage,
    fetchSessionComments
  } = useSessionComments();

  useEffect((): void => {
    fetchSessionComments(sessionId);
  }, [fetchSessionComments, sessionId]);

  useEffect((): void => {
    const modifiedComments = sessionComments.map((comment): TableComment => {
      return {
        id:         comment.id,
        content:    comment.content,
        createdAt:  format(new Date(comment.createdAt), 'yyyy-MM-dd HH:mm:ss'),
        updatedAt:  format(new Date(comment.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
        user: {
          username: comment.user.username,
          email:    comment.user.email,
        }
      };
    });
    setTableComments(modifiedComments);
  }, [sessionComments, currentPage]);

  const handleRemoveComment = (comment: TableComment): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this comment?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeSessionComment(comment.id.toString());
            await fetchSessionComments(sessionId);
            openNotification(ESnackbarStyle.SUCCESS, 'Comment successfully deleted');
          }}
        />
      )
    );
  };

  const goBack = (): void => {
    history.goBack();
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title={`Session comments`} />
        <Styled.TableContainer>
          <Table
            columns={[
              ...COMMENTS_COLUMNS,
              {
                title: 'Actions',
                width: 100,
                render: (record): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): void => handleRemoveComment(record)}>Remove</Typography.Link>
                  );
                }
              }]}
            dataSource={tableComments}
            pagination={false}
            loading={isSessionLoading}
            size={'middle'}
          />
        </Styled.TableContainer>
        {totalComments > PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalComments}
              onChange={setCurrentPage}
              pageSize={PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  )
}

export default SessionCommentsPage;
