import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Input } from 'antd';
import Pagination from 'antd/lib/pagination';
import * as Styled from './styles';
import { useSessions } from 'shared/hooks';
import { SESSIONS_COLUMNS } from './constants';
import { ContentContainer } from 'shared/components';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';

type TableSession = {
  id:         string;
  key:        number;
  createdAt:  string;
  name:       string;
  username:   string;
  email:      string;
  comments:   number;
  likes:      number;
}

type RowAction = {
  onClick: () => void;
};

const SessionsCommunityPage = (): JSX.Element => {
  const history = useHistory();
  const [tableSessions, setTableSessions] = useState<TableSession[]>([]);

  const {
    sessions,
    pagination: { currentPage, pageSize, totalItemsCount },
    searchValue,
    isSessionsLoading,
    fetchSessions,
    setSearchValue,
    onChangePagination
  } = useSessions();

  useEffect((): void => {
    fetchSessions({ withoutShots: true });
  }, [fetchSessions]);

  useEffect((): void => {
    let i = (currentPage - 1) * pageSize;

    const modifiedSessions = sessions.map(
      (session): TableSession => {
        i += 1;

        return {
          id:             session.id,
          key:            i,
          createdAt:      format(new Date(session.createdAt), 'yyyy-MM-dd HH:mm:ss'),
          name:           session.sessionName,
          username:       session.user.username,
          email:          session.user.email,
          comments:       session.comments.length,
          likes:          session.likes.length,
        }
      }
    );
    setTableSessions(modifiedSessions);
  }, [sessions, currentPage, pageSize]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRowClick = (session: TableSession): void => {
    history.push(`/sessions/${session.id}/comments`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Sessions Community Data' />
        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            onRow={(session): RowAction => ({ onClick: (): void => handleRowClick(session) })}
            columns={SESSIONS_COLUMNS}
            dataSource={tableSessions}
            pagination={false}
            loading={isSessionsLoading}
            size={'middle'}
          />
        </Styled.TableContainer>
        <PaginationContainer>
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            total={totalItemsCount}
            onChange={onChangePagination}
          />
        </PaginationContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default SessionsCommunityPage;
