export const SHOT_COLUMNS = [
  {
    title: 'Shot #',
    dataIndex: 'shotNumber',
    key: 'shotNumber',
    sortKey: 'shotNumber',
    ellipsis: true,
    sorter: {
      multiple: 1
    },
    width: 100
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sortKey: 'timestamp',
    ellipsis: true,
    sorter: {
      multiple: 1
    },
    width: 150
  },
  {
    title: 'Bow mode',
    dataIndex: 'deviceMode',
    key: 'deviceMode',
    sortKey: 'deviceMode',
    ellipsis: true,
    sorter: {
      multiple: 1
    },
    width: 140
  },
  {
    title: 'GPS location',
    dataIndex: 'gpsLocation',
    key: 'gpsLocation',
    sortKey: 'shot.location.lat',
    ellipsis: true,
    width: 170
  },
  {
    title: 'Shot status',
    dataIndex: 'shotQuality',
    key: 'shotQuality',
    sortKey: 'shotQuality',
    ellipsis: true,
    sorter: {
      multiple: 1
    },
    width: 120
  },
  {
    title: 'Registered',
    dataIndex: 'registered',
    key: 'registered',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Score',
    dataIndex: 'shotScore',
    key: 'shotScore',
    ellipsis: true,
    sortKey: 'shotScore',
    width: 80,
    sorter: {
      multiple: 1
    }
  },
  {
    title: 'Archived',
    dataIndex: 'archived',
    key: 'archived',
    sortKey: 'archived',
    ellipsis: true,
    sorter: {
      multiple: 1
    }
  }
];

export const EVENT_COLUMNS = [
  {
    title: 'Event',
    dataIndex: 'eventType',
    key: 'eventType',
    sortKey: 'eventType',
    ellipsis: true,
    sorter: {
      multiple: 1
    },
    width: 100
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sortKey: 'timestamp',
    sorter: {
      multiple: 1
    },
    ellipsis: true
  }
];
