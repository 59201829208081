import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EventGroup, EventStatus } from 'shared/types';

export const COLUMNS: ColumnsType<EventGroup> = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Upcoming',
    key: 'created',
    dataIndex: 'created',
    ellipsis: true,
    sorter: (a: EventGroup, b: EventGroup) =>
      a.eventCountByStatus.created - b.eventCountByStatus.created,
    render: (__, record: EventGroup) => (
      <span>{renderStatusTag('created', record.eventCountByStatus.created) ?? '-'}</span>
    )
  },
  {
    title: 'Past',
    key: 'finished',
    dataIndex: 'finished',
    ellipsis: true,
    sorter: (a: EventGroup, b: EventGroup) =>
      a.eventCountByStatus.finished - b.eventCountByStatus.finished,
    render: (__, record: EventGroup) => (
      <span>{renderStatusTag('finished', record.eventCountByStatus.finished) ?? '-'}</span>
    )
  },
  {
    title: 'Ongoing',
    key: 'active',
    dataIndex: 'active',
    ellipsis: true,
    sorter: (a: EventGroup, b: EventGroup) =>
      a.eventCountByStatus.active - b.eventCountByStatus.active,
    render: (__, record: EventGroup) => (
      <span>{renderStatusTag('active', record.eventCountByStatus.active) ?? '-'}</span>
    )
  }
];

export function renderStatusTag(status: EventStatus, count: number): JSX.Element {
  switch (status) {
    case 'created':
      return (
        <Tag color='blue' style={{ minWidth: 30, textAlign: 'center' }}>
          {count}
        </Tag>
      );
    case 'finished':
      return (
        <Tag color='volcano' style={{ minWidth: 30, textAlign: 'center' }}>
          {count}
        </Tag>
      );
    case 'active':
      return (
        <Tag color='green' style={{ minWidth: 30, textAlign: 'center' }}>
          {count}
        </Tag>
      );
    default:
      return <span>{status || '-'}</span>;
  }
}
