import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Input, Typography } from 'antd';
import Pagination from 'antd/lib/pagination';
import * as Styled from './styles';
import { useSessions, useAppDispatch, useNotifications } from 'shared/hooks';
import { SESSIONS_COLUMNS } from './constants';
import { ConfirmModal, ContentContainer } from 'shared/components';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { SHOTS_COLUMNS } from 'pages/user-shots-page/constants';

type SessionStats = {
  score:          number;
  shots:          number;
  shotDuration:   number;
  shotLevel:      number;
  shotSteadiness: number;
  shotTorque:     number;
}

type SessionShot = {
  name:                       string;
  shotScoreTotal:             number;
  shotScoreAimSteadiness:     number;
  shotScoreBowAngle:          number;
  shotScoreAimTime:           number;
  shotScoreBowTorque:         number;
  valid:                      string;
  timestamp:                  string;
}

type TableSession = {
  id:         string;
  key:        number;
  createdAt:  string;
  name:       string;
  stats:      SessionStats;
  username:   string;
  email:      string
  shots:      SessionShot[];
}

const SessionsPage = (): JSX.Element => {
  const [tableSessions, setTableSessions] = useState<TableSession[]>([]);
  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const {
    sessions,
    pagination: { currentPage, pageSize, totalItemsCount },
    searchValue,
    isSessionsLoading,
    fetchSessions,
    removeSession,
    setSearchValue,
    onChangePagination
  } = useSessions();

  useEffect((): void => {
    fetchSessions();
  }, [fetchSessions]);

  useEffect((): void => {
    let i = (currentPage - 1) * pageSize;
    const modifiedSessions = sessions.map(
      (session): TableSession => {
        i += 1;
        return {
          id:               session.id,
          key:              i,
          createdAt:        format(new Date(session.createdAt), 'yyyy-MM-dd HH:mm:ss'),
          name:             session.sessionName,
          stats: {
            score:          session.stats?.score,
            shots:          session.stats?.shots,
            shotDuration:   session.stats?.shotDuration,
            shotLevel:      session.stats?.shotLevel,
            shotSteadiness: session.stats?.shotSteadiness,
            shotTorque:     session.stats?.shotTorque,
          },
          username:         session.user.username,
          email:            session.user.email,
          shots:            (session.shots ?? []).map((shot): SessionShot => ({
            name: shot.name,
            shotScoreTotal: shot.shotScoreTotal,
            shotScoreAimSteadiness: shot.shotScoreAimSteadiness,
            shotScoreBowAngle: shot.shotScoreBowAngle,
            shotScoreAimTime: shot.shotScoreAimTime,
            shotScoreBowTorque: shot.shotScoreBowTorque,
            valid: shot.isShotDetected ? 'True' : 'False',
            timestamp: format(new Date(shot.timestamp), 'yyyy-MM-dd HH:mm:ss'),
          }))
        }
      }
    );
    setTableSessions(modifiedSessions);
  }, [sessions, currentPage, pageSize]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRemoveSession = (session: TableSession): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this session?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeSession(session.id);
            await fetchSessions();
            openNotification(ESnackbarStyle.SUCCESS, 'Session successfully deleted');
          }}
        />
      )
    );
  };

  // const handleRowClick = (session: TableSession): void => {
  //   history.push(`/sessions/${session.id}/comments`);
  // };

  return (
    <ContentContainer containerStyle={{ maxWidth: '1350px' }}>
      <Styled.PageContainer>
        <PageHeader title='Sessions' />
        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            // onRow={(session): RowAction => ({ onClick: (): void => handleRowClick(session) })}
            columns={[
              ...SESSIONS_COLUMNS,
              {
                title: 'Actions',
                width: 100,
                render: (record): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): void => handleRemoveSession(record)}>
                      Remove
                    </Typography.Link>
                  );
                }
              }
            ]}
            dataSource={tableSessions}
            pagination={false}
            loading={isSessionsLoading}
            size={'middle'}
            expandable={{
              expandedRowRender: (record): JSX.Element => (
                <Table
                  columns={SHOTS_COLUMNS}
                  dataSource={record.shots}
                  pagination={false}
                  loading={isSessionsLoading}
                />
              )
            }}
          />
        </Styled.TableContainer>
        <PaginationContainer>
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            total={totalItemsCount}
            onChange={onChangePagination}
          />
        </PaginationContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default SessionsPage;
