import React from 'react';
import { formatNumber } from 'utils/report-utils';
import { getActiveColorDuration } from './helpers';
import * as Styled from './styles';
import { Title } from 'shared/components';

type Props = {
  value?: number;
  aimTime?: number;
  activeColor?: string;
};

const Duration: React.FC<Props> = ({ value, aimTime, activeColor = '#FF8800' }): JSX.Element => {
  const formattedValue = formatNumber(aimTime);
  const showFirstSegment = aimTime && aimTime > 0;
  const showSecondSegment = aimTime && aimTime >= 3;
  const showThirdSegment = aimTime && aimTime >= 5;
  activeColor = getActiveColorDuration(value, activeColor);

  return (
    <Styled.Chart>
      <svg height='52' width='52' viewBox='0 0 52 52'>
        <path
          fill='#707070'
          d='M20.5 4.5v4h11v-4zm16.67 4.41-2.67 2.97A16.37 16.37 0 0 0 26 9.5C16.9 9.5 9.5 16.9 9.5 26S16.9 42.5 26 42.5 42.5 35.1 42.5 26c0-4.54-1.85-8.67-4.84-11.65l2.49-2.76zM26 12.5c7.47 0 13.5 6.03 13.5 13.5S33.47 39.5 26 39.5 12.5 33.47 12.5 26 18.53 12.5 26 12.5z'
        />
        {showFirstSegment ? (
          <path fill={activeColor} d='m27.1 25.2-.02-11.25a12 12 0 0 1 9.93 16.98z' />
        ) : null}
        {showSecondSegment ? (
          <path fill={activeColor} d='m16.39 32.95 9.72-5.6 9.72 5.6a12 12 0 0 1-19.44 0z' />
        ) : null}
        {showThirdSegment ? (
          <path
            fill={activeColor}
            d='M14.1 25.91a12 12 0 0 1 10.82-11.94L24.9 25.2l-9.75 5.64a11.96 11.96 0 0 1-1.05-4.92z'
          />
        ) : null}
      </svg>
      <Title title={formattedValue ? formattedValue + 'S' : '--'} variant='light' type='regular' />
    </Styled.Chart>
  );
};

export default Duration;
