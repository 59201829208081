import React from 'react';
import { TitleProps } from './types';
import * as Styled from './styles';

const Title = (props: TitleProps): JSX.Element => {
  const { containerStyles, title, ...rest } = props;

  return (
    <Styled.TitleContainer style={containerStyles}>
      <Styled.Title {...rest}>{title}</Styled.Title>
    </Styled.TitleContainer>
  );
};

export default Title;
