import styled from 'styled-components';
import { Button, Typography } from 'antd';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const ReportDetails = styled.div`
  width: 800px;
`

export const TableContainer = styled.div`
  margin-top: 24px;
  overflow-y: auto;
`;

export const StatusRow = styled.div<{ color: string }>`
  color: ${({ color }): string => color};
`;

export const ReportLink = styled(Typography.Link)`
  & + & {
    margin-left: 10px;
  }
`;

export const ReportActions = styled.div`
  display: flex;
`;

export const ReportAction = styled(Button)<{ color: string }>`
  background: ${({ color }): string => color};
  border-color: ${({ color }): string => color};

  & + &:not(:last-child) {
    margin-left: 10px;
  }

  &:last-child:not(:first-child) {
    margin-left: auto;
  }

  &:hover {
    background: ${({ color }): string => color};
    border-color: ${({ color }): string => color};
    opacity: 0.8;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  overflow-y: scroll;
`

export const MobileView = styled.div`
  padding: 63px 20px 20px 20px;
  width: 390px;
  background-color: #363636;
  max-height: 650px;
  overflow-y: scroll;
`;
