import { useCallback, useState } from 'react';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { ESnackbarStyle, Pagination, PublicGroup } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { getPublicGroups } from 'services/api/publicGroupsService';

type Result = {
  items: PublicGroup[];
  pagination: Pagination;
  isPublicGroupsLoading: boolean;
  fetchPublicGroups: () => Promise<void>;
  onChangePagination: (currentPage: number, pageSize?: number) => void;
};

const usePublicGroups = (): Result => {
  const [publicGroups, setPublicGroups] = useState<PublicGroup[]>([]);
  const [isPublicGroupsLoading, setIsPublicGroupsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<Pagination>({
    totalItemsCount: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE
  });

  const { openNotification } = useNotifications();

  const fetchPublicGroups = useCallback(async (): Promise<void> => {
    setIsPublicGroupsLoading(true);
    try {
      const response = await getPublicGroups({
        page: pagination.currentPage,
        limit: pagination.pageSize
      });
      const {
        items,
        pagination: { totalItemsCount, totalPagesCount }
      } = response.data;
      setPublicGroups(items);
      setPagination((prevState) => ({ ...prevState, totalItemsCount, totalPagesCount }));
    } catch (e: any) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsPublicGroupsLoading(false);
    }
  }, [pagination.currentPage, pagination.pageSize, openNotification]);

  const onChangePagination = (
    currentPage: number,
    pageSize: number | undefined = PAGE_SIZE
  ): void => {
    setPagination((prevState) => ({ ...prevState, currentPage, pageSize }));
  };

  return {
    items: publicGroups,
    pagination,
    isPublicGroupsLoading,
    fetchPublicGroups,
    onChangePagination
  };
};

export default usePublicGroups;
