import {
  FilterValue,
  TablePaginationConfig,
  ColumnType,
  SortOrder,
  Key
} from 'antd/lib/table/interface';
import { ESortType } from 'shared/types';

type SorterResult<RecordType> = {
  column?: ColumnType<RecordType> & {
    sortKey?: string;
  };
  order?: SortOrder;
  field?: Key | readonly Key[];
  columnKey?: Key;
};

const getSortByValue = (field: string, order: string): string => {
  return order === ESortType.ASC ? field : `-${field}`;
};

export const handleSortAction =
  <T = any>(
    setSortParams: (value: string[]) => void
  ): ((
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[]
  ) => Promise<void>) =>
  async (pagination, filters, sorter): Promise<void> => {
    let sortParams: string[];
    if (Array.isArray(sorter)) {
      sortParams = sorter.reduce((acc: string[], item): string[] => {
        const { column, order } = item;
        if (!!column && !!order) {
          const sortByValue = getSortByValue(column.sortKey as string, order as string);
          return [...acc, sortByValue];
        } else {
          return acc;
        }
      }, []);
    } else {
      const { column, order } = sorter;
      sortParams =
        !!column && !!order ? [getSortByValue(column.sortKey as string, order as string)] : [];
    }
    setSortParams(sortParams);
  };
