export const LOG_COLUMNS = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    ellipsis: true,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    ellipsis: false,
  },
];
