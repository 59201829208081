import React from 'react';
import { TargetHit } from 'shared/types';

type Props = {
  targetHits: TargetHit[]
};

const SmallTarget: React.FC<Props> = ({ targetHits }): JSX.Element => {
  const maxRadius = 130;
  const fov = 40;
  const height = maxRadius;
  const width = maxRadius + 8;

  return (
    <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(4, 0)`}>
        <circle cx='65' cy='65' r='64.5' stroke='#FFF' strokeWidth={1} fill='transparent' />
        <circle cx='65' cy='65' r='54.5' stroke='#FFF' strokeWidth={1} fill='transparent' />
        <circle cx='65' cy='65' r='44.5' stroke='#FFF' strokeWidth={1} fill='transparent' />
        <circle cx='65' cy='65' r='34.5' stroke='#FFF' strokeWidth={1} fill='transparent' />
        <circle cx='65' cy='65' r='22.5' stroke='#FFF' strokeWidth={1} fill='#484848' />
        <circle cx='65' cy='65' r='13.5' fill='#918f90' />
        <path
          d={
            'm63.4 62.84-.67.74L64.27 65l-1.56 1.42.68.74L65 65.68l1.6 1.48.68-.74L65.75 65l1.47-1.34-.67-.74-1.54 1.4z'
          }
          fill='#231F20'
        />
        {targetHits?.map(
          ({ x, y }, index): JSX.Element => (
            <circle
              key={index}
              cx='65'
              cy='65'
              r='4'
              transform={`translate(${(x / fov) * maxRadius}, ${(y / fov) * maxRadius})`}
              fill='#FFF'
            />
          )
        )}
      </g>
    </svg>
  );
};

export default SmallTarget;
