import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }

  & tbody tr td {
    vertical-align: top;
  }
`;

export const TableContainer = styled.div`
  flex: 1;
  margin-top: 24px;
  overflow-y: auto;
`;

export const TypeRow = styled.div<{ color: string }>`
  color: ${({ color }): string => color};
`;

export const DataRow = styled.div`
  max-height: 350px;
  overflow: auto;
`
