import * as Yup from 'yup';

export const validation = {
  LOG_IN: Yup.object().shape({
    email: Yup.string().email('Please enter a valid e-mail address').required('Required field'),
    password: Yup.string()
      .min(8, 'Use 8 or more characters')
      .max(50, 'Only 50 symbols allowed')
      .required('Required field')
  }),
  ADD_USER: Yup.object().shape({
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    role: Yup.string().required('Required field'),
    email: Yup.string().email('Please enter a valid e-mail address').required('Required field'),
    password: Yup.string()
      .min(8, 'Use 8 or more characters')
      .max(50, 'Only 50 symbols allowed')
      .required('Required field'),
    confirmPassword: Yup.string()
      .min(8, 'Use 8 or more characters')
      .max(50, 'Only 50 symbols allowed')
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Passwords must match')
  }),
  CREATE_BOW: Yup.object().shape({
    image: Yup.mixed().required('Required field'),
    modelName: Yup.string().required('Required field'),
    drawLengthFrom: Yup.string().required('Required field'),
    drawLengthTo: Yup.string().required('Required field'),
    drawWeight: Yup.array().of(Yup.number()).min(1, 'Min 1 value required').required('Required field'),
    letOff: Yup.array().of(Yup.number()).min(1, 'Min 1 value required').required('Required field'),
    'lr/hr': Yup.array().of(Yup.string()).min(1, 'Min 1 value required').required('Required field'),
  }),
  EDIT_BOW: Yup.object().shape({
    modelName: Yup.string().required('Required field'),
    drawLengthFrom: Yup.string().required('Required field'),
    drawLengthTo: Yup.string().required('Required field'),
    drawWeight: Yup.array().of(Yup.number()).min(1, 'Min 1 value required').required('Required field'),
    letOff: Yup.array().of(Yup.number()).min(1, 'Min 1 value required').required('Required field'),
    'lr/hr': Yup.array().of(Yup.string()).min(1, 'Min 1 value required').required('Required field'),
  }),
  EDIT_USER: Yup.object().shape({
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    role: Yup.string().required('Required field'),
    email: Yup.string().email('Please enter a valid e-mail address').required('Required field')
  }),
  EDIT_GROUP: Yup.object().shape({
    name: Yup.string().required('Required field')
  }),
  CHANGE_PASSWORD: Yup.object().shape({
    updatedPassword: Yup.string()
      .min(8, 'Use 8 or more characters')
      .max(50, 'Only 50 symbols allowed')
      .required('The password is too short'),
    confirmPassword: Yup.string()
      .min(8, 'Use 8 or more characters')
      .max(50, 'Only 50 symbols allowed')
      .oneOf([Yup.ref('updatedPassword')], 'Passwords must match')
      .required('Passwords must match')
  }),
  UPLOAD_FIRMWARE: Yup.object().shape({
    version: Yup.string()
      .trim()
      .required('Required field')
      .test('version', 'Invalid format, use: x.x.x', (value): boolean =>
        value ? /\d\.\d\.\d/.test(value) : false
      )
  }),
  ADD_SUPPORT_MESSAGE: Yup.object().shape({
    message: Yup.string().trim().required('Required field')
  }),
  CHECK_A_BOW: Yup.object().shape({
    searchValue: Yup.string().trim().required('Required field')
  })
};
