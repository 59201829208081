type Params = { [key: string]: string | number | boolean | undefined | null };

export const getSortQueryParams = (sortParams: string[]): string =>
  sortParams
    .map((item): string => (sortParams.length > 1 ? `orderBy[]=${item}` : `orderBy=${item}`))
    .join('&');

export const getQueryParams = (params: Params): string => {
  const urlParams = Object.entries(params)
    .filter((item): boolean => !!item[1])
    .map(
      (item): string =>
        encodeURIComponent(item[0]) + '=' + encodeURIComponent(item[1] as string | number | boolean)
    )
    .join('&');
  return urlParams;
};
