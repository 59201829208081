import {
  ActivityLogPage,
  ExActivityLogPage,
  AddUserPage,
  BowDetailsPage,
  BowUsersPage,
  BowUserSessionsPage,
  CheckBowPage,
  EditBowUserPage,
  EditUserPage,
  FirmwareRelease,
  FirmwareTesting,
  LogDetailsPage,
  ExLogDetailsPage,
  LoginPage,
  MainPage,
  NotFoundPage,
  SessionCommentsPage,
  SessionsCommunityPage,
  SessionsPage,
  UserGroupPage,
  UserGroupsPage,
  UsersPage,
  SupportMessagesPage,
  UserShotsPage,
  BowsPage,
  AddBowPage,
  EditBowPage,
  ReportsPage,
  ReportPage,
  StatisticsPage,
  UserDeviceStatisticsPage,
  PublicGroupsPage,
  PublicGroupPage,
  EventsPage,
  GroupEventsPage,
  GroupEventPage
} from 'pages';
import { ERouteType, RouteItem } from 'shared/types';

export const ROUTES: RouteItem[] = [
  {
    type: ERouteType.DEFAULT,
    path: '/',
    component: MainPage
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/login',
    component: LoginPage
  },
  {
    type: ERouteType.DEFAULT,
    path: '/not-found',
    component: NotFoundPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/check-a-bow',
    component: CheckBowPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/firmware/release',
    component: FirmwareRelease
  },
  {
    type: ERouteType.GUARDED,
    path: '/firmware/testing',
    component: FirmwareTesting
  },
  {
    type: ERouteType.GUARDED,
    path: '/add-user',
    component: AddUserPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/users',
    component: UsersPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bow-users',
    component: BowUsersPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/users/:userId',
    component: EditUserPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bow-users/:userId',
    component: EditBowUserPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bow-users/:userId/sessions',
    component: BowUserSessionsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bows',
    component: BowsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/add-bow',
    component: AddBowPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bows/:bowId',
    component: EditBowPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/sessions',
    component: SessionsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/sessions/community',
    component: SessionsCommunityPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/sessions/:sessionId/comments',
    component: SessionCommentsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/bows/:bowSerialNumber',
    component: BowDetailsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/activity-log',
    component: ActivityLogPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/activity-log/:logId',
    component: LogDetailsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/ex-activity-log',
    component: ExActivityLogPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/ex-activity-log/:logId',
    component: ExLogDetailsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/groups',
    component: UserGroupsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/groups/:groupId',
    component: UserGroupPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/support/:userId',
    component: SupportMessagesPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/shots/:userId',
    component: UserShotsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/reports',
    component: ReportsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/reports/:reportId',
    component: ReportPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/statistics',
    component: StatisticsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/user-device-statistics',
    component: UserDeviceStatisticsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/public-groups',
    component: PublicGroupsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/public-groups/:publicGroupId',
    component: PublicGroupPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/group-events',
    component: EventsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/group-events/:groupId',
    component: GroupEventsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/group-events/:groupId/event/:eventId',
    component: GroupEventPage
  }
];
