import styled, { CSSObject } from 'styled-components';
import { TitleProps, TitleSizes, TitleStyles, TitleTypes } from './types';
import _ from 'lodash';

const STYLES: TitleStyles = {
  default: {
    fontFamily: 'AvenirNextCyr Demi',
    fontSize: 16,
    color: '#FFD600'
  },
  light: {
    fontFamily: 'BebasNeue Light',
    fontSize: 18,
    color: '#FFF'
  },
  dark: {
    color: '#000',
    fontSize: 18,
    fontFamily: 'BebasNeue Regular'
  },
  text: {
    fontFamily: 'AvenirNextCyr Regular',
    fontSize: 14,
    color: '#FFF'
  },
  title: {
    fontFamily: 'BebasNeue Bold',
    fontSize: 23,
    color: '#FFF'
  }
};

const TYPES: TitleTypes = {
  default: 'AvenirNextCyr Demi',
  defaultRegular: 'AvenirNextCyr Regular',
  bold: 'BebasNeue Bold',
  regular: 'BebasNeue Regular',
  light: 'BebasNeue Light'
};

const SIZES: TitleSizes = {
  xxs: 12,
  xs: 14,
  sm: 15,
  md: 16,
  lg: 18,
  xl: 22,
  xxl: 23
};

export const TitleContainer = styled.div``;

export const Title = styled.div((props: Partial<TitleProps>): CSSObject => {
  const {
    variant = 'default',
    centered = false,
    uppercase = true,
    capitalize = false,
    underline = false,
    titleStyles = {},
    size,
    color,
    type
  } = props;

  const styles: CSSObject = {
    ...STYLES[variant],
    ...(size && { fontSize: _.isNumber(size) ? size : SIZES[size] }),
    ...(type && { fontFamily: TYPES[type] || type }),
    ...(color && { color }),
    textTransform: uppercase ? 'uppercase' : capitalize ? 'capitalize' : 'none',
    textAlign: centered ? 'center' : 'start',
    textDecoration: underline ? 'underline' : 'none',
    ...titleStyles
  };
  return styles;
});
