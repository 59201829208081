import React from 'react';
import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState } from 'shared/types';

type Props = {
  title: string;
  confirmAction: () => void;
  modalTitle?: string;
};

const ConfirmModal: React.FC<Props> = ({
  title,
  confirmAction,
  modalTitle = 'Confirm'
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      visible={isModalOpened}
      title={modalTitle}
      onCancel={handleCancel}
      onOk={confirmAction}
      okButtonProps={{ disabled: isModalDataLoading }}
      cancelButtonProps={{ disabled: isModalDataLoading }}
    >
      {title}
    </Modal>
  );
};

export default ConfirmModal;
