import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Redirect, useHistory, useLocation } from 'react-router';
import { MainPageContainer } from './styles';
import { getCurrentUser } from 'services/api/authService';
import { loginUserSuccess } from 'services/store/reducers/authReducer';
import { EUserRole } from '../../shared/types';

type LocationState = {
  from: {
    pathname: string;
  };
};

const MainPage = (): JSX.Element => {
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useAppDispatch();

  useEffect((): void => {
    const fetchUser = async (): Promise<void> => {
      try {
        const response = await getCurrentUser();
        dispatch(loginUserSuccess(response.data));
      } catch (e) {
        history.push('/login');
      }
    };
    fetchUser();
  }, [history, dispatch]);

  const { from } = location.state || {
    from: { pathname: user && user.role === EUserRole.moderator ? '/reports' : '/sessions' }
  };

  if (isAuth) return <Redirect to={from} />;

  return (
    <MainPageContainer>
      <Spin size='large' />
    </MainPageContainer>
  );
};

export default MainPage;
