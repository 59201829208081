import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { deleteUser, getUser } from 'services/api/usersService';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, User } from 'shared/types';

type Result = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  isUserLoading: boolean;
  fetchUser: (userId: string) => Promise<void>;
  removeUser: (userId: string) => Promise<void>;
};

const useUser = (): Result => {
  const [user, setUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchUser = useCallback(
    async (userId: string): Promise<void> => {
      setIsUserLoading(true);
      try {
        const response = await getUser(userId);
        setUser(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsUserLoading(false);
      }
    },
    [openNotification]
  );

  const removeUser = async (userId: string): Promise<void> => {
    setIsUserLoading(true);
    try {
      await deleteUser(userId);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsUserLoading(false);
    }
  };

  return {
    user,
    setUser,
    isUserLoading,
    fetchUser,
    removeUser
  };
};

export default useUser;
