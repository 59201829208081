import { useCallback, useState } from 'react';
import {deleteGroup, getGroup } from 'services/api/groupsService';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, UserGroup } from 'shared/types';

type Result = {
  group: UserGroup | null;
  isGroupLoading: boolean;
  fetchGroup: (groupId: string) => Promise<void>;
  removeGroup: (groupId: string) => Promise<void>;
};

const useGroup = (): Result => {
  const [group, setGroup] = useState<UserGroup | null>(null);
  const [isGroupLoading, setIsGroupLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchGroup = useCallback(
    async (groupId: string): Promise<void> => {
      setIsGroupLoading(true);
      try {
        const response = await getGroup(groupId);
        setGroup(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsGroupLoading(false);
      }
    },
      [openNotification]
  );

  const removeGroup = async (groupId: string): Promise<void> => {
    setIsGroupLoading(true);
    try {
      await deleteGroup(groupId);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsGroupLoading(false);
    }
  };

  return {
    group,
    isGroupLoading,
    fetchGroup,
    removeGroup
  }
}

export default useGroup;
