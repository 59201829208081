import React, { useEffect, useState } from 'react';
import { Button, Input, PageHeader, Pagination, Table } from 'antd';
import { ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { useFormik } from 'formik';
import { useDevices, useNotifications } from 'shared/hooks';
import { validation } from 'services/validation';
import { PaginationContainer } from 'shared/styles';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { parseISODateFormat } from 'utils/date-utils';
import { getDeviceBySerialNumber } from 'services/api/devicesService';
import { ESnackbarStyle } from 'shared/types';
import { useHistory } from 'react-router';
import { handleSortAction } from 'utils/sorting-utils';
import { BOW_COLUMNS } from './constants';
import { getFieldError } from 'utils/error-utils';

type TableDevice = {
  key: number;
  id: number;
  productSerialNumber: string;
  email: string;
  createdAt: string;
};

type RowAction = {
  onClick: () => void;
};

type FormValues = {
  searchValue: string;
};

const CheckBowPage = (): JSX.Element => {
  const [tableDevices, setTableDevices] = useState<TableDevice[]>([]);
  const {
    devices,
    fetchDevices,
    isDevicesLoading,
    totalDevices,
    setCurrentPage,
    currentPage,
    setSortParams
  } = useDevices();
  const { openNotification } = useNotifications();
  const history = useHistory();

  useEffect((): void => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect((): void => {
    const modifiedDevices = devices.map((device): TableDevice => {
      return {
        key: device.id,
        id: device.id,
        productSerialNumber: device.productSerialNumber,
        email: device.deviceUser?.email,
        createdAt: parseISODateFormat(device.createdAt)
      };
    });
    setTableDevices(modifiedDevices);
  }, [devices]);

  const initialValues: FormValues = {
    searchValue: ''
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      try {
        await getDeviceBySerialNumber(values.searchValue);
        history.push(`bows/${values.searchValue}`);
      } catch (e) {
        openNotification(ESnackbarStyle.WARNING, 'Bow was not found');
      }
    },
    initialValues,
    validationSchema: validation.CHECK_A_BOW
  });

  const handleRowClick = (device: TableDevice): void => {
    history.push(`/bows/${device.productSerialNumber}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Check a bow' />
        <Styled.SearchForm onSubmit={formik.handleSubmit}>
          <Styled.SearchFormInputContainer>
            <Input
              value={formik.values.searchValue}
              name='searchValue'
              onChange={formik.handleChange}
              size='large'
              placeholder='Serial number'
            />
            {getFieldError(formik, 'searchValue')}
          </Styled.SearchFormInputContainer>
          <Button type='primary' htmlType='submit'>
            Apply
          </Button>
        </Styled.SearchForm>
        <Styled.TableContainer>
          <Table
            onRow={(device: TableDevice): RowAction => ({
              onClick: (): void => handleRowClick(device)
            })}
            columns={BOW_COLUMNS}
            dataSource={tableDevices}
            loading={isDevicesLoading}
            pagination={false}
            size={'middle'}
            onChange={handleSortAction(setSortParams)}
          />
          {totalDevices > PAGE_SIZE && (
            <PaginationContainer>
              <Pagination
                current={currentPage}
                total={totalDevices}
                onChange={setCurrentPage}
                pageSize={PAGE_SIZE}
              />
            </PaginationContainer>
          )}
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default CheckBowPage;
