import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const RowValue = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Target = styled.div`
  display: flex;
`;

export const TargetStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TargetChart = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
