import React from 'react';

type Props = {
  color?: string;
  size?: number;
};

const TrainingIcon: React.FC<Props> = (props): JSX.Element => {
  const { size = 20, color = '#000' } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox={`0 0 ${size * 2} ${size * 2}`}
    >
      <g
        id='Group_21738'
        data-name='Group 21738'
        transform='matrix(0.259, 0.966, -0.966, 0.259, 47.418, -1.154)'
      >
        <g id='Group_21028' data-name='Group 21028' transform='translate(0 0)'>
          <path
            id='Path_34153'
            data-name='Path 34153'
            d='M25.977,3.906H24.613L23.249,0S14.6-.061,8.866,1.178,0,7.5,0,7.5L5.3,8.587A5.57,5.57,0,0,1,8.928,4.836C11.9,3.875,20.8,3.6,20.8,3.6l1.7,4.681h3.472Z'
            transform='translate(1.674 18.444)'
            fill={color}
            stroke={color}
            stroke-linejoin='round'
            stroke-width='2'
          />
          <g
            id='Ellipse_2243'
            data-name='Ellipse 2243'
            transform='translate(0 23.838)'
            fill={color}
            stroke={color}
            stroke-width='2'
          >
            <circle cx='3.968' cy='3.968' r='3.968' stroke='none' />
            <circle cx='3.968' cy='3.968' r='2.968' fill='none' />
          </g>
          <path
            id='Path_34157'
            data-name='Path 34157'
            d='M0,30.472c.083.162,26.225,18.475,26.225,18.475V0L23.59,6.262,25.574,8.4V43.677l-2.511,3.038Z'
            transform='translate(1.829 0)'
            fill={color}
            stroke={color}
            stroke-linejoin='round'
            stroke-width='1'
          />
        </g>
        <g id='Group_21029' data-name='Group 21029' transform='translate(28.054 0)'>
          <path
            id='Path_34153-2'
            data-name='Path 34153'
            d='M0,3.906H1.364L2.728,0s8.648-.062,14.383,1.178S25.977,7.5,25.977,7.5l-5.3,1.085a5.57,5.57,0,0,0-3.627-3.751C14.073,3.875,5.177,3.6,5.177,3.6l-1.7,4.681H0Z'
            transform='translate(0.403 18.444)'
            fill={color}
            stroke={color}
            stroke-linejoin='round'
            stroke-width='2'
          />
          <g
            id='Ellipse_2243-2'
            data-name='Ellipse 2243'
            transform='translate(20.118 23.838)'
            fill={color}
            stroke={color}
            stroke-width='2'
          >
            <circle cx='3.968' cy='3.968' r='3.968' stroke='none' />
            <circle cx='3.968' cy='3.968' r='2.968' fill='none' />
          </g>
          <path
            id='Path_34157-2'
            data-name='Path 34157'
            d='M26.225,30.472C26.143,30.634,0,48.947,0,48.947V0L2.635,6.262.651,8.4V43.677l2.511,3.038Z'
            transform='translate(0 0)'
            fill={color}
            stroke={color}
            stroke-linejoin='round'
            stroke-width='1'
          />
        </g>
      </g>
    </svg>
  );
};

export default TrainingIcon;
