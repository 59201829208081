import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  height: 68px;
`;

export const FormInputInner = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    width: min-content;
  }

  @media ${DEVICE.tablet} {
    justify-content: initial;
    flex-direction: column;

    & button:last-child {
      margin-top: 10px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 20px;
`;
