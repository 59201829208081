import { useCallback, useState } from 'react';
import { ESnackbarStyle, Log } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { getLogById } from '../../../../services/api/logService';

type Result = {
  log: Log | null;
  isLogLoading: boolean;
  fetchLog: () => Promise<void>;
};

const useLog = (logId: string, logType = ''): Result => {
  const [log, setLog] = useState<Log | null>(null);
  const [isLogLoading, setIsLogLoading] = useState<boolean>(true);
  const { openNotification } = useNotifications();

  const fetchLog = useCallback(async (): Promise<void> => {
    setIsLogLoading(true);
    try {
      const response = await getLogById(logId, logType);
      setLog(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsLogLoading(false);
    }
  }, [openNotification, logId, logType]);

  return {
    log,
    isLogLoading,
    fetchLog
  };
};

export default useLog;
