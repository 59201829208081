import { ReactNode } from 'react';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { ModalState } from 'shared/types';

// Actions

export const showModal = createAction<ReactNode | null>('modal/showModal');
export const closeModal = createAction('modal/closeModal');
export const setIsDataLoadingParameter = createAction<boolean>('modal/setIsDataLoadingParameter');

const initialState: ModalState = {
  isModalOpened: false,
  isModalDataLoading: false,
  modalWindow: null
};

// Reducer

export const modalReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(showModal, (state, action): ModalState => {
      return { ...state, isModalOpened: true, modalWindow: action.payload };
    })
    .addCase(setIsDataLoadingParameter, (state, action): ModalState => {
      return { ...state, isModalDataLoading: action.payload };
    })
    .addCase(closeModal, (state): ModalState => {
      return { ...state, isModalOpened: false, modalWindow: null };
    });
});
