import { useCallback, useState } from 'react';
import { getDevices } from 'services/api/devicesService';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { useNotifications } from 'shared/hooks';
import { Device, ESnackbarStyle } from 'shared/types';

type Result = {
  devices: Device[];
  isDevicesLoading: boolean;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalDevices: number;
  fetchDevices: () => Promise<void>;
  setSortParams: (values: string[]) => void;
};

const useDevices = (): Result => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [isDevicesLoading, setIsDevicesLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalDevices, setTotalDevices] = useState<number>(0);
  const [sortParams, setSortParams] = useState<string[]>([]);

  const { openNotification } = useNotifications();

  const fetchDevices = useCallback(async (): Promise<void> => {
    setIsDevicesLoading(true);
    try {
      const response = await getDevices(currentPage, PAGE_SIZE, sortParams);
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setDevices(response.data.items);
      setTotalPages(totalPagesCount);
      setTotalDevices(totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsDevicesLoading(false);
    }
  }, [openNotification, currentPage, sortParams]);

  return {
    devices,
    isDevicesLoading,
    fetchDevices,
    totalPages,
    currentPage,
    setCurrentPage,
    totalDevices,
    setSortParams
  };
};

export default useDevices;
