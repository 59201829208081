import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { Button, Select } from 'antd';

export const PageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const CheckboxContainer = styled.div`
  padding-top: 30px;
  padding-left: 5px;
`;

export const TableContainer = styled.div`
  flex: 1;
  margin-top: 24px;
  overflow-x: auto;

  & table {
    max-width: unset;
    width: unset;
    min-width: 100%;
  }

  & tbody tr {
    cursor: pointer;
  }

  & thead tr th {
    max-width: 600px;

    @media ${DEVICE.tablet} {
      max-width: 600px;
    }
  }

  & tbody tr td {
    max-width: 600px;

    @media ${DEVICE.tablet} {
      max-width: 600px;
    }
  }
`;

export const TypeRow = styled.div<{ color: string }>`
  color: ${({ color }): string => color};
`;

export const LogActions = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const LogSelect = styled(Select)`
  width: 300px;
`;

export const LogAction = styled(Button)`
  margin-left: 20px;
  background: #ff4f4f;
  border-color: #ff4f4f;

  &:hover {
    background: #ff4f4f;
    border-color: #ff4f4f;
    opacity: 0.8;
  }
`;
