import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Styled from './styles';
import { GuardedRoute, NotGuardedRoute } from 'routes';
import { ERouteType, ModalState, RouteItem } from 'shared/types';
import { ROUTES } from './routes';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Sidebar } from 'shared/components';
import { MenuOutlined } from '@ant-design/icons';
import { openSidebar } from 'services/store/reducers/sidebarReducer';

const MainRoutes = (): JSX.Element => {
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);
  const { isModalOpened, modalWindow } = useAppSelector((state): ModalState => state.modal);
  const dispatch = useAppDispatch();

  const handleMenuButtonClick = (): void => {
    dispatch(openSidebar());
  };

  const getRoute = (route: RouteItem): JSX.Element => {
    switch (route.type) {
      case ERouteType.GUARDED:
        return (
          <GuardedRoute key={route.path} exact path={route.path} component={route.component} />
        );
      case ERouteType.NOT_GUARDED:
        return (
          <NotGuardedRoute key={route.path} exact path={route.path} component={route.component} />
        );
      default:
        return <Route key={route.path} exact path={route.path} component={route.component} />;
    }
  };

  return (
    <Styled.RootContainer isAuth={isAuth}>
      {isAuth && (
        <>
          <Styled.MenuButton onClick={handleMenuButtonClick}>
            <MenuOutlined style={{ fontSize: '30px' }} />
          </Styled.MenuButton>
          <Sidebar />
        </>
      )}
      <Switch>
        {ROUTES.map((item): JSX.Element => {
          return getRoute(item);
        })}
        <Route path='*'>
          <Redirect to='/not-found' />
        </Route>
      </Switch>
      {isModalOpened && <>{modalWindow}</>}
    </Styled.RootContainer>
  );
};

export default MainRoutes;
