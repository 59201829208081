import styled from 'styled-components';

export const Session = styled.div`
  position: relative;
  border-radius: 10px;
  padding-top: 26px;
  background-color: #231f20;
`;

export const ChartGroup = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #231f20;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const UserInfo = styled.div`
  position: absolute;
  top: -22px;
  left: -15px;
  display: flex;
`;

export const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-right: 5px solid #363636;
  border-bottom: 5px solid #363636;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

export const Date = styled.div`
  position: absolute;
  top: -22px;
  right: 5px;
`;

export const Summary = styled.div`
  margin-bottom: 6px;
  padding-left: 29px;
  padding-right: 10px;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatItem = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Target = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 9px;
`;

export const PictureSection = styled.div<{ withBorder: boolean }>`
  padding: 11px;
  border: ${({withBorder}): string => withBorder ? '3px solid #1677FF' : 'unset'}
`

export const PictureContainer = styled.div`
  height: 130px;
  border-radius: 6px;
  overflow: hidden;
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #363636;
`
