import { useCallback, useState } from 'react';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, PublicGroup } from 'shared/types';
import { acceptGroup, rejectGroup, getPublicGroupById } from 'services/api/publicGroupsService';

type Result = {
  publicGroup: PublicGroup | null;
  isPublicGroupLoading: boolean;
  fetchPublicGroup: (publicGroupId: string) => Promise<void>;
  acceptPublicGroup: (publicGroupId: string) => Promise<void>;
  rejectPublicGroup: (publicGroupId: string, type: 'name' | 'image') => Promise<void>;
};

const usePublicGroup = (): Result => {
  const [publicGroup, setPublicGroup] = useState<PublicGroup | null>(null);
  const [isPublicGroupLoading, setIsPublicGroupLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchPublicGroup = useCallback(
    async (publicGroupId: string): Promise<void> => {
      setIsPublicGroupLoading(true);
      try {
        const response = await getPublicGroupById(`${publicGroupId}`);
        if (response.data.length) {
          setPublicGroup(response.data[0]);
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsPublicGroupLoading(false);
      }
    },
    [openNotification]
  );

  const acceptPublicGroup = async (publicGroupId: string): Promise<void> => {
    setIsPublicGroupLoading(true);
    try {
      const response = await acceptGroup(`${publicGroupId}`);
      setPublicGroup(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsPublicGroupLoading(false);
    }
  };

  const rejectPublicGroup = async (publicGroupId: string, type: 'name' | 'image'): Promise<void> => {
    setIsPublicGroupLoading(true);
    try {
      const response = await rejectGroup(`${publicGroupId}`, type);
      setPublicGroup(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsPublicGroupLoading(false);
    }
  };

  return {
    publicGroup,
    isPublicGroupLoading,
    fetchPublicGroup,
    acceptPublicGroup,
    rejectPublicGroup
  };
};

export default usePublicGroup;
