import React, { useEffect, useMemo } from 'react';
import { checkRolePermission } from 'utils/role-utils';
import { EUserRole, PublicGroup, User } from 'shared/types';
import { Redirect } from 'react-router';
import { useAppSelector, usePublicGroups } from 'shared/hooks';
import * as Styled from './styles';
import { PageHeader, Table } from 'antd';
import { PaginationContainer } from 'shared/styles';
import Pagination from 'antd/lib/pagination';
import { ContentContainer } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { PUBLIC_GROUPS_COLUMNS } from './constants';
import { ColumnType } from 'antd/lib/table/interface';

type TablePublicGroup = PublicGroup & { key: number };

const PublicGroupsPage = (): JSX.Element => {
  const history = useHistory();
  const user = useAppSelector((state): User | null => state.auth.user);

  const {
    items,
    pagination: { currentPage, pageSize, totalItemsCount },
    isPublicGroupsLoading,
    fetchPublicGroups,
    onChangePagination
  } = usePublicGroups();

  useEffect((): void => {
    fetchPublicGroups();
  }, [fetchPublicGroups]);

  const tablePublicGroups = useMemo(
    (): TablePublicGroup[] =>
      items.map((publicGroup): TablePublicGroup => ({ ...publicGroup, key: publicGroup.id })),
    [items]
  );

  const handleRowClick = (publicGroup: TablePublicGroup): void => {
    history.push(`/public-groups/${publicGroup.id}`);
  };

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.moderator, EUserRole.admin_user, EUserRole.master_admin],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Public Groups' />
        <Styled.TableContainer>
          <Table
            columns={PUBLIC_GROUPS_COLUMNS.map((column): ColumnType<TablePublicGroup> => {
              return {
                ...column,
                onCell: (record: TablePublicGroup): { onClick: () => void } => {
                  return {
                    onClick: (): void => handleRowClick(record)
                  };
                }
              };
            })}
            dataSource={tablePublicGroups}
            pagination={false}
            loading={isPublicGroupsLoading}
            size={'middle'}
          />
          <PaginationContainer>
            <Pagination
              pageSize={pageSize}
              current={currentPage}
              total={totalItemsCount}
              onChange={onChangePagination}
            />
          </PaginationContainer>
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default PublicGroupsPage;
