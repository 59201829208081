import { format } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';
import { EventUser } from 'shared/types';

export const COLUMNS: ColumnsType<EventUser> = [
  { title: 'User ID', dataIndex: 'id', key: 'id', width: 100 },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    render: (username) => <span>{username || '-'}</span>
  },
  {
    title: 'Full name',
    key: 'fullname',
    ellipsis: true,
    render: (__, record) => (
      <span>{[record.firstName, record.lastName].filter(Boolean).join(' ') || '-'}</span>
    )
  },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  {
    title: 'Total score',
    key: 'totalScore',
    dataIndex: 'leaderboard',
    ellipsis: true,
    render: (leaderboard) => <span>{leaderboard?.totalScore ?? '-'}</span>,
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.leaderboard?.totalScore ?? 0) - (b.leaderboard?.totalScore ?? 0)
  },
  {
    title: 'Shots',
    key: 'shotsCount',
    dataIndex: 'shotsCount',
    ellipsis: true,
    render: (__, record: EventUser) => <span>{record.shots?.length ?? 0}</span>,
    sorter: (a, b) => (a.shots?.length ?? 0) - (b.shots?.length ?? 0)
  },
  {
    title: 'Average Score',
    key: 'averageScore',
    dataIndex: 'leaderboard',
    ellipsis: true,
    render: (leaderboard) => <span>{leaderboard?.averageScore ?? '-'}</span>,
    sorter: (a, b) => (a.leaderboard?.averageScore ?? 0) - (b.leaderboard?.averageScore ?? 0)
  }
];

export const EXPANDED_ROW_COLUMNS: ColumnsType<EventUser['shots'][0]> = [
  {
    title: 'Ordinal number',
    dataIndex: 'ordinalNumber',
    key: 'ordinalNumber',
    width: 150,
    sorter: (a, b) => a.ordinalNumber - b.ordinalNumber
  },
  { title: 'Shot id', dataIndex: 'shotId', key: 'shotId', ellipsis: true },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (timestamp: number) => <>{format(new Date(timestamp), 'yyyy-MM-dd hh:mm:ss a')}</>,
    sorter: (a, b) => a.timestamp - b.timestamp
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    width: 200,
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.score - b.score
  }
];
