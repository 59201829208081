import { UserDeviceStatisticsUser, UserDeviceInfo } from 'shared/types';
import { AppVersionStatistics, DeviceStatistics } from './types';

export const categorizeUsersByDevice = (users: UserDeviceStatisticsUser[]): DeviceStatistics => {
  const categorizedUsers: DeviceStatistics = {
    iOS: [],
    Android: [],
    Unknown: []
  };

  users.forEach((user) => {
    if (user.devices.length > 0) {
      const categorizedDevices = user.devices.reduce(
        (acc, device) => {
          const deviceSystemName = device.deviceSystemName?.toLowerCase();
          if (deviceSystemName === 'ios') {
            acc.iOS.push(device);
          } else if (deviceSystemName === 'android') {
            acc.Android.push(device);
          } else {
            acc.Unknown.push(device);
          }
          return acc;
        },
        {
          iOS: [] as UserDeviceInfo[],
          Android: [] as UserDeviceInfo[],
          Unknown: [] as UserDeviceInfo[]
        }
      );

      if (categorizedDevices.iOS.length > 0) {
        categorizedUsers.iOS.push({ ...user, devices: categorizedDevices.iOS });
      }
      if (categorizedDevices.Android.length > 0) {
        categorizedUsers.Android.push({ ...user, devices: categorizedDevices.Android });
      }
      if (categorizedDevices.Unknown.length > 0) {
        categorizedUsers.Unknown.push({ ...user, devices: categorizedDevices.Unknown });
      }
    } else {
      categorizedUsers.Unknown.push(user);
    }
  });

  return categorizedUsers;
};

export const categorizeUsersByAppVersion = (
  users: UserDeviceStatisticsUser[]
): AppVersionStatistics => {
  const categorizedUsers: AppVersionStatistics = {};
  const UNKNOWN_VERSION_KEY = 'unknown';

  users.forEach((user) => {
    if (user.devices.length > 0) {
      const categorizedDevicesByAppVersion = user.devices.reduce((acc, device) => {
        const appVersion = device.appVersion || UNKNOWN_VERSION_KEY;
        if (!acc[appVersion]) {
          acc[appVersion] = [];
        }
        acc[appVersion].push(device);
        return acc;
      }, {} as Record<string, UserDeviceInfo[]>);

      Object.keys(categorizedDevicesByAppVersion).forEach((appVersion) => {
        if (!categorizedUsers[appVersion]) {
          categorizedUsers[appVersion] = [];
        }

        if (!categorizedUsers[appVersion].some((u) => u.id === user.id)) {
          categorizedUsers[appVersion].push({
            ...user,
            devices: categorizedDevicesByAppVersion[appVersion]
          });
        }
      });
    } else {
      if (!categorizedUsers[UNKNOWN_VERSION_KEY]) {
        categorizedUsers[UNKNOWN_VERSION_KEY] = [];
      }
      categorizedUsers[UNKNOWN_VERSION_KEY].push(user);
    }
  });

  return categorizedUsers;
};
