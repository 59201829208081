import React from 'react';
import { getActiveColorSteadiness } from './helpers';
import { formatNumber } from 'utils/report-utils';
import * as Styled from './styles';
import { Title } from 'shared/components';

type Props = {
  value?: number;
  aimSteadinessValueDps?: number;
  activeColor?: string;
};

const Steadiness: React.FC<Props> = ({
  value,
  aimSteadinessValueDps,
  activeColor = '#707070'
}): JSX.Element => {
  const r1 = 12.5;
  const r2 = 18.5;
  const r3 = 24.5;
  const strokeDashR1 = (r1 * 2 * Math.PI) / 4;
  const strokeDashR2 = (r2 * 2 * Math.PI) / 4;
  const strokeDashR3 = (r3 * 2 * Math.PI) / 4;
  const defaultColor = '#707070';
  activeColor = getActiveColorSteadiness(value, activeColor);
  const centerColor = activeColor;

  const r1Color = value !== undefined && value < 65 ? activeColor : defaultColor;
  const r2Color = value !== undefined && value < 52 ? activeColor : defaultColor;
  const r3Color = value !== undefined && value < 39 ? activeColor : defaultColor;
  const formattedValue = formatNumber(aimSteadinessValueDps);

  return (
    <Styled.Chart>
      <svg height='52' width='52' viewBox='0 0 52 52'>
        <circle cx='26' cy='26' r='6.5' fill={centerColor} />
        <circle
          cx='26'
          cy='26'
          r={r1}
          stroke={r1Color}
          strokeWidth={3}
          strokeDasharray={`${strokeDashR1} ${strokeDashR1}`}
          strokeDashoffset={strokeDashR1 / 2}
          fill='transparent'
        />
        <circle
          cx='26'
          cy='26'
          r={r2}
          stroke={r2Color}
          strokeWidth={3}
          strokeDasharray={`${strokeDashR2} ${strokeDashR2}`}
          strokeDashoffset={strokeDashR2 / 2}
          fill='transparent'
        />
        <circle
          cx='26'
          cy='26'
          r={r3}
          stroke={r3Color}
          strokeWidth={3}
          strokeDasharray={`${strokeDashR3} ${strokeDashR3}`}
          strokeDashoffset={strokeDashR3 / 2}
          fill='transparent'
        />
      </svg>
      <Title
        title={formattedValue ? formattedValue + ' MOA' : '--'}
        variant='light'
        type='regular'
      />
    </Styled.Chart>
  );
};

export default Steadiness;
