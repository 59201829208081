import styled from 'styled-components';

export const ErrorMessage = styled.div`
  margin-top: 3px;
  color: #de3618;
  font-size: 12px;
`;

export const PaginationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;
