import { Result, Button } from 'antd';
import { useHistory } from 'react-router';
import { NotFoundPageContainer } from './styles';

const NotFoundPage = (): JSX.Element => {
  const history = useHistory();

  const navigateBack = (): void => {
    history.goBack();
  };

  return (
    <NotFoundPageContainer>
      <Result
        status='500'
        style={{
          background: 'none'
        }}
        subTitle='The page you were looking for can not be found'
        extra={
          <Button type='primary' onClick={navigateBack}>
            Back
          </Button>
        }
      />
    </NotFoundPageContainer>
  );
};

export default NotFoundPage;
