import { useCallback, useState } from 'react';
import { useNotifications } from 'shared/hooks';
import {
  ESnackbarStyle,
  PostedShot,
  Report,
  ReportObjectType,
  ReportStatus,
  Session
} from 'shared/types';
import {
  deleteReport,
  getReportById,
  getReportedObject,
  updateReport
} from 'services/api/reportsService';

type Result = {
  report: Report | null;
  reportedObject: Session | PostedShot | null;
  isReportLoading: boolean;
  isReportedObjectLoading: boolean;
  fetchReport: (reportId: string, fetchReportedObject?: boolean) => Promise<void>;
  fetchParentObject: (reportId: string, reportType: ReportObjectType) => Promise<void>;
  removeReport: (reportId: string) => Promise<void>;
  changeReportStatus: (reportId: string, status: ReportStatus) => Promise<void>;
};

const useReport = (): Result => {
  const [report, setReport] = useState<Report | null>(null);
  const [reportedObject, setReportedObject] = useState<Session | PostedShot | null>(null);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(true);
  const [isReportedObjectLoading, setIsReportedObjectLoading] = useState<boolean>(false);

  const { openNotification } = useNotifications();

  const fetchParentObject = useCallback(
    async (parentId: string, reportType: ReportObjectType): Promise<void> => {
      try {
        setIsReportedObjectLoading(true);
        const response = await getReportedObject(parentId, reportType);
        setReportedObject(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsReportedObjectLoading(false);
      }
    },
    [openNotification]
  );

  const fetchReport = useCallback(
    async (reportId: string, fetchReportedObject = true): Promise<void> => {
      setIsReportLoading(true);
      try {
        const response = await getReportById(reportId);
        if (fetchReportedObject)
          await fetchParentObject(response.data.parentId, response.data.reportObjectType);
        setReport(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsReportLoading(false);
      }
    },
    [openNotification, fetchParentObject]
  );

  const removeReport = async (reportId: string): Promise<void> => {
    setIsReportLoading(true);
    try {
      await deleteReport(reportId);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsReportLoading(false);
    }
  };

  const changeReportStatus = async (reportId: string, status: ReportStatus): Promise<void> => {
    setIsReportLoading(true);
    try {
      await updateReport(reportId, { status });
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsReportLoading(false);
    }
  };

  return {
    report,
    reportedObject,
    isReportLoading,
    isReportedObjectLoading,
    fetchReport,
    fetchParentObject,
    removeReport,
    changeReportStatus
  };
};

export default useReport;
