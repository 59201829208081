import { ReportStatus, SessionStats } from 'shared/types';
import _ from 'lodash';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  parseISO
} from 'date-fns';

export const getReportColor = (type: ReportStatus): string =>
  ({ invalid: '#ff4d4f', valid: '#52c41a', pending: '#1677ff' }[type]);

export const formatNumber = (num?: number, absoluteValue = true): string | undefined => {
  if (num === undefined) {
    return num;
  }
  num = Number(num?.toPrecision(3));
  num = absoluteValue ? Math.abs(num) : num;
  const numFixed2 = Math.round(roundNearest5(num * 100)) / 100;

  return numFixed2.toString().replace(/^0./, '.');
};

export const roundNearest5 = (num: number): number => {
  return Math.round(num / 5) * 5;
};

export const getAverageScore = (total: number, value: number, dec: boolean = false): number => {
  const score = dec ? Math.round((value / total) * 10) / 10 : Math.floor(value / total);
  return total ? score : 0;
};

export const formatTimeValue = (value: number = 0): number | string =>
  value < 10 ? `0${value}` : value;

export const formatSessionDuration = ({ hours, minutes, seconds }: Duration = {}): string => {
  return `${formatTimeValue(hours)}:${formatTimeValue(minutes)}:${formatTimeValue(seconds)}`;
};

export const getStatisticsItems = ({
  score,
  shots,
  duration
}: SessionStats): { title: string; value: string }[] => [
  { title: 'AVG. SCORE', value: `${score}%` },
  { title: 'SHOTS', value: `${shots}` },
  { title: 'DURATION', value: formatSessionDuration(duration) }
];

export const getDate = (date?: string | number | Date | null): Date => {
  if (!date) {
    return new Date();
  }
  if (_.isString(date)) {
    return parseISO(date);
  }
  return _.isNumber(date) ? new Date(date) : date;
};

export const formatTimestamp = (sessionDate?: string | Date | null | number): string => {
  const date = getDate(sessionDate);
  const currDate = new Date();

  const [diffInDays, diffInHours, diffInMinutes, diffInSeconds] = [
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds
  ].map((action): number => action(currDate, date));

  if (diffInDays) {
    return format(date, 'M/dd/yyyy');
  }
  if (!diffInMinutes && !diffInHours) {
    return `${diffInSeconds}s`;
  }
  if (!diffInHours) {
    return `${diffInMinutes}m`;
  }
  return `${diffInHours}h`;
};

export const truncateName = (name: string, maxLength: number = 13): string => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + '...';
  } else {
    return name;
  }
};

export const getShotTime = (
  shotTime: string | Date
): { period: string; fullTime: string; time: string } => {
  const date = _.isString(shotTime) ? parseISO(shotTime) : shotTime;
  const time = format(date, 'hh:mm:ss');
  const period = format(date, 'a');

  return {
    time,
    period,
    fullTime: `${time} ${period}`
  };
};

export const getFullName = (params: {
  firstName?: string;
  lastName?: string;
  username?: string;
}): string => {
  const {firstName = '', lastName = '', username = ''} = params;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (username) {
    return username;
  }

  return '-';
};
