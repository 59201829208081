import { ReactNode } from 'react';

export enum ERouteType {
  GUARDED = 'GUARDED',
  NOT_GUARDED = 'NOT_GUARDED',
  DEFAULT = 'DEFAULT'
}

export type RouteItem = {
  type: ERouteType;
  path: string;
  component: () => JSX.Element;
};

export type User = {
  email: string;
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  birthday: string;
  role: EUserRole;
  status: EUserStatus;
  createdAt: string;
  updatedAt: string;
  token: string;
  profilePictureS3Url: string | null;
  totalShots: number;
  isGroupOwner?: boolean;
  lastShot: string;
};

export type SupportMessage = {
  id: number;
  userId: number;
  content: string;
  authorId: number;
  createdAt: string;
  author: User;
};

export type PostedShot = {
  id: string;
  shotId: string;
  userId: number;
  isShotDetected: boolean;
  isDryFire: boolean;
  isDerail: boolean;
  shotIndex: number;
  shotScoreTotal: number;
  shotScoreAimSteadiness: number;
  shotScoreBowAngle: number;
  shotScoreAimTime: number;
  shotScoreBowTorque: number;
  bowTorque: number;
  aimSteadinessValueDps: number;
  releaseTime: number;
  aimTime: number;
  setUpTime: number;
  vibrationEnergy: number;
  bowInclineDegrees: number;
  bowLevelDegrees: number;
  dryFireAmplitude: number;
  derailAmplitude: number;
  aimGraphDataPre: AimGraphItem[];
  aimGraphDataPost: AimGraphItem[];
  levelGraphDataZ: number[];
  torqueResult: TorqueResult;
  targetHit: TargetHit;
  distance: number;
  timestamp: string;
  deviceId: string;
  shotPictureS3Path: string;
  shotPictureS3Url: string;
  shotPictureS3ExpireDate: string;
  endAt: string;
  publishedAt: string | null;
  sessionType: SessionType;
  configuration: ShotConfiguration;
  user: Omit<User, 'token'>;
  likes: ShotLike[];
  comments: ShotComment[];
};

export type ShotComment = Comment & {
  postedShotId: string;
};

export type ShotLike = Like & {
  postedShotId: string;
};

export type ShotConfiguration = {
  duration: boolean;
  imageType: 'TARGET' | 'PHOTO';
  level: boolean;
  steadiness: boolean;
  torque: boolean;
};

export type TorqueResult = {
  data: {
    factor: number;
    length: number;
    max: number;
    maxIndex: number;
    min: number;
    minIndex: number;
  };
  hiGScore: number;
  score: number;
};

export type TargetHit = {
  x: number;
  y: number;
};

export type AimGraphItem = {
  x: number;
  y: number;
};

export type SessionType = 'training' | 'hunting' | 'tournament';

export type Session = {
  id: string;
  userId: number;
  sessionName: string;
  createdAt: string;
  isCompleted: boolean;
  details: SessionDetails;
  user: User;
  stats: SessionStats;
  shots?: SessionShot[];
  publishedAt?: string | null;
  comments: SessionComment[];
  likes: SessionLike[];
  sessionPictureS3Url?: string;
};

export type SessionDetails = {
  stat: boolean;
  summary: boolean;
  target: boolean;
};

export type SessionLike = Like & {
  sessionId: string;
};

export type SessionComment = Comment & {
  sessionId: string;
};

export type SessionStats = {
  id: string;
  sessionId: string;
  duration: {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  score: number;
  shotDuration: number;
  shotSteadiness: number;
  shotSteadinessScore: number;
  bowLevelDegrees: number;
  aimTime: number;
  bowTorque: number;
  shotLevel: number;
  shotTorque: number;
  shots: number;
  userId: number;
  targetHits: TargetHit[];
  targetHitsStat: { [key: string]: { total: number; result: number; distance: string } };
};

export type SessionShot = {
  id: string;
  userId: number;
  groupId: string;
  isShotDetected: boolean;
  isDryFire: boolean;
  isDerail: boolean;
  shotIndex: number;
  shotScoreTotal: number;
  shotScoreAimSteadiness: number;
  shotScoreBowAngle: number;
  shotScoreAimTime: number;
  shotScoreBowTorque: number;
  bowTorque: number;
  releaseTime: number;
  aimTime: number;
  setUpTime: number;
  vibrationEnergy: number;
  bowInclineDegrees: number;
  bowLevelDegrees: number;
  dryFireAmplitude: number;
  distance: number;
  timestamp: string;
  name: string;
  userMetadata?: DeviceInformation & { appVersion: string };
};

export type Comment = {
  id: number;
  userId: number;
  content: string;
  createdAt: string;
  updatedAt: string;
  user: User;
};

export type Like = {
  id: number;
  userId: number;
};

export type CsvShot = {
  id: number;
  shotId: string;
  data: string;
  timestamp: string;
};

export type UserGroup = {
  id: number;
  ownerId: number;
  name: string;
  groupPictureS3Url: string;
  members: User[];
};

export enum EUserRole {
  support_user = 'support_user',
  admin_user = 'admin_user',
  master_admin = 'master_admin',
  application_user = 'application_user',
  moderator = 'moderator'
}

export enum EUserStatus {
  Created = 'new',
  Active = 'active',
  Disabled = 'disabled',
  Deleted = 'deleted'
}

export type AuthState = {
  isAuth: boolean;
  user: User | null;
};

export enum ESnackbarStyle {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

export type SidebarState = {
  isSidebarOpened: boolean;
};

export type ModalState = {
  isModalOpened: boolean;
  isModalDataLoading: boolean;
  modalWindow: ReactNode | null;
};

export enum EFirmwareUpdateChannel {
  release = 'release',
  beta = 'beta',
  testing = 'testing'
}

export enum EFirmwareStatus {
  active = 'active',
  archived = 'archived',
  scheduled = 'scheduled',
  uploaded = 'uploaded'
}

export type FirmwareVersion = {
  id: string;
  brand: string;
  product: string;
  modelName: string;
  semver: string;
  version: string;
  channel: string;
  fileId: string;
  file: {};
  uploadedBy: string;
  createdAt: string;
  status: EFirmwareStatus;
  changelog: string;
  scheduledAt: string | null;
};

export type FirmwareTableRow = {
  key: string;
  version: string;
  createdAt: string;
  status: string;
  activatedDate: string;
  scheduledAt: string | null;
  changelog: string;
  author: string;
  fileId: string;
};

export type UploadedFirmware = {
  file: File;
  version: string;
  changeLog: string;
};

export type UpdatedUser = {
  email: string;
  role: EUserRole;
  firstName: string;
  lastName: string;
};

export type Pagination = {
  currentPage: number;
  pageSize: number;
  totalItemsCount: number;
  totalPagesCount: number;
};

export type NewUser = {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  sendEmail: boolean;
};

export enum ESortType {
  DESC = 'descend',
  ASC = 'ascend'
}

export type Device = {
  bowDropCount: number;
  createdAt: string;
  derailCount: number;
  deviceUser: {
    createdAt: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    updatedAt: string;
  };
  dryFireCount: number;
  id: number;
  lastBowDropAt: string | null;
  lastDerailAt: string | null;
  lastDryFireAt: string | null;
  productSerialNumber: string;
  shotCount: number;
  updatedAt: string;
  userId: number;
};

export enum EDiagramSections {
  SHOTS = 'shotCount',
  DERAIL = 'derailCount',
  DRY_FIRE = 'dryFireCount',
  BOW_DROP = 'bowDropCount'
}

export type Shot = {
  deviceMode: string;
  deviceSerialNumber: string;
  id: number;
  location: { lat: number; lon: number };
  productSerialNumber: string;
  shotNumber: number;
  shotQuality: string;
  timestamp: string;
  userId: number;
  archived: string;
  shotScore: string;
};

export type ShotEvent = {
  deviceSerialNumber: string;
  eventType: string;
  id: number;
  productSerialNumber: string;
  timestamp: string;
  userId: number;
};

type DateRange = {
  start: string | null;
  end: string | null;
};

export type RequestParams = {
  dateRange: DateRange;
  currentPage: number;
};

export type LogType = 'warning' | 'error' | 'debug' | 'info';

export type Log = DeviceInformation & {
  id: number;
  type: LogType;
  timestamp: string;
  data: string;
  title: string;
  user: User;
  username?: string;
};

export type DeviceInformation = {
  deviceBrand: string;
  deviceModel: string;
  deviceSystemName: string;
  deviceSystemVersion: string;
};

export type Bow = {
  id: number;
  bowPictureS3Path: string;
  bowPictureS3Url: string;
  bowPictureS3ExpireDate: string;
  bowPictureId: string;
  modelName: string;
  drawWeight: number[];
  drawLength: number[];
  letOff: number[];
  'lr/hr': string[];
  timestamp: string;
};

export type ImageReportType =
  | 'harmful_or_dangerous'
  | 'offensive_imagery'
  | 'commercial_content_or_spam'
  | 'copyright_violation'
  | 'pornography'
  | 'violent_or_repulsive'
  | 'other';

export type CommentReportType =
  | 'harassment_or_bullying'
  | 'commercial_content_or_spam'
  | 'hate_speech'
  | 'offensive_language'
  | 'personal_information'
  | 'other';

export type ReportCategory = ImageReportType | CommentReportType;

export type ReportObjectType = 'picture_session' | 'picture_shot' | 'session' | 'shot';

export type ReportStatus = 'pending' | 'valid' | 'invalid';

export type Report = {
  id: number;
  accusedId: number;
  reportObjectType: ReportObjectType;
  parentId: string;
  reportedObjectId: string;
  category: ReportCategory;
  status: ReportStatus;
  reporterId: number;
  createdAt: string;
  updatedAt: string;
  reportedPictureS3Path: string | null;
  reportedSessionCommentId: number | null;
  reportedShotCommentId: number | null;
  description?: string;
};

export type PublicGroupStatus = 'rejected' | 'accepted' | 'pending';

export type PublicGroup = {
  id: number;
  groupId: number;
  isNew: boolean;
  name: string;
  status: PublicGroupStatus;
  imageUrl: string | null;
  pictureId: string | null;
  picturePath: string | null;
  moderationDate: string;
};

export interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T> | undefined;
  cancel(): void;
  flush(): ReturnType<T> | undefined;
}

// Statistics
export interface Statistics {
  users: StatisticsUser[];
  statistics: AppUsersStatistics;
}

export interface UserInfo {
  id: number;
  username: any;
  email: string;
  first_name: string;
  last_name: string;
  status: string;
  total_shots: number;
  isActiveUser: boolean;
  hasShots: boolean;
}

export interface StatisticsUser extends UserInfo {
  deviceInfo?: DeviceInfo;
}

export interface DeviceInfo {
  appVersion: string;
  deviceBrand: string;
  deviceModel: string;
  deviceSystemName: string;
  deviceSystemVersion: string;
}

export interface AppUsersStatistics {
  appUserCount: number;
  activeUsersCount: number;
  notActiveUsersCount: number;
  userWithShotsCount: number;
  userWithoutShotsCount: number;
  iosCount: number;
  androidCount: number;
  unknownCount: number;
}

export interface UserDeviceStatistics {
  users: UserDeviceStatisticsUser[];
  statistics: AppUsersStatistics;
}

export interface UserDeviceInfo extends DeviceInfo {
  id: number;
  userId: number;
  uniqueDeviceId: string;
}

export interface UserDeviceStatisticsUser extends UserInfo {
  devices: UserDeviceInfo[];
}

export interface EventGroup extends Omit<UserGroup, 'members'> {
  eventCountByStatus: Record<EventStatus, number>;
}

export type EventStatus = 'created' | 'finished' | 'active';

export interface Event {
  id: number;
  name: string;
  startDate: number;
  endDate: number;
  shotsPerDay: number;
  description: string;
  status: EventStatus;
  groupId: number;
  createdAt: string;
  updatedAt: string;
  ownerId?: number;
  eventPictureS3Path?: string;
  eventPictureS3ExpireDate?: string;
  eventPictureS3Url?: string;
}

export interface EventShot {
  id: number;
  shotId: string;
  timestamp: number;
  score: number;
  ordinalNumber: number;
}

export interface EventUser extends Omit<User, 'token'> {
  leaderboard: { averageScore: number; totalScore: number };
  shots: EventShot[];
}
