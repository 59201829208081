import { AxiosResponse } from 'axios';
import { getQueryParams } from 'utils/query-utils';
import { ApiServiceV2 } from './api';
import { CsvShot, Pagination, SessionShot } from 'shared/types';

type ShotsResponse = {
  items: SessionShot[];
  pagination: Pagination
}

export const getShots = async (
  page: number,
  limit: number,
  userId: string | number,
): Promise<AxiosResponse<ShotsResponse>> => {
  const params = getQueryParams({ page, limit, userId });
  return await ApiServiceV2.get(`/shots?${params}`);
};

export const getCsvShot = async (shotId: string | number):Promise<AxiosResponse<CsvShot>> => {
  return await ApiServiceV2.get(`/shots-csv/${shotId}`);
}
