import styled from 'styled-components';

export const FormInputContainer = styled.div`
  height: 68px;
  width: 100%;
`;

export const FormCheckboxContainer = styled.div`
  margin-bottom: 24px;
`;

export const FormInputInner = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;
