import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { ContentContainer } from 'shared/components';
import { PageHeader, Table, Input } from 'antd';
import { useGroups } from 'shared/hooks';
import { GROUPS_COLUMNS } from './constants';
import Pagination from 'antd/lib/pagination';
import { PaginationContainer } from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { User } from 'shared/types';

type TableGroup = {
  groupId: number;
  name: string;
  ownerName: string;
  ownerEmail: string;
  membersCount: number;
  members: User[];
};

type RowAction = {
  onClick: () => void;
};

const UserGroupsPage = (): JSX.Element => {
  const history = useHistory();
  const [tableGroups, setTableGroups] = useState<TableGroup[]>([]);
  const {
    groups,
    isGroupsLoading,
    searchValue,
    pagination,
    onChangePagination,
    fetchGroups,
    setSearchValue
  } = useGroups();

  useEffect((): void => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect((): void => {
    const modifiedGroups = groups.map((group): TableGroup => {
      let ownerName = '';
      let ownerEmail = '';

      group.members.forEach((member): void => {
        if (member.isGroupOwner) {
          ownerName = member.username;
          ownerEmail = member.email;
        }
      });

      return {
        groupId: group.id,
        name: group.name,
        members: group.members,
        membersCount: group.members.length,
        ownerName,
        ownerEmail
      };
    });
    setTableGroups(modifiedGroups);
  }, [groups, pagination.currentPage]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRowClick = (group: TableGroup): void => {
    history.push(`/groups/${group.groupId}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Groups' />
        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            onRow={(group): RowAction => ({ onClick: (): void => handleRowClick(group) })}
            columns={GROUPS_COLUMNS}
            dataSource={tableGroups}
            pagination={false}
            loading={isGroupsLoading}
            size={'middle'}
            // expandable={{
            //   expandedRowRender: (record): JSX.Element => (
            //     <Table
            //       columns={MEMBERS_COLUMNS}
            //       dataSource={record.members}
            //       pagination={false}
            //       loading={isGroupsLoading}
            //     />
            //   )}}
          />
        </Styled.TableContainer>
        <PaginationContainer>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.currentPage}
            total={pagination.totalItemsCount}
            onChange={onChangePagination}
          />
        </PaginationContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default UserGroupsPage;
