import React, { ReactNode, useEffect, useMemo } from 'react';
import { PageHeader, Table } from 'antd';
import { ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { useHistory, useParams } from 'react-router';
import { useLog } from 'shared/hooks';
import { LOG_COLUMNS } from './constants';
import { getLogColor } from 'utils/log-utils';
import { parseISODateFormat } from '../../utils/date-utils';

type TableLog = {
  key: string;
  value: string | ReactNode;
};

const ExLogDetailsPage = (): JSX.Element => {
  const { logId } = useParams<{ logId: string }>();

  const { log, fetchLog, isLogLoading } = useLog(logId, 'ex-');
  const history = useHistory();

  useEffect((): void => {
    fetchLog();
  }, [fetchLog]);

  const tableLogs = useMemo((): TableLog[] => {
    if (!log) return [];
    const {
      title,
      data,
      type,
      username,
      timestamp,
      deviceBrand,
      deviceModel,
      deviceSystemName,
      deviceSystemVersion
    } = log;
    return [
      { key: 'Timestamp', value: parseISODateFormat(timestamp, true) },
      {
        key: 'Type',
        value: <Styled.TypeRow color={getLogColor(type)}>{type.toUpperCase()}</Styled.TypeRow>
      },
      { key: 'Title', value: title },
      { key: 'Username', value: username },
      { key: 'Data', value: <Styled.DataRow>{data}</Styled.DataRow> },
      { key: 'Brand', value: deviceBrand },
      { key: 'Model', value: deviceModel },
      { key: 'System Name', value: deviceSystemName },
      { key: 'System Version', value: deviceSystemVersion }
    ];
  }, [log]);

  const goBack = (): void => {
    history.goBack();
  };

  return (
    <ContentContainer containerStyle={{ maxHeight: '100vh', minHeight: 'unset' }}>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title={`External Log details #${logId}`} />
        <Styled.TableContainer>
          <Table
            columns={LOG_COLUMNS}
            dataSource={tableLogs}
            loading={isLogLoading}
            pagination={false}
            size={'middle'}
          />
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default ExLogDetailsPage;
