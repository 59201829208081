import { useCallback, useState } from 'react';
import { useNotifications } from 'shared/hooks';
import { Bow, ESnackbarStyle } from 'shared/types';
import { addBow, deleteBow, getBow, updateBow } from 'services/api/bowsService';

type Result = {
  bow: Bow | null;
  isBowLoading: boolean;
  fetchBow: (bowId: string) => Promise<void>;
  createBow: (newBow: FormData) => Promise<void>;
  editBow: (bowId: string, updatedBow: FormData) => Promise<void>;
  removeBow: (bowId: string) => Promise<void>;
};

const useBow = (): Result => {
  const [bow, setBow] = useState<Bow | null>(null);
  const [isBowLoading, setIsBowLoading] = useState<boolean>(false);

  const { openNotification } = useNotifications();

  const fetchBow = useCallback(
    async (bowId: string): Promise<void> => {
      setIsBowLoading(true);
      try {
        const response = await getBow(bowId);
        setBow(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsBowLoading(false);
      }
    },
    [openNotification]
  );

  const createBow = async (newBow: FormData): Promise<void> => {
    setIsBowLoading(true);
    try {
      const response = await addBow(newBow);
      setBow(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsBowLoading(false);
    }
  };

  const editBow = async (bowId: string, updatedBow: FormData): Promise<void> => {
    setIsBowLoading(true);
    try {
      const response = await updateBow(bowId, updatedBow);
      setBow(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsBowLoading(false);
    }
  };

  const removeBow = async (bowId: string): Promise<void> => {
    setIsBowLoading(true);
    try {
      await deleteBow(bowId);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsBowLoading(false);
    }
  };

  return {
    bow,
    isBowLoading,
    fetchBow,
    createBow,
    editBow,
    removeBow
  };
};

export default useBow;
