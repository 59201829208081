import { AxiosResponse } from 'axios';
import { Device, Pagination, Shot, ShotEvent } from 'shared/types';
import { getQueryParams, getSortQueryParams } from 'utils/query-utils';
import ApiService from './api';

type DevicesResponse = {
  items: Device[];
  pagination: Pagination;
};

type ShotsResponse = {
  items: Shot[];
  pagination: Pagination;
};

type EventsResponse = {
  items: ShotEvent[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `users/all/bows`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getDevices = async (
  page: number,
  limit: number,
  sortParams: string[]
): Promise<AxiosResponse<DevicesResponse>> => {
  const params = getQueryParams({ page, limit });
  const urlSortParams = !!sortParams.length
    ? `&${getSortQueryParams(sortParams)}`
    : '&orderBy=-createdAt';
  return await ApiService.get<DevicesResponse>(`${getURL()}?${params}${urlSortParams}`);
};

export const getDeviceBySerialNumber = async (
  serialNumber: string
): Promise<AxiosResponse<Device>> => await ApiService.get<Device>(getURL(serialNumber));

export const getDeviceShots = async (
  bowSerialNumber: string,
  page: number,
  limit: number,
  startDate: string | null,
  endDate: string | null,
  sortParams: string[]
): Promise<AxiosResponse<ShotsResponse>> => {
  const params = getQueryParams({ page, limit });
  const dateRange =
    !!startDate && !!endDate ? `&timeRange.from=${startDate}&timeRange.to=${endDate}` : '';
  const urlSortParams = !!sortParams.length
    ? `&${getSortQueryParams(sortParams)}`
    : '&orderBy=-timestamp';
  return await ApiService.get<ShotsResponse>(
    `${getURL(bowSerialNumber, 'shots')}?${params}${dateRange}${urlSortParams}`
  );
};

export const getDeviceEvents = async (
  bowSerialNumber: string,
  page: number,
  limit: number,
  startDate: string | null,
  endDate: string | null,
  sortParams: string[]
): Promise<AxiosResponse<EventsResponse>> => {
  const params = getQueryParams({ page, limit });
  const dateRange =
    !!startDate && !!endDate ? `&timeRange.from=${startDate}&timeRange.to=${endDate}` : '';
  const urlSortParams = !!sortParams.length
    ? `&${getSortQueryParams(sortParams)}`
    : '&orderBy=-timestamp';
  return await ApiService.get<EventsResponse>(
    `${getURL(bowSerialNumber, 'events')}?${params}${dateRange}${urlSortParams}`
  );
};

export const downloadDeviceData = async (bowSerialNumber: string): Promise<AxiosResponse<Blob>> => {
  const params = getQueryParams({ format: 'xls' });
  return await ApiService.get<Blob>(`${getURL(bowSerialNumber, 'export')}?${params}`, {
    responseType: 'blob'
  });
};
