import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
`;

export const SearchInput = styled.div`
  width: 300px;
`;

export const RemoveButton = styled.div`
  margin-left: 30px;

  & button {
    background: #f5222d;
    color: #fff;
    border-radius: 4px;
    transition: all .2s;
  }

  & button:hover, button:focus {
    opacity: 0.8;
    background: #f5222d;
    color: #fff;
    border: 1px solid #fff;
  }
`;


export const TableContainer = styled.div`
  margin-top: 30px;
  overflow: auto;

  & tbody tr {
    cursor: pointer;
  }

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;
