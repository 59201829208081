import { useCallback, useState } from 'react';
import { ESnackbarStyle, Pagination, User } from 'shared/types';
import { getUsers } from 'services/api/usersService';
import { useNotifications, useDebounce } from 'shared/hooks';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  users: User[];
  isUsersLoading: boolean;
  searchValue: string;
  pagination: Pagination;
  onChangePagination: (currentPage: number, pageSize?: number) => void;
  fetchUsers: (showAll?: boolean) => Promise<void>;
  setSortParams: (values: string[]) => void;
  setSearchValue: (value: string) => void;
};

const useUsers = (): Result => {
  const [users, setUsers] = useState<User[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(true);
  const [sortParams, setSortParams] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: PAGE_SIZE,
    totalPagesCount: 0,
    totalItemsCount: 0
  });

  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const { openNotification } = useNotifications();

  const fetchUsers = useCallback(
    async (showAll?: boolean): Promise<void> => {
      setIsUsersLoading(true);
      try {
        const response = await getUsers(
          pagination.currentPage,
          pagination.pageSize,
          sortParams,
          debouncedSearchValue,
          showAll
        );
        const { totalPagesCount, totalItemsCount } = response.data.pagination;
        setUsers(response.data.items);
        setPagination((prevState) => ({ ...prevState, totalPagesCount, totalItemsCount }));
      } catch (e: any) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsUsersLoading(false);
      }
    },
    [
      pagination.currentPage,
      pagination.pageSize,
      openNotification,
      sortParams,
      debouncedSearchValue
    ]
  );

  const onChangePagination = (
    currentPage: number,
    pageSize: number | undefined = PAGE_SIZE
  ): void => {
    setPagination((prevState) => ({ ...prevState, currentPage, pageSize }));
  };

  return {
    users,
    pagination,
    isUsersLoading,
    searchValue,
    fetchUsers,
    setSortParams,
    setSearchValue,
    onChangePagination
  };
};

export default useUsers;
