import React from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { SIDEBAR_ITEMS } from './constants';
import * as Styled from './styles';
import { Button, Menu } from 'antd';
import { useLocation } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import LogoIcon from 'assets/images/logo.svg';
import { logoutUser } from 'services/store/reducers/authReducer';
import { closeSidebar } from 'services/store/reducers/sidebarReducer';
import { CloseOutlined } from '@ant-design/icons';
import { User } from 'shared/types';

const Sidebar = (): JSX.Element => {
  const isSidebarOpened = useAppSelector((state): boolean => state.sidebar.isSidebarOpened);
  const user = useAppSelector((state): User | null => state.auth.user);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const logOut = (): void => {
    dispatch(logoutUser());
  };

  const allowedSidebarItems = SIDEBAR_ITEMS.filter((role): boolean =>
    role.roles.some((permissibleRole): boolean => permissibleRole === user?.role)
  );

  const handleMenuItemClick = (subItemLink: string): void => {
    dispatch(closeSidebar());
    history.push(`${subItemLink}`);
  };

  const handleCloseButtonClick = (): void => {
    dispatch(closeSidebar());
  };

  return (
    <>
      <Styled.SidebarContainer isSidebarOpened={isSidebarOpened}>
        <Styled.CloseButton onClick={handleCloseButtonClick}>
          <CloseOutlined style={{ fontSize: '15px' }} />
        </Styled.CloseButton>
        <Styled.SidebarContent>
          <Styled.SidebarLogo>
            <NavLink to='/'>
              <img src={LogoIcon} alt='Logo' />
            </NavLink>
          </Styled.SidebarLogo>
          <Styled.SidebarItems>
            <Menu
              mode='inline'
              theme='light'
              defaultSelectedKeys={['/']}
              selectedKeys={[location.pathname]}
            >
              {allowedSidebarItems.map(({ title, icon: Icon, link, subItems }): JSX.Element => {
                return subItems ? (
                  <Menu.SubMenu key={link} icon={<Icon />} title={title}>
                    {subItems.map(
                      ({
                        title: subItemTitle,
                        icon: SubItemIcon,
                        link: subItemLink
                      }): JSX.Element => {
                        return (
                          <Menu.Item
                            key={subItemLink}
                            icon={<SubItemIcon />}
                            onClick={(): void => handleMenuItemClick(subItemLink)}
                          >
                            {subItemTitle}
                          </Menu.Item>
                        );
                      }
                    )}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={link}
                    icon={<Icon />}
                    onClick={(): void => handleMenuItemClick(link)}
                  >
                    {title}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Styled.SidebarItems>
        </Styled.SidebarContent>
        <Styled.SidebarFooter>
          <Styled.SidebarUserInfo>
            <div>Logged in as</div>
            <div>{user?.email}</div>
          </Styled.SidebarUserInfo>
          <Styled.SidebarUserActions>
            <Button type='primary' onClick={logOut}>
              Logout
            </Button>
          </Styled.SidebarUserActions>
        </Styled.SidebarFooter>
      </Styled.SidebarContainer>
      <Styled.Overlay isSidebarOpened={isSidebarOpened} onClick={handleCloseButtonClick} />
    </>
  );
};

export default Sidebar;
