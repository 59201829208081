export const FIRMWARE_COLUMNS = [
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
    sortKey: 'version',
    sorter: { multiple: 1 }
  },
  {
    title: 'Upload date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortKey: 'createdAt',
    sorter: { multiple: 1 }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortKey: 'status',
    sorter: { multiple: 1 }
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    sortKey: 'uploadedBy',
    sorter: { multiple: 1 }
  },
  {
    title: 'Log description',
    dataIndex: 'changelog',
    key: 'changelog',
    sortKey: 'changelog',
    sorter: { multiple: 1 }
  }
];
