import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { useAppSelector } from 'shared/hooks';
import io, { Socket } from 'socket.io-client';

type Props = {
  children: ReactNode;
};

type ContextState = {
  socket: Socket | null;
};

const initialContextState: ContextState = {
  socket: null
};

export const SocketContext = React.createContext<ContextState>(initialContextState);
export const useSocketContext = (): ContextState => useContext(SocketContext);

export const SocketProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);

  useEffect((): (() => void) | void => {
    if (isAuth) {
      const socket = io(`${process.env.REACT_APP_API_URL}`, {
        path: '/ws',
        auth: {
          token: localStorage.getItem('token')
        },
        transports: ['websocket', 'polling'] // use WebSocket first, if available
      });

      socket.on('connect_error', (err) => {
        // If WebSocket connection fails, revert to classic upgrade (polling then WebSocket)
        socket.io.opts.transports = ['polling', 'websocket'];
        // tslint:disable-next-line:no-console
        console.error('Connection Error:', err.message);
      });

      setSocket(socket);

      return (): void => {
        socket.disconnect();
      };
    }
  }, [isAuth]);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};
