import { Pagination, PublicGroup } from 'shared/types';
import { AxiosResponse } from 'axios';
import { getQueryParams } from 'utils/query-utils';
import ApiService from './api';

type GetPublicGroupsParams = {
  page: number;
  limit: number;
};

export type PublicGroupsResponse = {
  items: PublicGroup[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `public-groups`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getPublicGroups = async (
  query: Partial<GetPublicGroupsParams>
): Promise<AxiosResponse<PublicGroupsResponse>> => {
  const params = getQueryParams(query);
  return await ApiService.get<PublicGroupsResponse>(`${getURL('requests')}?${params}`);
};

export const getPublicGroupById = async (
  publicGroupId: string
): Promise<AxiosResponse<PublicGroup[]>> => {
  return await ApiService.get<PublicGroup[]>(`${getURL('requests')}?requestId=${publicGroupId}`);
};

export const acceptGroup = async (publicGroupId: string): Promise<AxiosResponse<PublicGroup>> => {
  return await ApiService.get<PublicGroup>(getURL(publicGroupId, 'accept'));
};

export const rejectGroup = async (
  publicGroupId: string,
  type: 'name' | 'image'
): Promise<AxiosResponse<PublicGroup>> => {
  return await ApiService.get<PublicGroup>(`${getURL(publicGroupId, 'reject')}?type=${type}`);
};
