import styled from 'styled-components';

export const SupportMessageFieldContainer = styled.div`
  height: 115px;

  textarea {
    height: auto;
    resize: none;
    margin-bottom: 24px;
  }
`;