import React, { useState } from 'react';
import { ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { Button, Checkbox, Input, PageHeader, Select } from 'antd';
import { Redirect, useHistory } from 'react-router';
import { useFormik } from 'formik';
import { ROLES } from 'shared/constants/roles';
import { validation } from 'services/validation';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EyeInvisibleOutlined, EyeTwoTone, CopyOutlined } from '@ant-design/icons';
import { ESnackbarStyle, EUserRole, NewUser, User } from 'shared/types';
import { addUser } from 'services/api/usersService';
import { useAppSelector, useCopyToClipboard, useNotifications } from 'shared/hooks';
import { getFieldError } from 'utils/error-utils';
import { checkRolePermission } from 'utils/role-utils';

type FormValues = {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  confirmPassword: string;
  sendEmail: boolean;
};

const AddUserPage = (): JSX.Element => {
  const history = useHistory();
  const { openNotification } = useNotifications();
  const user = useAppSelector((state): User | null => state.auth.user);
  const [isNewUserLoading, setIsNewUserLoading] = useState<boolean>(false);

  const { copyToClipboard } = useCopyToClipboard();

  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    role: ROLES[0].value,
    email: '',
    password: '',
    confirmPassword: '',
    sendEmail: false
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      const { email, password, firstName, lastName, role, sendEmail } = values;
      const newUser: NewUser = { email, password, firstName, lastName, role, sendEmail };
      setIsNewUserLoading(true);
      try {
        await addUser(newUser);
        openNotification(ESnackbarStyle.SUCCESS, 'User was added successfully');
        history.push('/users');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsNewUserLoading(false);
      }
    },
    initialValues,
    validationSchema: validation.ADD_USER
  });

  const goBack = (): void => {
    history.goBack();
  };

  const handleSelectFieldChange =
    (fieldName: string): ((value: string) => void) =>
    (value: string): void => {
      formik.setFieldValue(fieldName, value);
    };

  const handleCheckboxChange =
    (fieldName: string): ((event: CheckboxChangeEvent) => void) =>
    (event: CheckboxChangeEvent): void => {
      formik.setFieldValue(fieldName, event.target.checked);
    };

  const handleGeneratePasswordClick = (): void => {
    const password = Math.random().toString(36).slice(-8);
    formik.setValues({ ...formik.values, password, confirmPassword: password });
  };

  const handleCopyButtonClick = (): void => {
    copyToClipboard(formik.values.password);
  };

  if (
    user?.role &&
    !checkRolePermission([EUserRole.master_admin, EUserRole.admin_user], user.role)
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Add a new user' />
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.FormInputContainer>
            <Input
              placeholder='First name'
              size='large'
              value={formik.values.firstName}
              name='firstName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFieldError(formik, 'firstName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Last name'
              size='large'
              value={formik.values.lastName}
              name='lastName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFieldError(formik, 'lastName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Select
              allowClear={false}
              size='large'
              placeholder='Role'
              value={formik.values.role}
              style={{ width: '100%' }}
              onChange={handleSelectFieldChange('role')}
            >
              {ROLES.map(
                (item): JSX.Element => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              )}
            </Select>
            {getFieldError(formik, 'role')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Email'
              size='large'
              value={formik.values.email}
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFieldError(formik, 'email')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Styled.FormInputInner>
              <Input.Password
                placeholder='Password'
                size='large'
                value={formik.values.password}
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                iconRender={(visible): JSX.Element =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              {formik.values.password && (
                <Button
                  type='primary'
                  onClick={handleCopyButtonClick}
                  shape='circle'
                  icon={<CopyOutlined />}
                />
              )}
            </Styled.FormInputInner>
            {getFieldError(formik, 'password')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input.Password
              placeholder='Confirm password'
              size='large'
              value={formik.values.confirmPassword}
              name='confirmPassword'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              iconRender={(visible): JSX.Element =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {getFieldError(formik, 'confirmPassword')}
          </Styled.FormInputContainer>
          <Styled.ButtonGroup>
            <Button type='primary' onClick={handleGeneratePasswordClick}>
              Generate password automatically
            </Button>
            <Button type='primary' htmlType='submit' loading={isNewUserLoading}>
              Add
            </Button>
          </Styled.ButtonGroup>
          <Styled.CheckboxContainer>
            <Checkbox
              checked={formik.values.sendEmail}
              onChange={handleCheckboxChange('sendEmail')}
            >
              Send credentials by e-mail
            </Checkbox>
          </Styled.CheckboxContainer>
        </Styled.Form>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default AddUserPage;
