import { notification } from 'antd';
import { useCallback } from 'react';
import { DEFAULT_ERROR } from 'shared/constants/errors';
import { ESnackbarStyle } from 'shared/types';

type Result = {
  openNotification: (type: ESnackbarStyle, message: string) => void;
};

const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const useNotifications = (): Result => {
  const openNotification = useCallback((type: ESnackbarStyle, message: string): void => {
    notification[type]({
      message: `${capitalize(type)}!`,
      description: message || DEFAULT_ERROR
    });
  }, []);

  return {
    openNotification
  };
};

export default useNotifications;
