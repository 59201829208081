import React from 'react';
import { Tooltip } from 'antd';

export const SESSIONS_COLUMNS = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    ellipsis: true,
    width: 50,
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    width: 130,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    width: 220
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Score',
    dataIndex: ['stats', 'score'],
    key: 'stats.score',
    ellipsis: true,
    width: 60
  },
  {
    title: 'Shots',
    dataIndex:['stats', 'shots'],
    key: 'stats.shots',
    ellipsis: true,
    width: 60
  },
  {
    title: <Tooltip title='Shot Duration'>S.D.</Tooltip>,
    dataIndex: ['stats', 'shotDuration'],
    key: 'stats.shotDuration',
    ellipsis: true,
    width: 50
  },
  {
    title: <Tooltip title='Shot Level'>S.L.</Tooltip>,
    dataIndex: ['stats', 'shotLevel'],
    key: 'stats.shotLevel',
    ellipsis: true,
    width: 50
  },
  {
    title: <Tooltip title='Shot Steadiness'>S.S.</Tooltip>,
    dataIndex: ['stats', 'shotSteadiness'],
    key: 'stats.shotSteadiness',
    ellipsis: true,
    width: 50
  },
  {
    title: <Tooltip title='Shot Torque'>S.T.</Tooltip>,
    dataIndex: ['stats', 'shotTorque'],
    key: 'stats.shotTorque',
    ellipsis: true,
    width: 50
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    width: 155
  },
];
