import React from 'react';
import _ from 'lodash';
import * as Styled from './styles';
import { formatNumber } from 'utils/report-utils';
import { getActiveColorTorqueAndLevel } from './helpers';
import { Title } from 'shared/components';

type Props = {
  value?: number;
  bowTorque?: number;
  activeColor?: string;
};

const Torque: React.FC<Props> = ({ value, activeColor = '#F90707', bowTorque }): JSX.Element => {
  const formattedValue = formatNumber(bowTorque);
  const valueForIndicator = Math.max(-100, Math.min(100, bowTorque || 0));
  const translateXForIndicator = valueForIndicator * 0.25;
  activeColor = getActiveColorTorqueAndLevel(value, activeColor);

  return (
    <Styled.Chart>
      <svg height='52' width='80' viewBox='0 0 80 52'>
        <line
          y1={26}
          x1={16}
          y2={26}
          x2={36}
          strokeWidth={3}
          strokeDasharray={'1 1'}
          stroke='#707070'
        />
        <line
          y1={26}
          x1={44}
          y2={26}
          x2={64}
          strokeWidth={3}
          strokeDasharray={'1 1'}
          stroke='#707070'
        />
        <line y1={6.5} x1={40} y2={22} x2={40} strokeWidth={3} stroke='#707070' />
        <line y1={30} x1={40} y2={45.5} x2={40} strokeWidth={3} stroke='#707070' />
        <circle cx='40' cy='26' r={4} stroke='#707070' strokeWidth={3} fill='transparent' />
        {value !== undefined ? (
          <circle
            cx='40'
            cy='26'
            r={11.5}
            stroke={activeColor}
            strokeWidth={3}
            fill='transparent'
            transform={`translate(${translateXForIndicator}, 0)`}
          />
        ) : null}
      </svg>
      <Title
        title={
          formattedValue && !_.isNil(bowTorque)
            ? formattedValue + '% ' + (bowTorque ? (bowTorque > 0 ? 'R' : 'L') : '')
            : '--'
        }
        variant='light'
        type='regular'
      />
    </Styled.Chart>
  );
};

export default Torque;
