import { Tag } from 'antd';
import { EUserStatus } from 'shared/types';

export const USER_COLUMNS = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    sorter: { multiple: 1 },
    sortKey: 'username'
  },
  {
    title: 'Full name',
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    sorter: { multiple: 1 },
    sortKey: 'firstName'
  },
  {
    title: 'Added',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: { multiple: 1 },
    sortKey: 'createdAt'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: renderStatusTag
  },
  {
    title: 'Last Shot',
    dataIndex: 'lastShot',
    key: 'lastShot',
  }
];

// tslint:disable-next-line:typedef
export function renderStatusTag(status: EUserStatus) {
  switch (status) {
    case EUserStatus.Active:
      return <Tag color='green'>Active</Tag>;
    case EUserStatus.Disabled:
      return <Tag color='red'>Disabled</Tag>;
    case EUserStatus.Created:
      return <Tag color='blue'>Pending Confirmation</Tag>;
    case EUserStatus.Deleted:
      return <Tag color='gray'>Deleted</Tag>;
    default:
      return status || '-';
  }
}
