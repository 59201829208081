import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Pagination, SupportMessage } from 'shared/types';
import { getQueryParams } from 'utils/query-utils';

type SupportMessagesResponse = {
  items: SupportMessage[],
  pagination: Pagination;
}

export const getMessages = async (
  userId: string,
  page: number,
  limit: number
): Promise<AxiosResponse<SupportMessagesResponse>> => {
  const params = getQueryParams({ page, limit });
  return await ApiService.get(`/support/${userId}?${params}`);
}

export const addMessage = async (userId: number, content: string ): Promise<AxiosResponse<SupportMessage>> =>
  await ApiService.post<{ userId: number, content: string }, SupportMessage>(`/support`, { userId, content });
