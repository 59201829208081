import React, { CSSProperties, ReactNode } from 'react';
import { Container } from './styles';

type Props = {
  children: ReactNode;
  containerStyle?: CSSProperties
};

const ContentContainer: React.FC<Props> = ({ children, containerStyle }): JSX.Element => {
  return <Container style={containerStyle}>{children}</Container>;
};

export default ContentContainer;
