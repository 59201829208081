import { useCallback, useState } from 'react';
import { Bow, ESnackbarStyle } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getBows } from 'services/api/bowsService';

type Result = {
  bows: Bow[];
  fetchBows: (showAll?: boolean) => Promise<void>;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalBows: number;
  isBowsLoading: boolean;
};

const useBows = (): Result => {
  const [bows, setBows] = useState<Bow[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalBows, setTotalBows] = useState<number>(0);
  const [isBowsLoading, setIsBowsLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchBows = useCallback(async (): Promise<void> => {
    setIsBowsLoading(true);
    try {
      const response = await getBows(currentPage, PAGE_SIZE);
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setBows(response.data.items);
      setTotalPages(totalPagesCount);
      setTotalBows(totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsBowsLoading(false);
    }
  }, [currentPage, openNotification]);

  return {
    bows,
    fetchBows,
    totalPages,
    currentPage,
    setCurrentPage,
    totalBows,
    isBowsLoading
  };
};

export default useBows;
