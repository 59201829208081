import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Pagination, UserGroup } from 'shared/types';
import {getQueryParams} from 'utils/query-utils';

type GroupsResponse = {
  items: UserGroup[];
  pagination: Pagination;
}

const getURL = (...rest: string[]): string => {
  const mainUrl = `groups`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getGroups = async (
  page: number,
  limit: number,
  search: string,
): Promise<AxiosResponse<GroupsResponse>> => {
  const params = getQueryParams({ page, limit, search });
  return await ApiService.get(`/groups?${params}`);
}

export const getGroup = async (groupId: string): Promise<AxiosResponse<UserGroup>> =>
  await ApiService.get<UserGroup>(getURL(groupId));

export const updateGroup = async (groupId: string, formData: FormData): Promise<AxiosResponse<UserGroup>> =>
  await ApiService.patch<FormData, UserGroup>(getURL(groupId, 'admin'), formData);

export const deleteGroup = async (groupId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL(groupId));
