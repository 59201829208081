import { useCallback, useState } from 'react';
import { ESnackbarStyle, SessionShot } from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getCsvShot, getShots } from 'services/api/shotsService';
import { useNotifications } from 'shared/hooks/helper-hooks';
import { parseISO } from 'date-fns';

type Result = {
  shots: SessionShot[];
  fetchShots: (userId: string | number) => Promise<void>;
  fetchCsvShot: (shotId: string | number, shotName: string) => Promise<void>;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalShots: number;
  isShotsLoading: boolean;
};

const useUserShots = (): Result => {
  const [shots, setShots] = useState<SessionShot[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalShots, setTotalShots] = useState<number>(0);
  const [isShotsLoading, setIsShotsLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchShots = useCallback(async (userId: string | number): Promise<void> => {
    setIsShotsLoading(true);
    try {
      const response = await getShots(currentPage, PAGE_SIZE, userId);
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setShots(response.data.items);
      setTotalPages(totalPagesCount);
      setTotalShots(totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsShotsLoading(false);
    }
  }, [currentPage, openNotification]);

  const fetchCsvShot = useCallback(async (shotId: string | number, shotName: string): Promise<void> => {
    try {
      const {data: {data, timestamp}} = await getCsvShot(shotId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]))
      link.setAttribute('download', `csv_shot_${shotName}_${parseISO(timestamp).getTime()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  }, [openNotification])

  return {
    shots,
    totalPages,
    totalShots,
    isShotsLoading,
    currentPage,
    setCurrentPage,
    fetchShots,
    fetchCsvShot,
  }
}

export default useUserShots;
