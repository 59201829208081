import { ESnackbarStyle, Pagination, Session } from 'shared/types';
import { deleteSession, getSessions } from 'services/api/sessionsService';
import { useCallback, useState } from 'react';
import { useDebounce, useNotifications } from 'shared/hooks';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  sessions: Session[];
  pagination: Pagination;
  searchValue: string;
  isSessionsLoading: boolean;
  fetchSessions: (params?: Partial<{ withoutShots: boolean; userId?: number }>) => Promise<void>;
  setSearchValue: (value: string) => void;
  removeSession: (sessionId: string) => Promise<void>;
  onChangePagination: (currentPage: number, pageSize?: number) => void;
};

const useSessions = (): Result => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [isSessionsLoading, setIsSessionsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({
    totalItemsCount: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE
  });

  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const { openNotification } = useNotifications();

  const fetchSessions = useCallback(
    async (params?: Partial<{ withoutShots?: boolean; userId?: number }>): Promise<void> => {
      const { withoutShots = false, userId } = params ?? {};
      setIsSessionsLoading(true);
      try {
        const response = await getSessions({
          page: pagination.currentPage,
          limit: pagination.pageSize,
          search: debouncedSearchValue,
          withoutShots,
          userId
        });
        const { totalPagesCount, totalItemsCount } = response.data.pagination;
        setSessions(response.data.items);
        setPagination((prevState) => ({ ...prevState, totalPagesCount, totalItemsCount }));
      } catch (e: any) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsSessionsLoading(false);
      }
    },
    [pagination.currentPage, pagination.pageSize, openNotification, debouncedSearchValue]
  );

  const removeSession = async (sessionId: string): Promise<void> => {
    setIsSessionsLoading(true);
    try {
      await deleteSession(sessionId);
    } catch (e: any) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsSessionsLoading(false);
    }
  };

  const onChangePagination = (
    currentPage: number,
    pageSize: number | undefined = PAGE_SIZE
  ): void => {
    setPagination((prevState) => ({ ...prevState, currentPage, pageSize }));
  };

  return {
    sessions,
    pagination,
    searchValue,
    isSessionsLoading,
    fetchSessions,
    removeSession,
    setSearchValue,
    onChangePagination
  };
};

export default useSessions;
