import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  overflow: auto;

  & tbody tr {
    cursor: pointer;
  }

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const TypeRow = styled.div<{ color: string }>`
  color: ${({ color }): string => color};
`;
