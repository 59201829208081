import styled from 'styled-components';
import { Typography } from 'antd';

export const PageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  overflow: auto;

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const Group = styled(Typography.Link)`
  display: flex;
  align-items: center;
`;

export const GroupImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

export const GroupImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const GroupName = styled.div`
  margin-left: 10px;
`;
