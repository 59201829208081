import React from 'react';

type Props = {
  color?: string;
  size?: number;
};

const HuntIcon: React.FC<Props> = (props): JSX.Element => {
  const { size = 20, color = '#000' } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox={`0 0 ${size * 2} ${size * 2}`}
    >
      <g id='Group_21928' data-name='Group 21928' transform='translate(-263.794 -144.227)'>
        <path
          id='Subtraction_31'
          data-name='Subtraction 31'
          d='M13.853,45.537H0V0H14.729c.014.154.084,3.363-.012,6.237h7.793a39.807,39.807,0,0,1-2.262,13.878A22.344,22.344,0,0,1,13.9,27.808c-2.58,1.909-10.414,5.85-11.3,6.294v3.1c.333.928,1.826,3.087,8.474,3.087h2.782v5.251Zm.531-34.375c-.075.653-.164,1.244-.25,1.784a47.744,47.744,0,0,1-2.793,10.6,15.689,15.689,0,0,0,4.5-5.614,18.176,18.176,0,0,0,1.71-6.775Z'
          transform='translate(285.138 153.08)'
          fill={color}
        />
        <path
          id='Union_32'
          data-name='Union 32'
          d='M18.27,54.39,9.961,48.1s-.831-6.012,0-8.063a23.47,23.47,0,0,1,3.531-5.022,5.912,5.912,0,0,1-1.317-3.254,7.411,7.411,0,0,1,.971-3.041s-3.185.354-5.055-.707-3.117-4.244-3.117-4.244a6.9,6.9,0,0,1,4.086,0c2.076.707,3.532,1.344,3.532,1.344a36.672,36.672,0,0,0-6.146-3.678c-4.155-2.069-8.622-6.9-5.272-14.243S6.775,0,6.775,0A35.731,35.731,0,0,0,3.8,5.7c-.623,1.98-1.247,4.2-1.247,4.2A12.693,12.693,0,0,1,5.562,6.684,4.994,4.994,0,0,1,8.731,5.7,16.408,16.408,0,0,0,5.356,9.018C4.005,10.9,3.243,15.277,3.243,15.277S4.827,12.051,7.675,11.1a9.183,9.183,0,0,1,5.471,0,10.908,10.908,0,0,0-5.35,2.29,7.807,7.807,0,0,0-2.822,4,2.706,2.706,0,0,0,1.732,1.485,59.568,59.568,0,0,1,5.47,2.546s-.969-1.98,0-3.324a6.753,6.753,0,0,1,3.048-1.98s-1.732,1.627-1.732,2.9,4.778,6.648,4.778,6.648Z'
          transform='translate(263.794 144.227)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default HuntIcon;
