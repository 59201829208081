export const BOW_COLUMNS = [
  {
    title: 'Model',
    dataIndex: 'modelName',
    key: 'modelName',
    ellipsis: true,
  },
  {
    title: 'Draw Weight',
    dataIndex: 'drawWeight',
    key: 'drawWeight',
    ellipsis: true,
    render: (item: number[]): JSX.Element => {
      return <>{item.join(', ')}</>
    }
  },
  {
    title: 'Draw Length',
    dataIndex: 'drawLength',
    key: 'drawLength',
    ellipsis: true,
    render: (item: number[]): JSX.Element => {
      return <>{Math.min(...item)} - {Math.max(...item)}</>
    }
  },
  {
    title: 'Let-Off',
    dataIndex: 'letOff',
    key: 'letOff',
    ellipsis: true,
    render: (item: number[]): JSX.Element => {
      return <>{item.join(', ')}</>
    }
  },
  {
    title: 'LR/HR',
    dataIndex: 'lr/hr',
    key: 'lr/hr',
    ellipsis: true,
    render: (item: string[]): JSX.Element => {
      return <>{item.join(', ')}</>
    }
  },
];
