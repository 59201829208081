import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Typography } from 'antd';
import Pagination from 'antd/lib/pagination';
import * as Styled from './styles';
import { useUserShots } from 'shared/hooks';
import {ContentContainer} from 'shared/components';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router';
import { ADVANCED_SHOTS_COLUMN } from './constants';
import { DeviceInformation } from '../../shared/types';

type TableShot = {
  id:                         string | number;
  name:                       string;
  shotScoreTotal:             number;
  shotScoreAimSteadiness:     number;
  shotScoreBowAngle:          number;
  shotScoreAimTime:           number;
  shotScoreBowTorque:         number;
  valid:                      string;
  timestamp:                  string;
  userMetadata?:               DeviceInformation & {appVersion: string};
}

type QueryParams = {
  userId: string;
};

const UserShotsPage = (): JSX.Element => {
  const [tableShots, setTableShots] = useState<TableShot[]>([]);
  const { userId } = useParams<QueryParams>();

  const history = useHistory();

  const {
    shots,
    fetchShots,
    fetchCsvShot,
    isShotsLoading,
    currentPage,
    totalShots,
    setCurrentPage,
  } = useUserShots();

  useEffect((): void => {
    fetchShots(userId);
  }, [fetchShots, userId]);

  useEffect((): void => {
    const modifiedShots = shots.map((shot): TableShot => {
      return {
        id: shot.id,
        name: shot.name,
        shotScoreTotal: shot.shotScoreTotal,
        shotScoreAimSteadiness: shot.shotScoreAimSteadiness,
        shotScoreBowAngle: shot.shotScoreBowAngle,
        shotScoreAimTime: shot.shotScoreAimTime,
        shotScoreBowTorque: shot.shotScoreBowTorque,
        valid: shot.isShotDetected ? 'True' : 'False',
        timestamp: format(new Date(shot.timestamp), 'yyyy-MM-dd HH:mm:ss'),
        userMetadata: shot.userMetadata,
      };
    });
    setTableShots(modifiedShots);
  }, [shots, currentPage]);

  const goBack = (): void => {
    history.goBack();
  };

  return (
      <ContentContainer containerStyle={{ maxWidth: 'unset', maxHeight: '100vh' }}>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Shots' />
        <Styled.TableContainer>
          <Table
            columns={[
              ...ADVANCED_SHOTS_COLUMN,
              {
                title: 'Actions',
                width: 100,
                render: (record): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): Promise<void> => fetchCsvShot(record.id, record.name)}>
                      Export CSV
                    </Typography.Link>
                  );
                }
              }
            ]}
            dataSource={tableShots}
            pagination={false}
            loading={isShotsLoading}
            size={'middle'}
          />
        </Styled.TableContainer>
        {totalShots > PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalShots}
              onChange={setCurrentPage}
              pageSize={PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default UserShotsPage;
