// Libs
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

// Components
import { Input, PageHeader, Table } from 'antd';
import { ContentContainer } from 'shared/components';

// Services
import { getGroupsWithEvents } from 'services/api/eventsService';

// Hooks
import { useHistory } from 'react-router-dom';

// Constants
import { COLUMNS } from './constants';

// Types
import { EventGroup } from 'shared/types';

// Styles
import * as Styled from './styles';

const EventsPage = (): JSX.Element => {
  const [groups, setGroups] = useState<EventGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    fetchGroupsWithEvents();
    // eslint-disable-next-line
  }, []);

  const filteredGroups = useMemo(() => {
    return groups.filter((record: EventGroup) =>
      Object.values(_.pick(record, ['id', 'name'])).some(
        (value) =>
          !_.isUndefined(value) && String(value).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [groups, searchValue]);

  const fetchGroupsWithEvents = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await getGroupsWithEvents();
      setGroups(response.data);
    } catch (err: any) {
      // tslint:disable-next-line:no-console
      console.error('[fetchGroupsWithEvents] ', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (record: EventGroup): void => {
    history.push(`/group-events/${record.id}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Group Events' />

        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input.Search
              placeholder='Search by group'
              onSearch={(value) => setSearchValue(value)}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>

        <Styled.TableContainer>
          <Table
            columns={COLUMNS}
            dataSource={filteredGroups}
            pagination={false}
            loading={loading}
            size={'middle'}
            rowKey='id'
            onRow={(record) => ({
              onClick: () => handleRowClick(record)
            })}
          />
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default EventsPage;
