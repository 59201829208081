import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { LOGS_PAGE_SIZE } from 'shared/constants/pagination';
import { DebouncedFunc, ESnackbarStyle, Log, LogType, Pagination } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { getLogs, LogsResponse } from 'services/api/logService';
import debounce from 'lodash.debounce';

type Params = {
  userId?: string | number;
  logsType?: string;
};

type Result = {
  items: Log[];
  pagination: Pagination;
  isLogsLoading: boolean;
  currentPage: number;
  inputSearch: string;
  type: LogType | undefined;
  setInputSearch: Dispatch<SetStateAction<string>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  fetchLogs: (useLoader?: boolean) => Promise<void>;
  handleChangeSearch: DebouncedFunc<(data: string) => void>;
  isAutoUpdate: boolean;
  setIsAutoUpdate: Dispatch<SetStateAction<boolean>>;
  setType: Dispatch<SetStateAction<LogType | undefined>>;
};

const initialLogs: LogsResponse = {
  items: [],
  pagination: {
    totalItemsCount: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: LOGS_PAGE_SIZE
  }
};

const useLogs = ({ userId, logsType = '' }: Params): Result => {
  const [logs, setLogs] = useState<LogsResponse>(initialLogs);
  const [isLogsLoading, setIsLogsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [inputSearch, setInputSearch] = useState('');
  const [type, setType] = useState<LogType | undefined>(undefined);
  const [isAutoUpdate, setIsAutoUpdate] = useState<boolean>(false);

  const { openNotification } = useNotifications();

  const fetchLogs = useCallback(
    async (useLoader: boolean = true): Promise<void> => {
      if (useLoader) {
        setIsLogsLoading(true);
      }
      try {
        const [response] = await Promise.all([
          getLogs(
            {
              page: currentPage,
              limit: LOGS_PAGE_SIZE,
              search,
              qwpnr: 'nSdjySDdtb',
              type,
              userId
            },
            logsType
          )
        ]);
        setLogs(response.data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsLogsLoading(false);
      }
    },
    [openNotification, userId, currentPage, search, type, logsType]
  );

  const handleChangeSearch = useMemo(
    (): DebouncedFunc<(data: string) => void> =>
      debounce((data: string): void => {
        setSearch(data);
        setInputSearch(data);
      }, 500),
    []
  );

  return {
    ...logs,
    fetchLogs,
    isLogsLoading,
    currentPage,
    inputSearch,
    type,
    handleChangeSearch,
    setCurrentPage,
    setInputSearch,
    isAutoUpdate,
    setIsAutoUpdate,
    setType
  };
};

export default useLogs;
