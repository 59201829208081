import React, { useEffect } from 'react';
import { ChangePasswordModal, ConfirmModal, ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { Button, Input, PageHeader, Select } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useFormik } from 'formik';
import { ROLES } from 'shared/constants/roles';
import { validation } from 'services/validation';
import { useAppDispatch, useNotifications, useUser } from 'shared/hooks';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { updateUser } from 'services/api/usersService';
import { ESnackbarStyle, EUserRole } from 'shared/types';
import { DeleteOutlined } from '@ant-design/icons';
import { getFieldError } from 'utils/error-utils';
import { checkRolePermission } from 'utils/role-utils';
import { Redirect } from 'react-router-dom';

type FormValues = {
  firstName: string;
  lastName: string;
  role: EUserRole | undefined;
  email: string;
};

type QueryParams = {
  userId: string;
};

const EditUserPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userId } = useParams<QueryParams>();
  const { openNotification } = useNotifications();
  const { user, isUserLoading, fetchUser, removeUser } = useUser();

  useEffect((): void => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  const initialValues: FormValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    role: user?.role || undefined,
    email: user?.email || ''
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      try {
        await updateUser(userId, {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role!
        });
        history.push('/users');
        openNotification(ESnackbarStyle.SUCCESS, 'User successfully updated');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    initialValues,
    validationSchema: validation.EDIT_USER,
    enableReinitialize: true
  });

  const goBack = (): void => {
    history.goBack();
  };

  const showRemoveUserModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this user?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeUser(userId);
            history.push('/users');
            openNotification(ESnackbarStyle.SUCCESS, 'User successfully deleted');
          }}
        />
      )
    );
  };

  const handleSelectFieldChange =
    (fieldName: string): ((value: string) => void) =>
    (value: string): void => {
      formik.setFieldValue(fieldName, value);
    };

  const showChangePasswordModal = (): void => {
    dispatch(showModal(<ChangePasswordModal userId={userId} />));
  };

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.master_admin, EUserRole.admin_user, EUserRole.support_user, EUserRole.moderator],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Edit user' />
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.FormInputContainer>
            <Input
              placeholder='First name'
              size='large'
              value={formik.values.firstName}
              name='firstName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'firstName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Last name'
              size='large'
              value={formik.values.lastName}
              name='lastName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'lastName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Select
              size='large'
              placeholder='Role'
              value={formik.values.role}
              style={{ width: '100%' }}
              onChange={handleSelectFieldChange('role')}
              allowClear
              disabled={isUserLoading || formik.isSubmitting}
            >
              {ROLES.map(
                (item): JSX.Element => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              )}
            </Select>
            {getFieldError(formik, 'role')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Email'
              size='large'
              value={formik.values.email}
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'email')}
          </Styled.FormInputContainer>
          <Styled.FormActions>
            <Button
              type='primary'
              htmlType='button'
              onClick={showChangePasswordModal}
              disabled={isUserLoading || formik.isSubmitting}
            >
              Change password
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              disabled={isUserLoading || formik.isSubmitting || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Apply
            </Button>
          </Styled.FormActions>
          <Styled.RemoveButton>
            <Button
              icon={<DeleteOutlined />}
              type='default'
              onClick={showRemoveUserModal}
              disabled={isUserLoading || formik.isSubmitting}
            >
              Remove user
            </Button>
          </Styled.RemoveButton>
        </Styled.Form>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default EditUserPage;
