import React, { CSSProperties, useMemo } from 'react';
import { getAverageScore } from 'utils/report-utils';
import { TargetHit } from 'shared/types';
import { SmallTarget, Title } from 'shared/components';
import * as Styled from './styles';

type Props = {
  targetHits: TargetHit[];
  shoots: {
    total: number;
    result: number;
    distance: string;
  }[];
};

type RowProps = {
  title: string;
  value: string;
  unit?: string;
  targetRowStyles?: CSSProperties;
};

const TargetRow: React.FC<RowProps> = (props): JSX.Element => {
  const { title, value, unit, targetRowStyles } = props;

  return (
    <Styled.Row style={targetRowStyles}>
      <Title title={title} variant='light' />
      <Styled.RowValue>
        <Title title={value} variant='light' type='regular' />
        {unit && <Title title={`${unit}`} variant='light' />}
      </Styled.RowValue>
    </Styled.Row>
  );
};

const Target: React.FC<Props> = (props): JSX.Element => {
  const { shoots, targetHits } = props;

  const overall = useMemo((): number => {
    const result = shoots.reduce((acc, curr): number => acc + curr.result, 0);
    return getAverageScore(Object.keys(shoots).length, result, true);
  }, [shoots]);

  return (
    <Styled.Target>
      <Styled.TargetStats>
        {shoots.map(
          ({ result, distance }, index): JSX.Element => (
            <TargetRow key={index} title={`${distance} YDS.`} value={`${result}`} unit='IN.' />
          )
        )}
        <TargetRow
          title={'OVERALL'}
          value={`${overall} IN.`}
          targetRowStyles={{ marginTop: 'auto' }}
        />
      </Styled.TargetStats>
      <Styled.TargetChart>
        <SmallTarget targetHits={targetHits} />
      </Styled.TargetChart>
    </Styled.Target>
  );
};

export default Target;
