import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchInput = styled.div`
  width: 300px;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  overflow: auto;

  & .ant-collapse-header {
    font-weight: bold;
    font-size: 20px;
  }
`;
