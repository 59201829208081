import { ReactNode } from 'react';
import { TypeRow } from './styles';
import { LogType } from 'shared/types';
import { getLogColor } from 'utils/log-utils';
import { parseISODateFormat } from '../../utils/date-utils';

export const ACTIVITY_LOG_COLUMNS = [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    ellipsis: true,
    render: (date: string): ReactNode => {
      return <>{parseISODateFormat(date, true)}</>
    }
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    render: (type: LogType): ReactNode => {
      return <TypeRow color={getLogColor(type)}>{type.toUpperCase()}</TypeRow>
    },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: 'Data',
    dataIndex: 'data',
    key: 'data',
    ellipsis: true,
  },
  {
    title: 'Username',
    dataIndex: ['username'],
    key: 'username',
    ellipsis: true,
  },
  {
    title: 'Brand',
    dataIndex: 'deviceBrand',
    key: 'deviceBrand',
    ellipsis: true,
  },
  {
    title: 'Model',
    dataIndex: 'deviceModel',
    key: 'deviceModel',
    ellipsis: true,
  },
  {
    title: 'System Name',
    dataIndex: 'deviceSystemName',
    key: 'deviceSystemName',
    ellipsis: true,
  },
  {
    title: 'System Version',
    dataIndex: 'deviceSystemVersion',
    key: 'deviceSystemVersion',
    ellipsis: true,
  },
];
