import React from 'react';
import { Modal, Input } from 'antd';
import { useAppDispatch, useAppSelector, useNotifications } from 'shared/hooks';
import {
  closeModal,
} from 'services/store/reducers/modalReducer';
import {
  ESnackbarStyle,
} from 'shared/types';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import * as Styled from './styles';
import { addMessage } from 'services/api/supportMessagesService';
import { getFieldError } from 'utils/error-utils';

type Props = {
  userId: string;
  fetchMessages: () => Promise<void>;
};

const SupportMessageModal: React.FC<Props> = ({
  userId,
  fetchMessages
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const isModalOpened = useAppSelector((state): boolean => state.modal.isModalOpened);
  const { openNotification } = useNotifications();

  const initialValues = {
    message: '',
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      const { message } = values;
      try {
        await addMessage(Number(userId), message);
        await fetchMessages();
        openNotification(
          ESnackbarStyle.SUCCESS,
          `Message was successfully sent`
        );
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        handleCancel();
      }
    },
    initialValues,
    validationSchema: validation.ADD_SUPPORT_MESSAGE
  });

  const { values, isSubmitting, isValid } = formik;

  const handleSubmit = (): void => {
    formik.handleSubmit();
  };

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      visible={isModalOpened}
      title='Support Message'
      onOk={handleSubmit}
      okText='Send'
      onCancel={handleCancel}
      okButtonProps={{
        disabled: isSubmitting || !isValid,
        loading: isSubmitting
      }}
    >
      <Styled.SupportMessageFieldContainer>
        <Input.TextArea
          placeholder='Message'
          size='large'
          value={values.message}
          name='message'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          rows={4}
        />
        {getFieldError(formik, 'message')}
      </Styled.SupportMessageFieldContainer>
    </Modal>
  );
}

export default SupportMessageModal;
