import { AxiosResponse } from 'axios';
import { ApiServiceV2 } from './api';
import { Pagination, Session, SessionComment } from 'shared/types';
import { getQueryParams } from 'utils/query-utils';

type SessionsResponse = {
  items: Session[];
  pagination: Pagination;
};

type CommentsResponse = {
  items: SessionComment[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `sessions`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getSessions = async (params: {
  page: number;
  limit: number;
  search: string;
  userId?: number;
  withoutShots: boolean;
}): Promise<AxiosResponse<SessionsResponse>> => {
  const { page, limit, search, userId, withoutShots } = params;
  const queryParams = getQueryParams({ page, limit, search, userId, withoutShots });
  return await ApiServiceV2.get(`/sessions?${queryParams}`);
};

export const getSessionComments = async (
  sessionId: string,
  page: number,
  limit: number
): Promise<AxiosResponse<CommentsResponse>> => {
  const params = getQueryParams({ page, limit });
  return await ApiServiceV2.get(`/sessions/${sessionId}/comments?${params}`);
};

export const deleteSessionComment = async (commentId: string): Promise<AxiosResponse<void>> =>
  await ApiServiceV2.delete(getURL('comments', commentId));

export const deleteSession = async (sessionId: string): Promise<AxiosResponse<void>> =>
  await ApiServiceV2.delete(getURL(sessionId));
