import React from 'react';
import { formatNumber } from 'utils/report-utils';
import { getActiveColorTorqueAndLevel } from './helpers';
import * as Styled from './styles';
import { Title } from 'shared/components/title';

type Props = {
  value?: number;
  bowLevelDegrees?: number;
  activeColor?: string;
};

const Level: React.FC<Props> = ({
  value,
  bowLevelDegrees,
  activeColor = '#0BB127'
}): JSX.Element => {
  const formattedValue = formatNumber(bowLevelDegrees);
  const valueForIndicator = Math.max(-5, Math.min(5, bowLevelDegrees || 0));
  const translateXForIndicator = valueForIndicator * -4.5;
  activeColor = getActiveColorTorqueAndLevel(value, activeColor);

  return (
    <Styled.Chart>
      <svg height='52' width='80' viewBox='0 0 80 52'>
        <rect ry='6' y={15.25} x={16.5} height={21.5} width={47} fill='#707070' />
        <rect y={15.25} x={31.25} height={21.5} width={2} fill='231F20' />
        <rect y={15.25} x={46.75} height={21.5} width={2} fill='231F20' />
        {value !== undefined ? (
          <path
            fill={activeColor}
            transform={`translate(${translateXForIndicator}, 0)`}
            d='M34.96 5.57a.5.5 0 0 0-.43.75l4.54 7.53v16.23a1.5 1.5 0 1 0 3 0V13.83l3.42-7.5a.5.5 0 0 0-.45-.71Z'
          />
        ) : null}
      </svg>
      <Title title={formattedValue ? formattedValue + '°' : '--'} variant='light' type='regular' />
    </Styled.Chart>
  );
};

export default Level;
