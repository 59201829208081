import { useCallback, useState } from 'react';
import { deleteSessionComment, getSessionComments } from 'services/api/sessionsService';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, SessionComment } from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  sessionComments: SessionComment[];
  isSessionLoading: boolean;
  fetchSessionComments: (sessionId: string) => Promise<void>;
  removeSessionComment: (commentId: string) => Promise<void>;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalComments: number;
}

const useSessionComments = (): Result => {
  const [sessionComments, setSessionComments] = useState<SessionComment[]>([]);
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);

  const { openNotification } = useNotifications();

  const fetchSessionComments = useCallback(
    async (sessionId: string): Promise<void> => {
      setIsSessionLoading(true);
      try {
        const response = await getSessionComments(sessionId, currentPage, PAGE_SIZE);
        const { totalPagesCount, totalItemsCount } = response.data.pagination;

        setSessionComments(response.data.items);
        setTotalPages(totalPagesCount);
        setTotalComments(totalItemsCount);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsSessionLoading(false);
      }
    },
    [openNotification, currentPage]
  );

  const removeSessionComment = async (commentId: string): Promise<void> => {
    setIsSessionLoading(true);
    try {
      await deleteSessionComment(commentId);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsSessionLoading(false);
    }
  };

  return {
    sessionComments,
    isSessionLoading,
    fetchSessionComments,
    removeSessionComment,
    totalComments,
    totalPages,
    setCurrentPage,
    currentPage
  }
}

export default useSessionComments;
