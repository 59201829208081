import { ReportCategory, ReportObjectType, ReportStatus } from 'shared/types';
import { ReactNode } from 'react';
import { TypeRow } from './styles';
import { getReportColor } from 'utils/report-utils';
import { REPORT_STATUSES, REPORT_TITLES, REPORT_TYPES } from 'shared/constants/reports';
import { parseISODateFormat } from '../../utils/date-utils';

export const REPORT_COLUMNS = [
  {
    title: 'Type',
    dataIndex: 'reportObjectType',
    key: 'reportObjectType',
    ellipsis: true,
    render: (type: ReportObjectType): ReactNode => {
      return <>{REPORT_TYPES[type]}</>;
    }
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    render: (category: ReportCategory): ReactNode => {
      return <>{REPORT_TITLES[category]}</>;
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    width: 100,
    render: (status: ReportStatus): ReactNode => {
      return <TypeRow color={getReportColor(status)}>{REPORT_STATUSES[status]}</TypeRow>;
    }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 190,
    ellipsis: true,
    render: (date: string): ReactNode => {
      return <>{parseISODateFormat(date, true)}</>;
    }
  }
];
