import {
  FileDoneOutlined,
  IssuesCloseOutlined,
  CodepenOutlined,
  UserOutlined,
  StockOutlined,
  TeamOutlined,
  GlobalOutlined,
  ShoppingOutlined,
  AuditOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import { ElementType } from 'react';
import { EUserRole } from 'shared/types';

type SidebarItem = {
  title: string;
  link: string;
  icon: ElementType;
  roles: EUserRole[];
  subItems?: SidebarItem[];
};

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    title: 'Firmware',
    link: '/firmware',
    icon: CodepenOutlined,
    subItems: [
      {
        title: 'Release',
        link: '/firmware/release',
        icon: FileDoneOutlined,
        roles: [EUserRole.admin_user, EUserRole.master_admin]
      },
      {
        title: 'Testing',
        link: '/firmware/testing',
        icon: IssuesCloseOutlined,
        roles: [EUserRole.admin_user, EUserRole.master_admin]
      }
    ],
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'Users',
    link: '/users',
    icon: UserOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin, EUserRole.moderator]
  },
  {
    title: 'Bow Users',
    link: '/bow-users',
    icon: TeamOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin, EUserRole.moderator]
  },
  {
    title: 'Bows',
    link: '/bows',
    icon: ShoppingOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'User Groups',
    link: '/groups',
    icon: GlobalOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'External Activity log',
    link: '/ex-activity-log',
    icon: StockOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'Reports',
    link: '/reports',
    icon: AuditOutlined,
    roles: [EUserRole.moderator, EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'Statistics',
    link: '/statistics',
    icon: PieChartOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'User Device Statistics',
    link: '/user-device-statistics',
    icon: PieChartOutlined,
    roles: [EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'Public Groups',
    link: '/public-groups',
    icon: ShareAltOutlined,
    roles: [EUserRole.moderator, EUserRole.admin_user, EUserRole.master_admin]
  },
  {
    title: 'Group Events',
    link: '/group-events',
    icon: BarChartOutlined,
    roles: [EUserRole.moderator, EUserRole.admin_user, EUserRole.master_admin]
  }
];
