export const getActiveColorTorqueAndLevel = (
  currentPercentage: number | undefined,
  currentColor: string
): string => {
  return currentPercentage === undefined
    ? currentColor
    : currentPercentage >= 12
    ? '#0BB127'
    : currentPercentage >= 7
    ? '#FFD600'
    : '#F90707';
};

export const getActiveColorSteadiness = (
  currentPercentage: number | undefined,
  currentColor: string
): string => {
  return currentPercentage === undefined
    ? currentColor
    : currentPercentage >= 52
    ? '#0BB127'
    : currentPercentage >= 33
    ? '#FFD600'
    : '#F90707';
};

export const getActiveColorDuration = (
  currentPercentage: number | undefined,
  currentColor: string
): string => {
  return currentPercentage === undefined
    ? currentColor
    : currentPercentage >= 4
    ? '#0BB127'
    : currentPercentage >= 2.5
    ? '#FFD600'
    : '#F90707';
};
