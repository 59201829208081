import React, { useEffect, useMemo } from 'react';
import { PageHeader, Table, Input, Typography } from 'antd';
import Pagination from 'antd/lib/pagination';
import * as Styled from './styles';
import { useSessions, useAppDispatch, useNotifications } from 'shared/hooks';
import { SESSIONS_COLUMNS } from './constants';
import { ConfirmModal, ContentContainer } from 'shared/components';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { SHOTS_COLUMNS } from 'pages/user-shots-page/constants';
import { useHistory, useParams } from 'react-router-dom';
import { SessionShot, TableSession } from './types';

const BowUserSessionsPage = (): JSX.Element => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();

  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const {
    sessions,
    pagination: { currentPage, pageSize, totalItemsCount },
    searchValue,
    isSessionsLoading,
    fetchSessions,
    removeSession,
    setSearchValue,
    onChangePagination
  } = useSessions();

  useEffect((): void => {
    fetchSessions({ userId: Number(userId) });
  }, [fetchSessions, userId]);

  const tableSessions: TableSession[] = useMemo(() => {
    return sessions.map(
      (session, index): TableSession => ({
        id: session.id,
        key: (currentPage - 1) * pageSize + (index + 1),
        createdAt: format(new Date(session.createdAt), 'yyyy-MM-dd HH:mm:ss'),
        name: session.sessionName,
        comments: session.comments.length,
        likes: session.likes.length,
        stats: {
          score: session.stats?.score,
          shots: session.stats?.shots,
          shotDuration: session.stats?.shotDuration,
          shotLevel: session.stats?.shotLevel,
          shotSteadiness: session.stats?.shotSteadiness,
          shotTorque: session.stats?.shotTorque
        },
        username: session.user.username,
        email: session.user.email,
        publishedAt: session.publishedAt,
        shots: (session.shots ?? []).map(
          (shot): SessionShot => ({
            name: shot.name,
            shotScoreTotal: shot.shotScoreTotal,
            shotScoreAimSteadiness: shot.shotScoreAimSteadiness,
            shotScoreBowAngle: shot.shotScoreBowAngle,
            shotScoreAimTime: shot.shotScoreAimTime,
            shotScoreBowTorque: shot.shotScoreBowTorque,
            valid: shot.isShotDetected ? 'True' : 'False',
            timestamp: format(new Date(shot.timestamp), 'yyyy-MM-dd HH:mm:ss')
          })
        )
      })
    );
  }, [sessions, currentPage, pageSize]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRemoveSession = (session: TableSession): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this session?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeSession(session.id);
            await fetchSessions({ userId: Number(userId) });
            openNotification(ESnackbarStyle.SUCCESS, 'Session successfully deleted');
          }}
        />
      )
    );
  };

  const goBack = (): void => {
    history.goBack();
  };

  return (
    <ContentContainer containerStyle={{ maxWidth: '1350px' }}>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='User Sessions' />
        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            columns={[
              ...SESSIONS_COLUMNS,
              {
                title: 'Actions',
                width: 100,
                render: (record): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): void => handleRemoveSession(record)}>
                      Remove
                    </Typography.Link>
                  );
                }
              }
            ]}
            dataSource={tableSessions}
            pagination={false}
            loading={isSessionsLoading}
            size={'middle'}
            expandable={{
              expandedRowRender: (record): JSX.Element => (
                <Table
                  columns={SHOTS_COLUMNS}
                  dataSource={record.shots}
                  pagination={false}
                  loading={isSessionsLoading}
                />
              )
            }}
          />
        </Styled.TableContainer>
        <PaginationContainer>
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            total={totalItemsCount}
            onChange={onChangePagination}
          />
        </PaginationContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default BowUserSessionsPage;
