import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { store } from 'services/store';
import { logoutUser } from 'services/store/reducers/authReducer';

class ApiService {
  private instance: AxiosInstance;

  constructor(v: string = 'v1') {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${v}`
    });

    this.instance.interceptors.request.use(
      (config): AxiosRequestConfig => {
        if (!config.headers.Authorization) {
          const token = localStorage.getItem('token');
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error): Promise<Error> => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response): AxiosResponse => {
        return response;
      },
      async (error): Promise<void | AxiosResponse<any> | Error> => {
        if (error.response) {
          if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            store.dispatch(logoutUser());
          }
          return Promise.reject(error.response.data);
        } else if (error.request) {
          return Promise.reject(new Error('Network error'));
        }
        return Promise.reject(error);
      }
    );
  }

  public get<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get(path, config);
  }

  public post<T, R>(path: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.instance.post(path, data, config);
  }

  public put<T, R>(path: string, data: T): Promise<Promise<AxiosResponse<R>>> {
    return this.instance.put(path, data);
  }

  public patch<T, R>(path: string, data: T): Promise<AxiosResponse<R>> {
    return this.instance.patch(path, data);
  }

  public delete<T>(path: string): Promise<AxiosResponse<T>> {
    return this.instance.delete(path);
  }
}

const apiService = new ApiService()
export default apiService;
export const ApiServiceV2 = new ApiService('v2')
