import {
  Report, Pagination, ReportStatus, ReportObjectType, Session, PostedShot,
} from 'shared/types';
import { AxiosResponse } from 'axios';
import { getQueryParams } from 'utils/query-utils';
import ApiService, { ApiServiceV2 } from './api';

type GetReportsParams = {
  page: number;
  limit: number;
  search: string;
  status: string;
};

export type ReportsResponse = {
  items: Report[];
  pagination: Pagination;
};

export type UpdatedReport = {
  status: ReportStatus;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `reports`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getReports = async (
  query: Partial<GetReportsParams>
): Promise<AxiosResponse<ReportsResponse>> => {
  const params = getQueryParams(query);
  return await ApiService.get<ReportsResponse>(`${getURL()}?${params}`);
};

export const getReportById = async (reportId: string): Promise<AxiosResponse<Report>> => {
  return await ApiService.get<Report>(getURL(reportId));
};

export const deleteReport = async (reportId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL(reportId));

export const updateReport = async (
  reportId: string,
  data: UpdatedReport
): Promise<AxiosResponse<Report>> => {
  return await ApiService.patch(getURL(reportId), data);
};

export const getReportedObject = async (
  parentId: string,
  reportType: ReportObjectType
): Promise<AxiosResponse<Session | PostedShot>> => {
  return await ApiServiceV2.get<Session | PostedShot>(
    `${['picture_session', 'session'].includes(reportType) ? 'sessions' : 'posted-shots'}/${parentId}`
  );
};
