import { getQueryParams } from 'utils/query-utils';
import ApiService from './api';
import { Log, LogType, Pagination } from 'shared/types';
import { AxiosResponse } from 'axios';

type GetLogsParams = {
  page: number;
  limit: number;
  userId: string | number;
  search: string;
  type?: LogType;
  qwpnr?: string;
};

export type LogsResponse = {
  items: Log[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `logs`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getLogs = async (
  query: Partial<GetLogsParams>,
  logsType: string
): Promise<AxiosResponse<LogsResponse>> => {
  const params = getQueryParams(query);
  return await ApiService.get<LogsResponse>(`${logsType}${getURL()}?${params}`);
};

export const getLogById = async (logId: string, logType: string): Promise<AxiosResponse<Log>> => {
  return await ApiService.get<Log>(`${logType}${getURL(logId)}?qwpnr=nSdjySDdtb`);
};

export const deleteLogs = async (): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL());

export const deleteUserLogs = async (userId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL(userId));
