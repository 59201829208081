import React, { CSSProperties } from 'react';
import { line, curveBasis } from 'd3';
import { AimGraphItem } from 'shared/types';
import * as Styled from './styles';

type Props = {
  preShotAimGraphData: AimGraphItem[];
  postShotAimGraphData: AimGraphItem[];
  fov: number;
  radius?: number;
  containerStyles?: CSSProperties;
};

const FloatGraphComponent: React.FC<Props> = ({
  preShotAimGraphData,
  postShotAimGraphData,
  fov,
  containerStyles,
  radius = 152
}): JSX.Element => {
  const resultRadius = radius + 2;
  const diameter = resultRadius * 2;
  const preShotAimGraphDataPoints = preShotAimGraphData.map(
    ({ x, y }): AimGraphItem => ({
      x: (x / fov) * radius + radius,
      y: (y / fov) * -radius + radius
    })
  );
  const postShotAimGraphDataPoints = postShotAimGraphData.map(
    ({ x, y }): AimGraphItem => ({
      x: (x / fov) * radius + radius,
      y: (y / fov) * -radius + radius
    })
  );

  const preShotAimGraphDataCurvedLine =
    line<AimGraphItem>()
      .x((d): number => d.x)
      .y((d): number => d.y)
      .curve(curveBasis)(preShotAimGraphDataPoints) || '';

  const postShotAimGraphDataCurvedLine =
    line<AimGraphItem>()
      .x((d): number => d.x)
      .y((d): number => d.y)
      .curve(curveBasis)(postShotAimGraphDataPoints) || '';

  const circles = (fov > 10 ? Math.round(fov / 10) : fov) - 1;
  const circlesStep = (radius * 0.9) / (circles + 1);

  return (
    <Styled.FloatGraph style={containerStyles}>
      <svg width={diameter} height={diameter} viewBox={`0 0 ${diameter} ${diameter}`}>
        <defs>
          <clipPath id='circle-mask'>
            <circle cx={resultRadius} cy={resultRadius} r={radius} fill='transparent' />
          </clipPath>
        </defs>
        <circle
          cx={resultRadius}
          cy={resultRadius}
          r={radius}
          fill='transparent'
          stroke='#E60019'
          strokeWidth={1}
        />
        {new Array(circles).fill(0).map(
          (v, index): JSX.Element => (
            <circle
              key={index}
              cx={resultRadius}
              cy={resultRadius}
              r={radius - (index + 1) * circlesStep}
              stroke='#FFF'
              strokeWidth={1}
              fill='transparent'
            />
          )
        )}
        <path
          transform={`translate(${resultRadius - 13}, ${resultRadius - 13})`}
          d='M 16.061,13.104 25.595,3.5700001 A 2.091,2.091 0 1 0 22.638,0.61300009 L 13.104,10.147 3.5700001,0.61300009 A 2.091,2.091 0 0 0 0.61300009,3.5700001 L 10.147,13.104 0.61300009,22.638 A 2.091,2.091 0 1 0 3.5700001,25.595 L 13.104,16.061 l 9.534,9.534 a 2.091,2.091 0 1 0 2.957,-2.957 z'
          fill='#FFF'
        />
        <path
          clipPath={'url(#circle-mask)'}
          d={postShotAimGraphDataCurvedLine}
          strokeWidth={3}
          stroke='#FFF'
          fill='transparent'
        />
        <path
          clipPath={'url(#circle-mask)'}
          d={preShotAimGraphDataCurvedLine}
          strokeWidth={3}
          stroke='#FFD600'
          fill='transparent'
        />
      </svg>
    </Styled.FloatGraph>
  );
};

export default FloatGraphComponent;
