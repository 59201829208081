import React from 'react';
import { Modal, Input, Button, Upload, Radio, RadioChangeEvent, DatePicker } from 'antd';
import { useAppDispatch, useAppSelector, useNotifications } from 'shared/hooks';
import {
  closeModal,
  setIsDataLoadingParameter,
  showModal
} from 'services/store/reducers/modalReducer';
import {
  EFirmwareStatus,
  EFirmwareUpdateChannel,
  ESnackbarStyle,
  FirmwareTableRow
} from 'shared/types';
import { useFormik } from 'formik';
import { DeleteOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { validation } from 'services/validation';
import * as Styled from './styles';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Space from 'antd/lib/space';
import moment from 'moment';
import {
  addFirmwareVersion,
  deleteFirmwareVersion,
  downloadFirmware,
  updateFirmwareVersion
} from 'services/api/firmwareService';
import { ConfirmModal } from 'shared/components';
import { getFieldError } from 'utils/error-utils';

enum EApplyStatus {
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  DRAFT = 'draft'
}

type FormValues = {
  file: RcFile | null;
  version: string;
  changelog: string;
  applyStatus: EApplyStatus;
  scheduledAt: string;
};

type Props = {
  record?: FirmwareTableRow;
  firmwareUpdateChannel: EFirmwareUpdateChannel;
  fetchFirmwareData: () => Promise<void>;
};

const FirmwareActionsModal: React.FC<Props> = ({
  record,
  firmwareUpdateChannel,
  fetchFirmwareData
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const isModalOpened = useAppSelector((state): boolean => state.modal.isModalOpened);
  const { openNotification } = useNotifications();

  const initialValues: FormValues = {
    file: null,
    version: record?.version || '',
    changelog: record?.changelog || '',
    applyStatus: EApplyStatus.ACTIVE,
    scheduledAt: moment(record?.scheduledAt ? record.scheduledAt : new Date()).toISOString()
  };

  const checkIsCorrectDate = (): boolean => {
    if (formik.values.applyStatus === EApplyStatus.SCHEDULED) {
      const isDateCorrect =
        moment(formik.values.scheduledAt).toISOString() > moment(new Date()).toISOString();
      if (!isDateCorrect) {
        setFieldValue('scheduledAt', moment(new Date()).toISOString());
        openNotification(ESnackbarStyle.ERROR, 'Please, choose the correct date');
      }
      return isDateCorrect;
    }
    return true;
  };

  const getDataForUpdate = (values: FormValues): { [key: string]: string } => {
    const { applyStatus, scheduledAt, changelog } = values;
    switch (applyStatus) {
      case EApplyStatus.SCHEDULED: {
        return { changelog, scheduledAt, status: EFirmwareStatus.scheduled };
      }
      case EApplyStatus.ACTIVE: {
        return { changelog, status: EFirmwareStatus.active };
      }
      default:
        return {};
    }
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      const { applyStatus, scheduledAt, version, changelog, file } = values;
      if (!checkIsCorrectDate()) return;
      try {
        if (!!record) {
          if (
            record?.status === EFirmwareStatus.active ||
            record?.status === EFirmwareStatus.archived
          ) {
            await updateFirmwareVersion(record.version, firmwareUpdateChannel, { changelog });
          } else {
            const updateData = getDataForUpdate(values);
            await updateFirmwareVersion(record.version, firmwareUpdateChannel, updateData);
          }
        } else {
          const formData = new FormData();
          formData.append('file', file!);
          formData.append('version', version);
          formData.append('changelog', changelog);
          if (applyStatus === EApplyStatus.SCHEDULED) {
            formData.append('scheduledAt', scheduledAt);
          }
          await addFirmwareVersion(formData, firmwareUpdateChannel);
          if (applyStatus === EApplyStatus.ACTIVE) {
            await updateFirmwareVersion(version, firmwareUpdateChannel, {
              status: EFirmwareStatus.active
            });
          }
        }
        await fetchFirmwareData();
        openNotification(
          ESnackbarStyle.SUCCESS,
          `Firmware was successfully ${!!record ? 'updated' : 'uploaded'}`
        );
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        handleCancel();
      }
    },
    initialValues,
    validationSchema: validation.UPLOAD_FIRMWARE
  });

  const { values, setFieldValue, isSubmitting } = formik;

  const handleFileChange = (file: UploadChangeParam<UploadFile<File>>): void => {
    const regexp = /\.(zip)$/;
    if (file?.fileList[0]) {
      if (regexp.test(file.file.name)) {
        setFieldValue('file', file.fileList[0].originFileObj);
      } else {
        openNotification(ESnackbarStyle.ERROR, 'File must be in .zip format only');
      }
    } else {
      setFieldValue('file', null);
    }
  };

  const handleSubmit = (): void => {
    formik.handleSubmit();
  };

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const handleRadioButtonChange = (event: RadioChangeEvent): void => {
    const { value } = event.target;
    setFieldValue('applyStatus', value);
  };

  const handleDateChange = (date: moment.Moment | null, dateString: string): void => {
    setFieldValue('scheduledAt', moment(dateString).toISOString());
  };

  const handleDownloadButtonClick = async (): Promise<void> => {
    if (!!record) {
      try {
        const response = await downloadFirmware(record.fileId);
        const fileName =
          response.headers['content-disposition'].split('filename=')[1].slice(1, -1) ||
          'Firmware.zip';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    }
  };

  const removeFirmware = async (versionId: string): Promise<void> => {
    dispatch(setIsDataLoadingParameter(true));
    try {
      await deleteFirmwareVersion(versionId, firmwareUpdateChannel);
      await fetchFirmwareData();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      dispatch(setIsDataLoadingParameter(false));
      dispatch(closeModal());
    }
  };

  const showRemoveFirmwareModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this firmware?'
          confirmAction={(): Promise<void> => removeFirmware(record!.version)}
        />
      )
    );
  };

  return (
    <Modal
      visible={isModalOpened}
      title='Firmware'
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: isSubmitting || (!record && !values.file),
        loading: isSubmitting
      }}
      cancelButtonProps={{ disabled: isSubmitting }}
    >
      <Styled.UploadForm onSubmit={formik.handleSubmit}>
        {!record && (
          <Styled.UploadArea>
            <Upload
              name='file'
              fileList={values.file ? [values.file] : []}
              onChange={handleFileChange}
              beforeUpload={(): boolean => false}
            >
              <Button icon={<UploadOutlined />}>Choose file</Button>
            </Upload>
            <Styled.FormatText>Only .zip format</Styled.FormatText>
          </Styled.UploadArea>
        )}
        <>
          {!record && (
            <Styled.UploadFormFieldContainer>
              <Input
                placeholder='Firmware version'
                size='large'
                value={values.version}
                name='version'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isSubmitting}
              />
              {getFieldError(formik, 'version')}
            </Styled.UploadFormFieldContainer>
          )}
          <Styled.UploadFormFieldContainer className={'textarea'}>
            <Input.TextArea
              placeholder='Log description'
              size='large'
              autoSize
              value={values.changelog}
              name='changelog'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
            />
          </Styled.UploadFormFieldContainer>
          {!(
            record?.status === EFirmwareStatus.active || record?.status === EFirmwareStatus.archived
          ) && (
            <Styled.RadioGroupContainer>
              <Radio.Group onChange={handleRadioButtonChange} value={values.applyStatus}>
                <Space direction='vertical'>
                  <Radio value={EApplyStatus.ACTIVE}>Activate now</Radio>
                  <Styled.ScheduledButtonContainer>
                    <Radio value={EApplyStatus.SCHEDULED}>Scheduled on</Radio>
                    <Styled.DatePickerContainer>
                      <DatePicker
                        value={moment(values.scheduledAt)}
                        onChange={handleDateChange}
                        size='small'
                        disabledDate={(current): boolean => moment().add(-1, 'second') >= current}
                        disabled={values.applyStatus !== EApplyStatus.SCHEDULED}
                        placeholder='Scheduled date'
                        showTime
                        inputReadOnly
                        allowClear={false}
                        showNow={false}
                      />
                    </Styled.DatePickerContainer>
                  </Styled.ScheduledButtonContainer>
                  {!record && <Radio value={EApplyStatus.DRAFT}>Make it as a draft</Radio>}
                </Space>
              </Radio.Group>
            </Styled.RadioGroupContainer>
          )}
          {!!record && (
            <>
              <Styled.RemoveButton>
                <Button
                  icon={<DeleteOutlined />}
                  type='default'
                  onClick={showRemoveFirmwareModal}
                  disabled={isSubmitting}
                >
                  Remove firmware
                </Button>
              </Styled.RemoveButton>
              <Styled.DownloadButton>
                <Button
                  icon={<DownloadOutlined />}
                  type='primary'
                  onClick={handleDownloadButtonClick}
                  disabled={isSubmitting}
                >
                  Download firmware
                </Button>
              </Styled.DownloadButton>
            </>
          )}
        </>
      </Styled.UploadForm>
    </Modal>
  );
};

export default FirmwareActionsModal;
