import { ReportCategory, ReportObjectType, ReportStatus } from '../types';

export const REPORT_TITLES: {[key in ReportCategory]: string} = {
  harassment_or_bullying: 'HARASSMENT OR BULLYING',
  commercial_content_or_spam: 'UNWANTED COMMERCIAL CONTENT OR SPAM',
  hate_speech: 'HATE SPEECH',
  offensive_language: 'OFFENSIVE LANGUAGE',
  personal_information: 'SHARING PERSONAL INFORMATION',
  harmful_or_dangerous: 'HARMFUL OR DANGEROUS ACTS',
  offensive_imagery: 'OFFENSIVE IMAGERY',
  copyright_violation: 'COPYRIGHT VIOLATION',
  pornography: 'PORNOGRAPHY OR SEXUALLY EXPLICIT MATERIAL',
  violent_or_repulsive: 'VIOLENT OR REPULSIVE CONTENT',
  other: 'OTHER'
};

export const REPORT_TYPES: {[key in ReportObjectType]: string} = {
  picture_session: 'Picture',
  picture_shot: 'Picture',
  session: 'Comment (Session)',
  shot: 'Comment (Shot)',
};

export const REPORT_STATUSES: {[key in ReportStatus]: string} = {
  pending: 'PENDING',
  valid: 'APPROVED',
  invalid: 'DECLINED',
};
