import React from 'react';
import './App.css';
import { MainRoutes } from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'services/store';
import { SocketProvider } from 'shared/providers';

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <SocketProvider>
          <MainRoutes />
        </SocketProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
