import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchInput = styled.div`
  width: 300px;
`;

export const NewUserButton = styled.div`
  width: 270px;
`;

export const TableContainer = styled.div`
  margin-top: 24px;

  & tbody tr {
    cursor: pointer;
  }

  & tbody tr td,
  th {
    width: 25%;
    display: inline-block;

    @media ${DEVICE.tablet} {
      width: 33%;
    }
  }

  & tbody tr td:last-child {
    width: 50%;

    @media ${DEVICE.tablet} {
      width: 33%;
    }
  }

  & thead tr th:last-child {
    width: 50%;

    @media ${DEVICE.tablet} {
      width: 33%;
    }
  }
`;
