import { useCallback, useState } from 'react';
import { getFirmwareByStatus, getFirmwareVersions } from 'services/api/firmwareService';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { useDebounce, useNotifications } from 'shared/hooks';
import {
  EFirmwareStatus,
  EFirmwareUpdateChannel,
  ESnackbarStyle,
  FirmwareVersion
} from 'shared/types';

type Result = {
  firmwareVersions: FirmwareVersion[];
  fetchFirmwareByStatus: (status: EFirmwareStatus) => Promise<void>;
  activeFirmware: FirmwareVersion | null;
  scheduledFirmware: FirmwareVersion | null;
  isFirmwareVersionsLoading: boolean;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalFirmwares: number;
  fetchFirmwareVersions: () => Promise<void>;
  setSortParams: (values: string[]) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
};

const useFirmwareVersions = (channelId: EFirmwareUpdateChannel): Result => {
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareVersion[]>([]);
  const [activeFirmware, setActiveFirmware] = useState<FirmwareVersion | null>(null);
  const [scheduledFirmware, setScheduledFirmware] = useState<FirmwareVersion | null>(null);
  const [isFirmwareVersionsLoading, setIsFirmwareVersionsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFirmwares, setTotalFirmwares] = useState<number>(0);
  const [sortParams, setSortParams] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const { openNotification } = useNotifications();

  const fetchFirmwareVersions = useCallback(async (): Promise<void> => {
    setIsFirmwareVersionsLoading(true);
    try {
      const response = await getFirmwareVersions(
        currentPage,
        PAGE_SIZE,
        channelId,
        sortParams,
        debouncedSearchValue
      );
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setFirmwareVersions(response.data.items);
      setTotalPages(totalPagesCount);
      setTotalFirmwares(totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsFirmwareVersionsLoading(false);
    }
  }, [currentPage, channelId, sortParams, debouncedSearchValue, openNotification]);

  const fetchFirmwareByStatus = useCallback(
    async (status: EFirmwareStatus): Promise<void> => {
      try {
        const response = await getFirmwareByStatus(channelId, status);
        const version = !!response.data.semver ? response.data : null;
        status === EFirmwareStatus.active
          ? setActiveFirmware(version)
          : setScheduledFirmware(version);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    [channelId, openNotification]
  );

  return {
    firmwareVersions,
    fetchFirmwareByStatus,
    activeFirmware,
    scheduledFirmware,
    isFirmwareVersionsLoading,
    fetchFirmwareVersions,
    totalPages,
    currentPage,
    setCurrentPage,
    totalFirmwares,
    setSortParams,
    searchValue,
    setSearchValue
  };
};

export default useFirmwareVersions;
