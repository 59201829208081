import React, { useEffect, useMemo } from 'react';
import { useLogs } from 'shared/hooks';
import { Log } from 'shared/types';
import { useHistory } from 'react-router';
import { Input, PageHeader, Pagination, Table, Checkbox } from 'antd';
import { ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { LOGS_PAGE_SIZE } from 'shared/constants/pagination';
import { PaginationContainer } from 'shared/styles';
import { ACTIVITY_LOG_COLUMNS } from './constants';

type TableLog = Log & { key: number };

type RowAction = {
  onClick: () => void;
};

const ExActivityLogPage = (): JSX.Element => {
  const {
    items,
    pagination,
    isLogsLoading,
    currentPage,
    inputSearch,
    setInputSearch,
    setCurrentPage,
    fetchLogs,
    handleChangeSearch,
    isAutoUpdate,
    setIsAutoUpdate
  } = useLogs({logsType: 'ex-'});
  const history = useHistory();

  useEffect((): void => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect((): any => {
   if (isAutoUpdate) {
     const interval = setInterval((): void => {
       fetchLogs(false);
     }, 1000);

     return (): void => clearInterval(interval);
   }
  }, [isAutoUpdate, fetchLogs]);

  const tableLogs = useMemo(
    (): TableLog[] => items.map((log): TableLog => ({ ...log, key: log.id })),
    [items]
  );

  const handleRowClick = (log: TableLog): void => {
    history.push(`/ex-activity-log/${log.id}`);
  };

  return (
    <ContentContainer containerStyle={{ maxWidth: 'unset', maxHeight: '100vh' }}>
      <Styled.PageContainer>
        <PageHeader title='External Activity Log' />
        <Input
          value={inputSearch}
          onChange={(e): void => {
            setInputSearch(e.target.value);
            handleChangeSearch(e.target.value);
          }}
          size='large'
          placeholder='User info'
        />
        <Styled.CheckboxContainer>
          <Checkbox checked={isAutoUpdate} onChange={(e): void => setIsAutoUpdate(e.target.checked)}>
            Auto Update
          </Checkbox>
        </Styled.CheckboxContainer>
        <Styled.TableContainer>
          <Table
            onRow={(log: TableLog): RowAction => ({
              onClick: (): void => handleRowClick(log)
            })}
            columns={ACTIVITY_LOG_COLUMNS}
            dataSource={tableLogs}
            loading={isLogsLoading}
            pagination={false}
            size={'middle'}
          />
        </Styled.TableContainer>
        {pagination.totalItemsCount > LOGS_PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={pagination.totalItemsCount}
              onChange={setCurrentPage}
              pageSize={LOGS_PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default ExActivityLogPage;
