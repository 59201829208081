import React from 'react';
import { ContentContainer, FirmwareActionsModal } from 'shared/components';
import { Button, PageHeader, Table, Pagination, Input } from 'antd';
import * as Styled from './styles';
import { useAppDispatch, useNotifications } from 'shared/hooks';
import { showModal } from 'services/store/reducers/modalReducer';
import {
  EFirmwareUpdateChannel,
  ESnackbarStyle,
  FirmwareTableRow,
  FirmwareVersion
} from 'shared/types';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import { PaginationContainer } from 'shared/styles';
import { addFirmwareVersion } from 'services/api/firmwareService';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { RcFile } from 'antd/lib/upload';
import { handleSortAction } from 'utils/sorting-utils';
import { FIRMWARE_COLUMNS } from './constants';
import moment from 'moment';

type FormValues = {
  file: RcFile | null;
  version: string;
  changelog: string;
};

type RowAction = {
  onClick: () => void;
};

type Props = {
  firmwareUpdateChannel: EFirmwareUpdateChannel;
  fetchFirmwareData: () => Promise<void>;
  data: FirmwareTableRow[];
  activeFirmware: FirmwareVersion | null;
  scheduledFirmware: FirmwareVersion | null;
  isDataLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalFirmwares: number;
  setSortParams: (value: string[]) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
};

const FirmwareList: React.FC<Props> = ({
  firmwareUpdateChannel,
  fetchFirmwareData,
  data,
  activeFirmware,
  scheduledFirmware,
  isDataLoading,
  setCurrentPage,
  totalFirmwares,
  currentPage,
  setSortParams,
  searchValue,
  setSearchValue
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const showFirmwareActionsModal = (record?: FirmwareTableRow): void => {
    dispatch(
      showModal(
        <FirmwareActionsModal
          record={record}
          firmwareUpdateChannel={firmwareUpdateChannel}
          fetchFirmwareData={fetchFirmwareData}
        />
      )
    );
  };

  const initialValues: FormValues = {
    file: null,
    version: '',
    changelog: ''
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      formik.setSubmitting(true);
      try {
        const formData = new FormData();
        formData.append('file', values.file!);
        formData.append('version', values.version);
        formData.append('changelog', values.changelog);
        await addFirmwareVersion(formData, firmwareUpdateChannel);
        await fetchFirmwareData();
        formik.setValues(initialValues);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
    initialValues,
    validationSchema: validation.UPLOAD_FIRMWARE
  });

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Firmware' />
        <Button
          htmlType='button'
          type='primary'
          size='middle'
          disabled={isDataLoading}
          style={{ width: 150 }}
          onClick={(): void => showFirmwareActionsModal()}
        >
          Upload Firmware
        </Button>
        <Styled.VersionsGroup>
          {!!activeFirmware && <div>{`Current version: ${activeFirmware.semver}`}</div>}
          {!!scheduledFirmware && (
            <Styled.ScheduledVersion>
              <span>{`Scheduled version: ${scheduledFirmware.semver}`}</span>
              <span>{moment(scheduledFirmware.scheduledAt).format('YYYY-MM-DD hh:mm:ss')}</span>
            </Styled.ScheduledVersion>
          )}
        </Styled.VersionsGroup>
        <Styled.SearchInput>
          <Input
            placeholder='Search'
            onChange={handleSearchInputChange}
            value={searchValue}
            allowClear
          />
        </Styled.SearchInput>
        <Styled.TableContainer>
          <Table
            columns={FIRMWARE_COLUMNS}
            dataSource={data}
            pagination={false}
            loading={isDataLoading}
            size={'middle'}
            onChange={handleSortAction(setSortParams)}
            onRow={(record): RowAction => ({
              onClick: (): void => showFirmwareActionsModal(record)
            })}
          />
        </Styled.TableContainer>
        {totalFirmwares > PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalFirmwares}
              onChange={setCurrentPage}
              pageSize={PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default FirmwareList;
