import { format } from 'date-fns';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Event, EventStatus } from 'shared/types';

const statusTitleMap: Record<EventStatus, string> = {
  created: 'Upcoming',
  finished: 'Past',
  active: 'Ongoing'
};

const getStatusTitle = (status: EventStatus): string => statusTitleMap[status] || status;

export const COLUMNS: ColumnsType<Event> = [
  {
    title: 'Event Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',
    ellipsis: true,
    render: (timestamp: number) => <>{format(new Date(timestamp), 'yyyy-MM-dd hh:mm:ss a')}</>,
    sorter: (a, b) => a.startDate - b.startDate
  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
    ellipsis: true,
    render: (timestamp: number) => <>{format(new Date(timestamp), 'yyyy-MM-dd hh:mm:ss a')}</>,
    sorter: (a, b) => a.endDate - b.endDate
  },
  {
    title: 'Shots Per Day',
    dataIndex: 'shotsPerDay',
    key: 'shotsPerDay',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: renderStatusTag,
    filters: [
      { text: getStatusTitle('created'), value: 'created' },
      { text: getStatusTitle('finished'), value: 'finished' },
      { text: getStatusTitle('active'), value: 'active' }
    ] as { text: string; value: EventStatus }[],
    onFilter: (value, record) => record.status === value
  }
];

export function renderStatusTag(status: EventStatus): JSX.Element {
  switch (status) {
    case 'created':
      return <Tag color='blue'>{getStatusTitle('created')}</Tag>;
    case 'finished':
      return <Tag color='volcano'>{getStatusTitle('finished')}</Tag>;
    case 'active':
      return <Tag color='green'>{getStatusTitle('active')}</Tag>;
    default:
      return <span>{status || '-'}</span>;
  }
}
