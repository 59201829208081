import React, { useEffect } from 'react';
import { FirmwareList } from 'shared/components';
import { useFirmwareVersions } from 'shared/hooks';
import { useSocketContext } from 'shared/providers';
import { EFirmwareStatus, EFirmwareUpdateChannel, FirmwareTableRow } from 'shared/types';
import { parseISODateFormat } from 'utils/date-utils';

const FirmwareTesting = (): JSX.Element => {
  const {
    firmwareVersions,
    fetchFirmwareByStatus,
    activeFirmware,
    scheduledFirmware,
    fetchFirmwareVersions,
    isFirmwareVersionsLoading,
    totalFirmwares,
    currentPage,
    setCurrentPage,
    setSortParams,
    searchValue,
    setSearchValue
  } = useFirmwareVersions(EFirmwareUpdateChannel.testing);
  const { socket } = useSocketContext();

  useEffect((): void => {
    fetchFirmwareVersions();
  }, [fetchFirmwareVersions]);

  useEffect((): void => {
    fetchFirmwareByStatus(EFirmwareStatus.active);
    fetchFirmwareByStatus(EFirmwareStatus.scheduled);
  }, [fetchFirmwareByStatus]);

  useEffect((): void => {
    if (socket) {
      socket.on('firmware:update', (): void => {
        fetchFirmwareVersions();
        fetchFirmwareByStatus(EFirmwareStatus.active);
        fetchFirmwareByStatus(EFirmwareStatus.scheduled);
      });
    }
  }, [socket, fetchFirmwareVersions, fetchFirmwareByStatus]);

  const fetchFirmwareData = async (): Promise<void> => {
    await fetchFirmwareVersions();
    await fetchFirmwareByStatus(EFirmwareStatus.active);
    await fetchFirmwareByStatus(EFirmwareStatus.scheduled);
  };

  const TABLE_DATA = firmwareVersions.map(
    (item): FirmwareTableRow => ({
      key: item.id,
      version: item.semver,
      createdAt: parseISODateFormat(item.createdAt),
      status: item.status === EFirmwareStatus.uploaded ? 'draft' : item.status,
      activatedDate: parseISODateFormat(item.createdAt),
      scheduledAt: item.scheduledAt,
      changelog: item.changelog,
      author: item.uploadedBy,
      fileId: item.fileId
    })
  );

  return (
    <FirmwareList
      firmwareUpdateChannel={EFirmwareUpdateChannel.testing}
      fetchFirmwareData={fetchFirmwareData}
      activeFirmware={activeFirmware}
      scheduledFirmware={scheduledFirmware}
      data={TABLE_DATA}
      isDataLoading={isFirmwareVersionsLoading}
      totalFirmwares={totalFirmwares}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setSortParams={setSortParams}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};

export default FirmwareTesting;
