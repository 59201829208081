import React from 'react';
import { Modal, Input, Checkbox, Button } from 'antd';
import { useAppDispatch, useAppSelector, useCopyToClipboard, useNotifications } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { useFormik } from 'formik';
import { FormCheckboxContainer, FormInputContainer, FormInputInner } from './styles';
import { EyeInvisibleOutlined, EyeTwoTone, CopyOutlined } from '@ant-design/icons';
import { validation } from 'services/validation';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { changeUserPassword } from 'services/api/usersService';
import { getFieldError } from 'utils/error-utils';

type FormValues = {
  updatedPassword: string;
  confirmPassword: string;
  sendEmail: boolean;
};

type Props = {
  userId: string;
};

const ChangePasswordModal: React.FC<Props> = ({ userId }): JSX.Element => {
  const dispatch = useAppDispatch();
  const isModalOpened = useAppSelector((state): boolean => state.modal.isModalOpened);
  const { openNotification } = useNotifications();
  const { copyToClipboard } = useCopyToClipboard();

  const initialValues: FormValues = {
    updatedPassword: '',
    confirmPassword: '',
    sendEmail: false
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      try {
        const { confirmPassword, updatedPassword, sendEmail } = values;
        await changeUserPassword(userId, { confirmPassword, updatedPassword, sendEmail });
        openNotification(ESnackbarStyle.SUCCESS, `Password was successfully updated`);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        handleCancel();
      }
    },
    initialValues,
    validationSchema: validation.CHANGE_PASSWORD
  });

  const handleSubmit = (): void => {
    formik.handleSubmit();
  };

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const handleCheckboxChange =
    (fieldName: string): ((event: CheckboxChangeEvent) => void) =>
    (event: CheckboxChangeEvent): void => {
      formik.setFieldValue(fieldName, event.target.checked);
    };

  const handleGeneratePasswordClick = (): void => {
    const password = Math.random().toString(36).slice(-8);
    formik.setValues({ ...formik.values, updatedPassword: password, confirmPassword: password });
  };

  const handleCopyButtonClick = (): void => {
    copyToClipboard(formik.values.confirmPassword);
  };

  return (
    <Modal
      visible={isModalOpened}
      title='Change password'
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{ disabled: formik.isSubmitting, loading: formik.isSubmitting }}
      cancelButtonProps={{ disabled: formik.isSubmitting }}
    >
      <FormInputContainer>
        <FormInputInner>
          <Input.Password
            placeholder='Password'
            size='large'
            value={formik.values.updatedPassword}
            name='updatedPassword'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible): JSX.Element =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.values.updatedPassword && (
            <Button
              type='primary'
              onClick={handleCopyButtonClick}
              shape='circle'
              icon={<CopyOutlined />}
            />
          )}
        </FormInputInner>
        {getFieldError(formik, 'updatedPassword')}
      </FormInputContainer>
      <FormInputContainer>
        <Input.Password
          placeholder='Confirm password'
          size='large'
          value={formik.values.confirmPassword}
          name='confirmPassword'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          iconRender={(visible): JSX.Element =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        {getFieldError(formik, 'confirmPassword')}
      </FormInputContainer>
      <FormCheckboxContainer>
        <Button type='primary' onClick={handleGeneratePasswordClick}>
          Generate password automatically
        </Button>
      </FormCheckboxContainer>
      <FormCheckboxContainer>
        <Checkbox checked={formik.values.sendEmail} onChange={handleCheckboxChange('sendEmail')}>
          Send credentials by e-mail
        </Checkbox>
      </FormCheckboxContainer>
    </Modal>
  );
};

export default ChangePasswordModal;
