import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { renderStatusTag } from 'pages/bow-users-page/constants';
import { EUserStatus, StatisticsUser } from 'shared/types';

export const defaultModalState = {
  title: '',
  search: '',
  data: [],
  visible: false
};

export const defaultDeviceStatistics = {
  iOS: [],
  Android: [],
  Unknown: []
};

// Define the columns for the parent table (user information)
export const columns: ColumnsType<StatisticsUser> = [
  { title: 'User ID', dataIndex: 'id', key: 'id', width: 100 },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    render: (username) => <span>{username || '-'}</span>
  },
  {
    title: 'Full name',
    key: 'fullname',
    ellipsis: true,
    render: (__, record) => (
      <span>{[record.first_name, record.last_name].filter(Boolean).join(' ') || '-'}</span>
    )
  },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: renderStatusTag,
    filters: [
      { text: 'Active', value: EUserStatus.Active },
      { text: 'Disabled', value: EUserStatus.Disabled },
      { text: 'Pending Confirmation', value: EUserStatus.Created },
      { text: 'Deleted', value: EUserStatus.Deleted }
    ],
    onFilter: (value: string | number | boolean, record: StatisticsUser) => record.status === value
  },
  { title: 'Total Shots', dataIndex: 'total_shots', key: 'total_shots' },
  {
    title: 'Active User',
    dataIndex: 'isActiveUser',
    key: 'isActiveUser',
    render: (isActive: boolean) =>
      isActive ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>,
    filters: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    onFilter: (value: string | number | boolean, record: StatisticsUser) =>
      record.isActiveUser === value
  },
  {
    title: 'Has Shots',
    dataIndex: 'hasShots',
    key: 'hasShots',
    render: (hasShots: boolean) =>
      hasShots ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>,
    filters: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    onFilter: (value: string | number | boolean, record: StatisticsUser) =>
      record.hasShots === value
  }
];

// Define the columns for the nested table (device information)
export const expandedRowColumns = [
  { title: 'App Version', dataIndex: 'appVersion', key: 'appVersion' },
  { title: 'Device Brand', dataIndex: 'deviceBrand', key: 'deviceBrand' },
  { title: 'Device Model', dataIndex: 'deviceModel', key: 'deviceModel' },
  { title: 'Device System Name', dataIndex: 'deviceSystemName', key: 'deviceSystemName' },
  { title: 'Device System Version', dataIndex: 'deviceSystemVersion', key: 'deviceSystemVersion' }
];
