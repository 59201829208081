import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useLogs, useNotifications } from 'shared/hooks';
import { ESnackbarStyle, Log, LogType } from 'shared/types';
import { useHistory, useLocation } from 'react-router';
import { Input, PageHeader, Pagination, Table, Checkbox } from 'antd';
import { ConfirmModal, ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { LOGS_PAGE_SIZE } from 'shared/constants/pagination';
import { PaginationContainer } from 'shared/styles';
import { ACTIVITY_LOG_COLUMNS } from './constants';
import _ from 'lodash';
import { closeModal, showModal } from '../../services/store/reducers/modalReducer';
import { deleteUserLogs } from '../../services/api/logService';

type LocationState = {
  userId: number;
};

type TableLog = Log & { key: number };

type Option = {
  value: string;
  label: string;
};

type RowAction = {
  onClick: () => void;
};

const ActivityLogPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { state: { userId } = {} } = useLocation<LocationState>();
  const { openNotification } = useNotifications();

  const {
    items,
    pagination,
    isLogsLoading,
    currentPage,
    inputSearch,
    setType,
    setInputSearch,
    setCurrentPage,
    fetchLogs,
    handleChangeSearch,
    isAutoUpdate,
    setIsAutoUpdate
  } = useLogs({ userId });

  useEffect((): void => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect((): any => {
    if (isAutoUpdate) {
      const interval = setInterval((): void => {
        fetchLogs(false);
      }, 1000);

      return (): void => clearInterval(interval);
    }
  }, [isAutoUpdate, fetchLogs]);

  const tableLogs = useMemo(
    (): TableLog[] => items.map((log): TableLog => ({ ...log, key: log.id })),
    [items]
  );

  const goBack = (): void => {
    history.goBack();
  };

  const handleRowClick = (log: TableLog): void => {
    history.push(`/activity-log/${log.id}`);
  };

  const showRemoveUserLogsModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove user logs?'
          confirmAction={async (): Promise<void> => {
            try {
              dispatch(closeModal());
              await deleteUserLogs(`${userId}`);
              history.goBack();
              openNotification(ESnackbarStyle.SUCCESS, 'User logs successfully deleted');
            } catch (e) {
              openNotification(ESnackbarStyle.ERROR, e.message);
            }
          }}
        />
      )
    );
  };

  return (
    <ContentContainer containerStyle={{ maxWidth: 'unset', maxHeight: '100vh' }}>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Activity Log' />
        <Styled.LogActions>
          <Styled.LogSelect
            defaultValue=''
            onChange={(value): void => setType((value as LogType) || undefined)}
            options={['', 'warning', 'error', 'debug', 'info'].map(
              (value): Option => ({
                value,
                label: value ? _.capitalize(value) : 'All'
              })
            )}
          />
          <Styled.LogAction type='primary' onClick={showRemoveUserLogsModal} color='#FF4F4F'>
            Remove User Logs
          </Styled.LogAction>
        </Styled.LogActions>
        <Input
          value={inputSearch}
          onChange={(e): void => {
            setInputSearch(e.target.value);
            handleChangeSearch(e.target.value);
          }}
          size='large'
          placeholder='User info'
        />
        <Styled.CheckboxContainer>
          <Checkbox
            checked={isAutoUpdate}
            onChange={(e): void => setIsAutoUpdate(e.target.checked)}
          >
            Auto Update
          </Checkbox>
        </Styled.CheckboxContainer>
        <Styled.TableContainer>
          <Table
            onRow={(log: TableLog): RowAction => ({
              onClick: (): void => handleRowClick(log)
            })}
            columns={ACTIVITY_LOG_COLUMNS}
            dataSource={tableLogs}
            loading={isLogsLoading}
            pagination={false}
            size={'middle'}
          />
        </Styled.TableContainer>
        {pagination.totalItemsCount > LOGS_PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={pagination.totalItemsCount}
              onChange={setCurrentPage}
              pageSize={LOGS_PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default ActivityLogPage;
