// Libs
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

// Components
import { Input, PageHeader, Table } from 'antd';
import { ContentContainer } from 'shared/components';

// Services
import { getGroup } from 'services/api/groupsService';
import { getGroupEvents } from 'services/api/eventsService';

// Hooks
import { useHistory, useParams } from 'react-router-dom';

// Constants
import { COLUMNS } from './constants';

// Types
import { Event, UserGroup } from 'shared/types';

// Styles
import * as Styled from './styles';

type QueryParams = {
  groupId: string;
};

const GroupEventsPage = (): JSX.Element => {
  const [group, setGroup] = useState<UserGroup | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const history = useHistory();
  const { groupId } = useParams<QueryParams>();

  const filteredEvents = useMemo(() => {
    return events.filter((record: Event) =>
      Object.values(_.pick(record, ['id', 'name'])).some(
        (value) =>
          !_.isUndefined(value) && String(value).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [events, searchValue]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  const initialize = async () => {
    try {
      setLoading(true);
      await fetchGroup(groupId);
      await fetchGroupEvents(groupId);
    } finally {
      setLoading(false);
    }
  };

  const fetchGroup = async (groupId: string): Promise<void> => {
    try {
      const response = await getGroup(groupId);
      setGroup(response.data);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('[fetchGroup] ', err);
    }
  };

  const fetchGroupEvents = async (groupId: string): Promise<void> => {
    try {
      const response = await getGroupEvents(groupId);
      setEvents(response.data);
    } catch (err: any) {
      // tslint:disable-next-line:no-console
      console.error('[fetchGroupEvents] ', err);
    }
  };

  const handleRowClick = (record: Event): void => {
    history.push(`/group-events/${groupId}/event/${record.id}`);
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={() => history.push('/group-events')} title={`Group - ${group?.name ?? ''} events`} />

        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input.Search
              placeholder='Search by event'
              onSearch={(value) => setSearchValue(value)}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>

        <Styled.TableContainer>
          <Table
            columns={COLUMNS}
            dataSource={filteredEvents}
            pagination={false}
            loading={loading}
            size={'middle'}
            rowKey='id'
            onRow={(record) => ({
              onClick: () => handleRowClick(record)
            })}
          />
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default GroupEventsPage;
