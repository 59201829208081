import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle } from 'shared/types';

type Result = {
  copyToClipboard: (tet: string) => Promise<void>;
};

const useCopyToClipboard = (): Result => {
  const { openNotification } = useNotifications();

  const processCopyAction = (text: string): Promise<void> => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((resolve, reject): void => {
        document.execCommand('copy') ? resolve() : reject();
        textArea.remove();
      });
    }
  };

  const copyToClipboard = async (text: string): Promise<void> => {
    try {
      await processCopyAction(text);
      openNotification(ESnackbarStyle.SUCCESS, 'Successfully copied');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  };

  return {
    copyToClipboard
  };
};

export default useCopyToClipboard;
