import styled from 'styled-components';

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 75px;
`

export const FloatGraph = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
`
