import { useCallback, useState } from 'react';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, Shot, RequestParams } from 'shared/types';
import { getDeviceShots } from 'services/api/devicesService';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  shots: Shot[];
  fetchDeviceShots: () => Promise<void>;
  requestShotsParams: RequestParams;
  setRequestShotsParams: (requestParams: RequestParams) => void;
  totalPages: number;
  totalShots: number;
  isShotsLoading: boolean;
  setShotsSortParams: (values: string[]) => void;
};

const useShotsData = (bowSerialNumber: string): Result => {
  const [shots, setShots] = useState<Shot[]>([]);
  const [requestShotsParams, setRequestShotsParams] = useState<RequestParams>({
    dateRange: { start: null, end: null },
    currentPage: 1
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalShots, setTotalShots] = useState<number>(0);
  const [isShotsLoading, setIsShotsLoading] = useState<boolean>(true);
  const [shotSortParams, setShotsSortParams] = useState<string[]>([]);
  const { openNotification } = useNotifications();

  const fetchDeviceShots = useCallback(async (): Promise<void> => {
    setIsShotsLoading(true);
    try {
      const { start, end } = requestShotsParams.dateRange;
      const { currentPage } = requestShotsParams;
      const response = await getDeviceShots(
        bowSerialNumber,
        currentPage,
        PAGE_SIZE,
        start,
        end,
        shotSortParams
      );
      setShots(response.data.items);
      setTotalPages(response.data.pagination.totalPagesCount);
      setTotalShots(response.data.pagination.totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsShotsLoading(false);
    }
  }, [openNotification, bowSerialNumber, requestShotsParams, shotSortParams]);

  return {
    shots,
    fetchDeviceShots,
    requestShotsParams,
    setRequestShotsParams,
    totalPages,
    totalShots,
    isShotsLoading,
    setShotsSortParams
  };
};

export default useShotsData;
