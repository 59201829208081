import React, { useEffect } from 'react';
import { ChangePasswordModal, ConfirmModal, ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { Button, Input, PageHeader, Select, Upload } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useFormik } from 'formik';
import { ROLES } from 'shared/constants/roles';
import { validation } from 'services/validation';
import { useAppDispatch, useNotifications, useUser } from 'shared/hooks';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { activateUserAccount, updateBowUser } from 'services/api/usersService';
import { ESnackbarStyle, EUserRole } from 'shared/types';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { getFieldError } from 'utils/error-utils';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

type FormValues = {
  firstName: string;
  lastName: string;
  role: EUserRole | undefined;
  email: string;
  image: RcFile | null;
};

type QueryParams = {
  userId: string;
};

const EditUserPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userId } = useParams<QueryParams>();
  const { openNotification } = useNotifications();
  const { user, setUser, isUserLoading, fetchUser, removeUser } = useUser();

  useEffect((): void => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  const initialValues: FormValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    role: user?.role || undefined,
    email: user?.email || '',
    image: null
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      try {
        const formData = new FormData();

        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        formData.append('role', values.role!);
        if (values.image) {
          formData.append('image', values.image!);
        }

        await updateBowUser(userId, formData);
        history.push('/bow-users');
        openNotification(ESnackbarStyle.SUCCESS, 'User successfully updated');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    initialValues,
    validationSchema: validation.EDIT_USER,
    enableReinitialize: true
  });

  const { values, setFieldValue } = formik;

  const handleImageChange = (file: UploadChangeParam<UploadFile<File>>): void => {
    if (file?.fileList[0]) {
      setFieldValue('image', file.fileList[0].originFileObj);
    } else {
      setFieldValue('image', null);
    }
  };

  const handleChangeUserStatus = async (status: 'active' | 'disabled'): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('status', status);
      const res = await updateBowUser(userId, formData);
      setUser(res.data);
      openNotification(ESnackbarStyle.SUCCESS, 'User status has been changed successfully');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  };

  const handleActivateUserAccount = async (userId: string): Promise<void> => {
    try {
      const res = await activateUserAccount(userId);
      setUser(res.data);
      openNotification(ESnackbarStyle.SUCCESS, 'User account has been activated successfully');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  }

  const goBack = (): void => {
    // Get the current location
    const currentLocation = history.location;

    // Check if there's a previous location in the state
    const prevLocation = (currentLocation.state as Record<string, string>)?.from;

    if (prevLocation) {
      // Navigate to the previous location with useLastSearchState
      history.replace({
        pathname: prevLocation,
        state: {
          useLastSearchState: true
        }
      });
    } else {
      // If no previous location, fallback to history.goBack()
      history.goBack();
    }
  };

  const showRemoveUserModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this user?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeUser(userId);
            history.push('/users');
            openNotification(ESnackbarStyle.SUCCESS, 'User successfully deleted');
          }}
        />
      )
    );
  };

  const handleSelectFieldChange =
    (fieldName: string): ((value: string) => void) =>
    (value: string): void => {
      formik.setFieldValue(fieldName, value);
    };

  const showChangePasswordModal = (): void => {
    dispatch(showModal(<ChangePasswordModal userId={userId} />));
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Edit user' />
        <Styled.Form onSubmit={formik.handleSubmit}>
          {user?.profilePictureS3Url && (
            <Styled.Img>
              <img src={user?.profilePictureS3Url} alt={''} />
            </Styled.Img>
          )}
          <Styled.FormInputContainer>
            <Upload
              name='image'
              fileList={values.image ? [values.image] : []}
              onChange={handleImageChange}
              beforeUpload={(): boolean => false}
            >
              <Button icon={<UploadOutlined />}>Choose file</Button>
            </Upload>
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='First name'
              size='large'
              value={formik.values.firstName}
              name='firstName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'firstName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Last name'
              size='large'
              value={formik.values.lastName}
              name='lastName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'lastName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input placeholder='Username' size='large' value={user?.username || ''} disabled />
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Select
              size='large'
              placeholder='Role'
              value={formik.values.role}
              style={{ width: '100%' }}
              onChange={handleSelectFieldChange('role')}
              allowClear
              disabled={isUserLoading || formik.isSubmitting}
            >
              {ROLES.map(
                (item): JSX.Element => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              )}
            </Select>
            {getFieldError(formik, 'role')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Email'
              size='large'
              value={formik.values.email}
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUserLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'email')}
          </Styled.FormInputContainer>
          <Styled.FormActions>
            <Button
              type='primary'
              htmlType='button'
              onClick={showChangePasswordModal}
              disabled={isUserLoading || formik.isSubmitting}
            >
              Change password
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              disabled={isUserLoading || formik.isSubmitting || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Apply
            </Button>
          </Styled.FormActions>
          <Styled.FormActions>
            <Button
              type='primary'
              htmlType='button'
              onClick={(): Promise<void> =>
                handleChangeUserStatus(user?.status === 'active' ? 'disabled' : 'active')
              }
              disabled={isUserLoading || formik.isSubmitting || !user}
            >
              {user?.status === 'active' ? 'Lock Account' : 'Unlock Account'}
            </Button>
            {user?.status === 'new' && (
              <Button
                type='primary'
                htmlType='button'
                onClick={(): Promise<void> => handleActivateUserAccount(userId)}
                disabled={isUserLoading || formik.isSubmitting || !user}
              >
                Activate Account
              </Button>
            )}
          </Styled.FormActions>
          <Styled.FormActions>
            <Styled.RemoveButton>
              <Button
                icon={<DeleteOutlined />}
                type='default'
                onClick={showRemoveUserModal}
                disabled={isUserLoading || formik.isSubmitting}
              >
                Remove user
              </Button>
            </Styled.RemoveButton>
          </Styled.FormActions>
        </Styled.Form>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default EditUserPage;
