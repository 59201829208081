import React, { useEffect, useState } from 'react';
import Pagination from 'antd/lib/pagination';
import { PageHeader, Table, Button } from 'antd';
import * as Styled from './styles';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { ContentContainer, SupportMessageModal } from 'shared/components';
import { PaginationContainer } from 'shared/styles';
import { format } from 'date-fns';
import { useSupportMessages, useAppDispatch } from 'shared/hooks';
import { useParams } from 'react-router';
import { showModal } from 'services/store/reducers/modalReducer';
import { MESSAGES_COLUMNS } from './constants';

type TableMessage = {
  id: number;
  userId: number;
  message: string;
  createdAt: string;
  authorUsername: string;
  authorEmail: string;
}

type QueryParams = {
  userId: string;
};

const SupportMessagesPage = (): JSX.Element => {
  const [tableMessages, setTableMessages] = useState<TableMessage[]>([]);
  const { userId } = useParams<QueryParams>();
  const dispatch = useAppDispatch();

  const {
    messages,
    fetchMessages,
    isMessagesLoading,
    currentPage,
    setCurrentPage,
    totalMessages
  } = useSupportMessages(userId);

  useEffect((): void => {
    fetchMessages(true);
  }, [fetchMessages]);

  useEffect((): any => {
    const interval = setInterval((): void => {
      fetchMessages(false);
    }, 2500);

    return (): void => clearInterval(interval);
  }, [fetchMessages]);

  useEffect((): void => {
    const modifiedMessages = messages.map((message): TableMessage => {
      return {
        id: message.id,
        message: message.content,
        createdAt: format(new Date(message.createdAt), 'yyyy-MM-dd HH:mm:ss'),
        userId: message.userId,
        authorEmail: message.author.email,
        authorUsername: message.author.username || (message.author.role === 'master_admin' ? 'Master Admin' : '')
      };
    });
    setTableMessages(modifiedMessages);
  }, [messages, currentPage]);

  const showSupportMessageModal = (): void => {
    dispatch(
      showModal(
        <SupportMessageModal userId={userId} fetchMessages={fetchMessages}/>
      )
    );
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title={`Support messages`} />
        <Button
          htmlType='button'
          type='primary'
          size='middle'
          disabled={isMessagesLoading}
          style={{ width: 150 }}
          onClick={(): void => showSupportMessageModal()}
        >
          New message
        </Button>
        <Styled.TableContainer>
          <Table
            columns={MESSAGES_COLUMNS}
            dataSource={tableMessages}
            pagination={false}
            loading={isMessagesLoading}
            size={'middle'}
          />
        </Styled.TableContainer>
        {totalMessages > PAGE_SIZE && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalMessages}
              onChange={setCurrentPage}
              pageSize={PAGE_SIZE}
            />
          </PaginationContainer>
        )}
      </Styled.PageContainer>
    </ContentContainer>
  )
}

export default SupportMessagesPage;
