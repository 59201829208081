export const GROUPS_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'groupId',
    key: 'groupId',
    ellipsis: true,
    width: 50,
  },
  {
    title: 'Group name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'Owner Username',
    dataIndex: 'ownerName',
    key: 'ownerName',
    ellipsis: true,
  },
  {
    title: 'Owner Email',
    dataIndex: 'ownerEmail',
    key: 'ownerEmail',
    ellipsis: true,
  },
  {
    title: 'Members',
    dataIndex: 'membersCount',
    key: 'membersCount',
    ellipsis: true,
    width: 90
  }
];

export const MEMBERS_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 50,
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
];
