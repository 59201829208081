import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EDiagramSections } from 'shared/types';
import * as Styled from './styles';

type DrawParams = {
  context: CanvasRenderingContext2D;
  index: number;
  currentAngle: number;
  endAngle: number;
  x: number;
  y: number;
};

type Props = {
  shotAttempts: { [key: string]: number };
};

const CircleDiagram: React.FC<Props> = ({ shotAttempts }): JSX.Element => {
  const COLORS = useMemo((): string[] => ['#00796a', '#659f33', '#ffa600', '#144a5c'], []);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const RADIUS: number = 80;
  const OFFSET_END: number = 5;
  const OFFSET_STEP: number = 0.5;
  const ANGLE_STEP: number = 0.05 * Math.PI;
  const CANVAS_WIDTH: number = 200;
  const CANVAS_HEIGHT: number = 200;
  const CENTER_X: number = CANVAS_WIDTH / 2;
  const CENTER_Y: number = CANVAS_HEIGHT / 2;
  const isDiagramRendered = useRef<boolean>(false);
  const hoverSectionIndex = useRef<number | null>(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(null);

  useEffect((): void => {
    if (!!canvasRef.current) {
      setContext(canvasRef.current.getContext('2d'));
    }
  }, [canvasRef]);

  const attempts: [string, number][] = Object.entries(shotAttempts);
  const total: number = Object.values(shotAttempts).reduce(
    (sum: number, item): number => sum + item,
    0
  );

  const angles: number[] = attempts.map((item): number => {
    const onePercentOfShots = total * 0.01;
    const percent = item[1] / onePercentOfShots;
    return percent * 0.02 * Math.PI;
  });

  const bordersAngles: number[] = angles.reduce(
    (acc: number[], angle, index): number[] => [...acc, !!index ? acc[index - 1] + angle : angle],
    []
  );

  const clearCanvas = useCallback(
    (): void => context?.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT),
    [context]
  );

  const currentOffset = useRef<number>(OFFSET_STEP);

  const getCoordinates = useCallback(
    (
      activeIndex: number,
      currentAngle: number,
      endAngle: number
    ): { x: number; y: number; labelX: number; labelY: number } => {
      const offsetValue = currentOffset.current;
      const isSingleValue = currentAngle === 0 && endAngle.toFixed(3) === (2 * Math.PI).toFixed(3);
      const isHoverSection = activeIndex === hoverSectionIndex.current && !isSingleValue;
      const medianAngle = (endAngle + currentAngle) / 2;
      const offsetX = Math.cos(medianAngle) * offsetValue;
      const offsetY = Math.sin(medianAngle) * offsetValue;
      const sectionCenter = isHoverSection ? RADIUS / 1.5 + offsetValue : RADIUS / 1.5;
      const x = isHoverSection ? CENTER_X + offsetX : CENTER_X;
      const y = isHoverSection ? CENTER_Y + offsetY : CENTER_Y;
      const labelX = isSingleValue ? CENTER_X : CENTER_X + sectionCenter * Math.cos(medianAngle);
      const labelY = isSingleValue ? CENTER_Y : CENTER_Y + sectionCenter * Math.sin(medianAngle);
      if (hoverSectionIndex.current !== null) {
        currentOffset.current =
          offsetValue < OFFSET_END ? (currentOffset.current += OFFSET_STEP) : offsetValue;
      }
      return { x, y, labelX, labelY };
    },
    [currentOffset, CENTER_X, CENTER_Y]
  );

  const drawDiagram = useCallback(
    ({ context, index, currentAngle, endAngle, x, y }: DrawParams): void => {
      context.beginPath();
      context.fillStyle = COLORS[index];
      context.strokeStyle = COLORS[index];
      context.moveTo(x, y);
      context.arc(x, y, RADIUS, currentAngle, endAngle);
      context.lineTo(x, y);
      context.stroke();
      context.fill();
    },
    [COLORS]
  );

  const renderDiagram = useCallback((): void => {
    if (!!context) {
      clearCanvas();
      angles.forEach((angle, index): void => {
        if (!angle) return;
        const currentAngle = bordersAngles[index - 1] || 0;
        const endAngle = bordersAngles[index];
        const { labelX, labelY, x, y } = getCoordinates(index, currentAngle, endAngle);
        drawDiagram({ context, index, currentAngle, endAngle, x, y });
        context.fillStyle = '#FFF';
        context.fillText(`${attempts[index][1]}`, labelX, labelY);
        context.font = 'bold 12px Arial';
      });
    }
    if (currentOffset.current < OFFSET_END && activeSectionIndex !== null) {
      requestAnimationFrame(renderDiagram);
    }
  }, [
    context,
    activeSectionIndex,
    clearCanvas,
    angles,
    bordersAngles,
    getCoordinates,
    drawDiagram,
    attempts
  ]);

  const renderStartDiagram = useCallback(
    (context: CanvasRenderingContext2D): void => {
      let index = 0;
      let endAngle = 0;
      let currentAngle = 0;
      const draw = (): void => {
        const nextAngle = endAngle + ANGLE_STEP;
        currentAngle = endAngle;
        endAngle = nextAngle < bordersAngles[index] ? nextAngle : bordersAngles[index];
        const { x, y } = getCoordinates(index, currentAngle, endAngle);
        currentAngle !== endAngle
          ? drawDiagram({ context, index, currentAngle, endAngle, x, y })
          : (index += 1);
        if (index <= angles.length - 1) {
          requestAnimationFrame(draw);
        } else {
          isDiagramRendered.current = true;
          renderDiagram();
        }
      };
      draw();
    },
    [ANGLE_STEP, bordersAngles, getCoordinates, drawDiagram, angles.length, renderDiagram]
  );

  useEffect((): void => {
    if (!!context && !isDiagramRendered.current) {
      renderStartDiagram(context);
    }
  }, [context, renderStartDiagram]);

  useEffect((): void => {
    if (activeSectionIndex !== null) {
      renderDiagram();
    }
  }, [activeSectionIndex, renderDiagram]);

  const handleMouseLeave = (): void => {
    hoverSectionIndex.current = null;
    setActiveSectionIndex(null);
    renderDiagram();
  };

  const handleMouseMove = (e: React.MouseEvent): void => {
    if (isDiagramRendered.current) {
      const { offsetY, offsetX } = e.nativeEvent;
      const y = CENTER_Y - offsetY;
      const x = CENTER_X - offsetX;
      const distanceFromCenter = Math.sqrt(x * x + y * y);
      const angle = Math.atan2(y, x) / Math.PI;
      const hoverAngle = (1 + angle) * Math.PI;

      if (distanceFromCenter <= RADIUS) {
        const index = bordersAngles.findIndex((angle): boolean => hoverAngle <= angle);
        if (hoverSectionIndex.current !== index) currentOffset.current = OFFSET_STEP;
        hoverSectionIndex.current = index;
        setActiveSectionIndex(index);
      } else {
        if (hoverSectionIndex.current !== null) handleMouseLeave();
      }
    }
  };

  const handleEventOnMouseMove = (index: number): void => {
    if (isDiagramRendered.current) {
      hoverSectionIndex.current = index;
      setActiveSectionIndex(index);
      renderDiagram();
    }
  };

  const getEventTitle = (eventKey: string): string => {
    switch (eventKey) {
      case EDiagramSections.DRY_FIRE: {
        return 'Dry fire';
      }
      case EDiagramSections.DERAIL: {
        return 'Derail';
      }
      case EDiagramSections.BOW_DROP: {
        return 'Bow drop';
      }
      default: {
        return 'Shots';
      }
    }
  };

  const getEventList = (): JSX.Element[] => {
    return attempts.map(
      (attempt, index): JSX.Element => (
        <Styled.EventContainer
          onMouseMove={(): void => handleEventOnMouseMove(index)}
          onMouseLeave={handleMouseLeave}
          key={attempt[0]}
          isActiveSection={activeSectionIndex === index}
        >
          <Styled.EventColor color={COLORS[index]} />
          <Styled.EventTitle>{getEventTitle(attempt[0])}</Styled.EventTitle>
          <Styled.EventTotal>{attempt[1]}</Styled.EventTotal>
        </Styled.EventContainer>
      )
    );
  };

  return (
    <Styled.ContentContainer>
      <Styled.DiagramContainer>
        <canvas
          ref={canvasRef}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
      </Styled.DiagramContainer>
      <Styled.EventListContainer>{getEventList()}</Styled.EventListContainer>
    </Styled.ContentContainer>
  );
};

export default CircleDiagram;
