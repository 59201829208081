export const SHOTS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: 120,
  },
  {
    title: 'Total Score',
    dataIndex: 'shotScoreTotal',
    key: 'shotScoreTotal',
    ellipsis: true,
    width: 100,
  },
  {
    title: 'Aim Steadiness',
    dataIndex: 'shotScoreAimSteadiness',
    key: 'shotScoreAimSteadiness',
    ellipsis: true,
    width: 140,
  },
  {
    title: 'Bow Angle',
    dataIndex: 'shotScoreBowAngle',
    key: 'shotScoreBowAngle',
    ellipsis: true,
    width: 100,
  },
  {
    title: 'Aim Time',
    dataIndex: 'shotScoreAimTime',
    key: 'shotScoreAimTime',
    ellipsis: true,
    width: 100,
  },
  {
    title: 'Bow Torque',
    dataIndex: 'shotScoreBowTorque',
    key: 'shotScoreBowTorque',
    ellipsis: true,
    width: 100,
  },
  {
    title: 'Valid',
    dataIndex: 'valid',
    key: 'valid',
    ellipsis: true,
    width: 60,
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    ellipsis: true,
    width: 155,
  }
];

export const ADVANCED_SHOTS_COLUMN = [
  ...SHOTS_COLUMNS,
  {
    title: 'Brand',
    dataIndex: ['userMetadata', 'deviceBrand'],
    key: 'userMetadata.deviceBrand',
    ellipsis: true,
  },
  {
    title: 'Model',
    dataIndex: ['userMetadata', 'deviceModel'],
    key: 'userMetadata.deviceModel',
    ellipsis: true,
  },
  {
    title: 'System Name',
    dataIndex: ['userMetadata', 'deviceSystemName'],
    key: 'userMetadata.deviceSystemName',
    ellipsis: true,
  },
  {
    title: 'System Version',
    dataIndex: ['userMetadata', 'deviceSystemVersion'],
    key: 'userMetadata.deviceSystemVersion',
    ellipsis: true,
  },
  {
    title: 'App Version',
    dataIndex: ['userMetadata', 'appVersion'],
    key: 'userMetadata.appVersion',
    ellipsis: true,
  },
]
