import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader, Table, Typography } from 'antd';
import * as Styled from './styles';
import { ContentContainer } from 'shared/components';
import { ESnackbarStyle, User } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { AxiosResponse } from 'axios';
import ApiService from 'services/api/api';
import GroupPlaceholder from '../../assets/images/bow-ava.png';
import { useHistory } from 'react-router-dom';

export type CommunityGroup = {
  id: number;
  name: string;
  ownerId: number;
  groupPictureId: string | null;
  groupPictureS3Url: string | null;
  owner?: User;
  members?: User[];
};

export type GroupRequest = {
  accepted: boolean;
  id: number;
  userId: number;
  groupId: number;
  group: CommunityGroup;
};

type TableGroupRequests = GroupRequest & { key: number };

const getURL = (...rest: string[]): string => {
  const mainUrl = `groups`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getGroupRequests = async (): Promise<AxiosResponse<GroupRequest[]>> => {
  return await ApiService.get<GroupRequest[]>(`${getURL('all/requests')}`);
};

export const acceptRequests = async (
  groupRequestId: string
): Promise<AxiosResponse<CommunityGroup>> => {
  return await ApiService.post(getURL('acceptGroup'), { groupRequestId });
};

const GroupRequestsPage = (): JSX.Element => {
  const [groupRequests, setGroupRequests] = useState<GroupRequest[]>([]);
  const [isGroupRequestsLoading, setIsGroupRequestsLoading] = useState<boolean>(true);

  const history = useHistory();
  const { openNotification } = useNotifications();

  const tableGroupRequests = useMemo(
    (): TableGroupRequests[] =>
      groupRequests.map((request): TableGroupRequests => ({ ...request, key: request.id })),
    [groupRequests]
  );

  const fetchGroupRequests = useCallback(async (): Promise<void> => {
    setIsGroupRequestsLoading(true);
    try {
      const response = await getGroupRequests();
      setGroupRequests(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsGroupRequestsLoading(false);
    }
  }, [openNotification]);

  const acceptGroupRequest = useCallback(
    async (groupRequestId: number): Promise<void> => {
      setIsGroupRequestsLoading(true);
      try {
        await acceptRequests(`${groupRequestId}`);
        await fetchGroupRequests();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        setIsGroupRequestsLoading(false);
      }
    },
    [openNotification, fetchGroupRequests]
  );

  useEffect((): void => {
    fetchGroupRequests();
  }, [fetchGroupRequests]);

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Group Requests' />
        <Styled.TableContainer>
          <Table
            columns={[
              {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
                ellipsis: true,
                render: (group: CommunityGroup): JSX.Element => {
                  return (
                    <Styled.Group onClick={(): void => history.push(`groups/${group.id}`)}>
                      <Styled.GroupImageContainer>
                        <Styled.GroupImage src={group.groupPictureS3Url || GroupPlaceholder} />
                      </Styled.GroupImageContainer>
                      <Styled.GroupName>{group.name}</Styled.GroupName>
                    </Styled.Group>
                  );
                }
              },
              {
                title: 'Status',
                dataIndex: 'accepted',
                key: 'accepted',
                ellipsis: true,
                render: (accepted: boolean): JSX.Element => {
                  return <>{accepted ? 'ACCEPTED' : 'PENDING'}</>;
                }
              },
              {
                title: 'User Info',
                dataIndex: 'userId',
                key: 'userId',
                ellipsis: true,
                render: (userId: string): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): void => history.push(`bow-users/${userId}`)}>
                      User
                    </Typography.Link>
                  );
                }
              },
              {
                title: 'Actions',
                width: 150,
                render: (record: TableGroupRequests): JSX.Element => {
                  return (
                    <Typography.Link onClick={(): Promise<void> => acceptGroupRequest(record.id)}>
                      Accept Request
                    </Typography.Link>
                  );
                }
              }
            ]}
            dataSource={tableGroupRequests}
            pagination={false}
            loading={isGroupRequestsLoading}
            size={'middle'}
          />
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default GroupRequestsPage;
