import { AxiosResponse } from 'axios';
import { Bow, Pagination } from '../../shared/types';
import ApiService from './api';
import { getQueryParams } from '../../utils/query-utils';

type BowsResponse = {
  items: Bow[];
  pagination: Pagination;
};

const getURL = (...rest: string[]): string => {
  const mainUrl = `bow-models`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getBows = async (
  page: number,
  limit: number,
): Promise<AxiosResponse<BowsResponse>> => {
  const params = getQueryParams({ page, limit });
  return await ApiService.get(`/bow-models?${params}`);
};

export const getBow = async (bowId: string): Promise<AxiosResponse<Bow>> =>
  await ApiService.get<Bow>(getURL(bowId));

export const addBow = async (newBow: FormData): Promise<AxiosResponse<Bow>> =>
  await ApiService.post<FormData, Bow>(`/bow-models`, newBow);

export const updateBow = async (bowId: string, bow: FormData): Promise<AxiosResponse<Bow>> =>
  await ApiService.patch<FormData, Bow>(getURL(bowId), bow);

export const deleteBow = async (bowId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(getURL(bowId));
