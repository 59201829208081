import React, { useEffect } from 'react';
import { ConfirmModal, ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { Button, Input, PageHeader, Tag, Upload } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import { useAppDispatch, useAppSelector, useBow, useNotifications } from 'shared/hooks';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle, EUserRole, User } from 'shared/types';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { getFieldError } from 'utils/error-utils';
import { checkRolePermission } from 'utils/role-utils';
import { Redirect } from 'react-router-dom';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import _ from 'lodash';

type FormValues = {
  image: RcFile | null;
  modelName: string;
  inputDrawWeight: string;
  drawWeight: number[];
  drawLengthFrom: string;
  drawLengthTo: string;
  inputLetOff: string;
  letOff: number[];
  'lr/hr': string[];
};

type QueryParams = {
  bowId: string;
};

const getFieldName = (field: string, arr: number[] | string[]): string => {
  return `${field}${arr.length === 1 ? '[0]' : ''}`;
};

const EditBowPage = (): JSX.Element => {
  const user = useAppSelector((state): User | null => state.auth.user);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { bowId } = useParams<QueryParams>();
  const { openNotification } = useNotifications();
  const { bow, isBowLoading, fetchBow, removeBow, editBow } = useBow();

  useEffect((): void => {
    fetchBow(bowId);
  }, [fetchBow, bowId]);

  const initialValues: FormValues = {
    image: null,
    modelName: bow?.modelName || '',
    inputDrawWeight: '',
    drawWeight: bow?.drawWeight || [],
    drawLengthFrom: bow ? `${Math.min(...bow.drawLength)}` : '',
    drawLengthTo: bow ? `${Math.max(...bow.drawLength)}` : '',
    inputLetOff: '',
    letOff: bow?.letOff || [],
    'lr/hr': bow ? bow['lr/hr'] : []
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      const maxLength = Math.max(
        _.toNumber(values.drawLengthFrom),
        _.toNumber(values.drawLengthTo)
      );
      const minLength = Math.min(
        _.toNumber(values.drawLengthFrom),
        _.toNumber(values.drawLengthTo)
      );
      const formData = new FormData();
      if (values.image) {
        formData.append('image', values.image!);
      }
      formData.append('modelName', values.modelName);
      _.range(minLength, maxLength + 0.5, 0.5).forEach((item): void =>
        formData.append('drawLength', `${item}`)
      );
      values.drawWeight.forEach((item): void =>
        formData.append(getFieldName('drawWeight', values.drawWeight), `${item}`)
      );
      values.letOff.forEach((item): void =>
        formData.append(getFieldName('letOff', values.letOff), `${item}`)
      );
      values['lr/hr'].forEach((item): void =>
        formData.append(getFieldName('lr/hr', values['lr/hr']), `${item}`)
      );
      try {
        await editBow(bowId, formData);
        openNotification(ESnackbarStyle.SUCCESS, 'Bow successfully updated');
        history.push('/bows');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    initialValues,
    validationSchema: validation.EDIT_BOW,
    enableReinitialize: true
  });

  const handleImageChange = (file: UploadChangeParam<UploadFile<File>>): void => {
    if (file?.fileList[0]) {
      formik.setFieldValue('image', file.fileList[0].originFileObj);
    } else {
      formik.setFieldValue('image', null);
    }
  };

  const addBowValue = (
    value: 'inputDrawWeight' | 'inputLetOff',
    field: 'drawWeight' | 'letOff'
  ): void => {
    const property = formik.values[value];
    const curr = formik.values[field];
    const isExists = curr.some((val: string | number): boolean => val === property);
    if (!isExists) {
      formik.setFieldValue(
        field,
        [...curr, _.toNumber(property)].sort((a, b): number => a - b)
      );
    }
    formik.setFieldValue(value, initialValues[value]);
  };

  const removeBowValue = (field: 'drawWeight' | 'letOff', value: string | number): void => {
    formik.setFieldValue(field, _.difference(formik.values[field], [value]));
  };

  const goBack = (): void => {
    history.goBack();
  };

  const showRemoveBowModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this bow?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeBow(bowId);
            history.push('/bows');
            openNotification(ESnackbarStyle.SUCCESS, 'Bow successfully deleted');
          }}
        />
      )
    );
  };

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.master_admin, EUserRole.admin_user, EUserRole.support_user],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Edit bow' />
        <Styled.Form onSubmit={formik.handleSubmit}>
          {bow?.bowPictureS3Url && (
            <Styled.FormImg>
              <img src={bow.bowPictureS3Url} alt={''} />
            </Styled.FormImg>
          )}
          <Styled.FormInputContainer>
            <Upload
              accept='.jpeg,.png'
              name='image'
              fileList={formik.values.image ? [formik.values.image] : []}
              onChange={handleImageChange}
              beforeUpload={(): boolean => false}
            >
              <Button icon={<UploadOutlined />}>Choose image</Button>
            </Upload>
            {getFieldError(formik, 'image')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Model'
              size='large'
              value={formik.values.modelName}
              name='modelName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFieldError(formik, 'modelName')}
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Styled.FormRow>
              <Styled.FormRange>
                <Styled.FormInput
                  placeholder='Draw Length'
                  size='large'
                  value={formik.values.drawLengthFrom}
                  name='drawLengthFrom'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='number'
                />
                {getFieldError(formik, 'drawLengthFrom')}
              </Styled.FormRange>
              <Styled.FormRange>
                <Styled.FormInput
                  placeholder='Draw Length'
                  size='large'
                  value={formik.values.drawLengthTo}
                  name='drawLengthTo'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='number'
                />
                {getFieldError(formik, 'drawLengthTo')}
              </Styled.FormRange>
            </Styled.FormRow>
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Styled.FormSelect
              mode='multiple'
              placeholder='Please select'
              value={formik.values['lr/hr']}
              onChange={(val): void => {
                formik.setFieldValue('lr/hr', val);
              }}
              options={[
                { value: 'lr', label: 'LR' },
                { value: 'hr', label: 'HR' }
              ]}
            />
            {getFieldError(formik, 'lr/hr')}
          </Styled.FormInputContainer>
          <Styled.FormValuesContainer>
            <Styled.FormRow>
              <Styled.FormInput
                placeholder='Draw Weight'
                size='large'
                value={formik.values.inputDrawWeight}
                name='inputDrawWeight'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type='number'
              />
              <Styled.FormAddButton
                type='primary'
                onClick={(): void => addBowValue('inputDrawWeight', 'drawWeight')}
                disabled={!formik.values.inputDrawWeight}
              >
                Add
              </Styled.FormAddButton>
            </Styled.FormRow>
            <Styled.FormTags>
              {formik.values.drawWeight.map(
                (value): JSX.Element => (
                  <Tag
                    key={value}
                    closable
                    onClose={(): void => removeBowValue('drawWeight', value)}
                  >
                    {value}
                  </Tag>
                )
              )}
            </Styled.FormTags>
            {getFieldError(formik, 'drawWeight')}
          </Styled.FormValuesContainer>
          <Styled.FormValuesContainer>
            <Styled.FormRow>
              <Styled.FormInput
                placeholder='Let-Off'
                size='large'
                value={formik.values.inputLetOff}
                name='inputLetOff'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type='number'
              />
              <Styled.FormAddButton
                type='primary'
                onClick={(): void => addBowValue('inputLetOff', 'letOff')}
                disabled={!formik.values.inputLetOff}
              >
                Add
              </Styled.FormAddButton>
            </Styled.FormRow>
            <Styled.FormTags>
              {formik.values.letOff.map(
                (value): JSX.Element => (
                  <Tag key={value} closable onClose={(): void => removeBowValue('letOff', value)}>
                    {value}
                  </Tag>
                )
              )}
            </Styled.FormTags>
            {getFieldError(formik, 'letOff')}
          </Styled.FormValuesContainer>
          <Styled.FormActions>
            <Button
              type='primary'
              htmlType='submit'
              disabled={isBowLoading || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Apply
            </Button>
            <Styled.RemoveButton>
              <Button
                icon={<DeleteOutlined />}
                type='default'
                onClick={showRemoveBowModal}
                disabled={isBowLoading}
              >
                Remove bow
              </Button>
            </Styled.RemoveButton>
          </Styled.FormActions>
        </Styled.Form>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default EditBowPage;
