export const COMMENTS_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    ellipsis: true,
    width: 50,
  },
  {
    title: 'Content',
    dataIndex: 'content',
    ellipsis: false,
  },
  {
    title: 'Author username',
    dataIndex: ['user', 'username'],
    ellipsis: true,
    width: 155,
  },
  {
    title: 'Author email',
    dataIndex: ['user', 'email'],
    ellipsis: true,
    width: 230,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    ellipsis: true,
    width: 165,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    ellipsis: true,
    width: 165,
  },
];
