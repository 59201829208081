import ApiService from './api';
import { AxiosResponse } from 'axios';
import { Statistics, UserDeviceStatistics } from 'shared/types';

const getURL = (...rest: string[]): string => {
  const mainUrl = `statistics`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

const getGenerateStatisticsURL = (...rest: string[]): string => {
  const mainUrl = `generate-statistics`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getStatistics = async (): Promise<AxiosResponse<{ data: any }>> => {
  return await ApiService.get(getURL());
};

export const getUserStatistics = async (): Promise<AxiosResponse<{ data: Statistics }>> => {
  return await ApiService.get(getURL('user-stats'));
};

export const getUserDeviceStatistics = async (): Promise<
  AxiosResponse<{ data: UserDeviceStatistics }>
> => {
  return await ApiService.get(getURL('user-device-stats'));
};

export const generateStatistics = async (): Promise<AxiosResponse<any>> =>
  await ApiService.get<any>(getGenerateStatisticsURL());
