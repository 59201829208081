import React from 'react';
import TrainingIcon from './TrainingIcon';
import HuntIcon from './HuntIcon';

type IconName = 'training' | 'hunting';

type Props = {
  name: IconName;
  color?: string;
  size?: number;
};

const ICONS: { [key in IconName]: React.FC<Omit<Props, 'name'>> } = {
  training: TrainingIcon,
  hunting: HuntIcon
};

const Icon: React.FC<Props> = (props): JSX.Element => {
  const { name, ...rest } = props;
  const IconComponent = ICONS[name];
  return <IconComponent {...rest} />;
};

export default Icon;
