import { ESnackbarStyle, Pagination, UserGroup } from 'shared/types';
import { getGroups } from 'services/api/groupsService';
import { useCallback, useState } from 'react';
import { useDebounce, useNotifications } from 'shared/hooks';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  groups: UserGroup[];
  isGroupsLoading: boolean;
  searchValue: string;
  pagination: Pagination;
  onChangePagination: (currentPage: number, pageSize?: number) => void;
  fetchGroups: () => Promise<void>;
  setSearchValue: (value: string) => void;
};

const useGroups = (): Result => {
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: PAGE_SIZE,
    totalPagesCount: 0,
    totalItemsCount: 0
  });

  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const { openNotification } = useNotifications();

  const fetchGroups = useCallback(async (): Promise<void> => {
    setIsGroupsLoading(true);
    try {
      const response = await getGroups(
        pagination.currentPage,
        pagination.pageSize,
        debouncedSearchValue
      );
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setGroups(response.data.items);
      setPagination((prevState) => ({ ...prevState, totalPagesCount, totalItemsCount }));
    } catch (e: any) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsGroupsLoading(false);
    }
  }, [pagination.currentPage, pagination.pageSize, openNotification, debouncedSearchValue]);

  const onChangePagination = (
    currentPage: number,
    pageSize: number | undefined = PAGE_SIZE
  ): void => {
    setPagination((prevState) => ({ ...prevState, currentPage, pageSize }));
  };

  return {
    groups,
    isGroupsLoading,
    searchValue,
    pagination,
    onChangePagination,
    fetchGroups,
    setSearchValue
  };
};

export default useGroups;
