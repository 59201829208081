export const BOW_COLUMNS = [
  {
    title: 'Bow serial number',
    dataIndex: 'productSerialNumber',
    key: 'productSerialNumber',
    sortKey: 'productSerialNumber',
    ellipsis: true,
    sorter: { multiple: 1 }
  },
  {
    title: 'Bow user',
    dataIndex: 'email',
    key: 'users.email',
    sortKey: 'users.email',
    ellipsis: true,
    sorter: { multiple: 1 }
  },
  {
    title: 'Registered',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortKey: 'createdAt',
    ellipsis: true,
    sorter: { multiple: 1 }
  }
];
