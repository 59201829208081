import React, { useMemo } from 'react';
import { PostedShot, ReportObjectType, SessionType } from 'shared/types';
import { Duration, FloatGraph, Icon, Level, Steadiness, Title, Torque } from 'shared/components';
import * as Styled from './styles';
import AvatarPlaceholder from 'assets/images/bow-ava.png';
import { getShotTime, truncateName } from 'utils/report-utils';
import _ from 'lodash';

type Props = {
  shot: PostedShot;
  reportObjectType: ReportObjectType;
};

const sessionTypeToTitle: Record<SessionType, string> = {
  hunting: 'Hunt/Event',
  training: 'Training',
  tournament: 'Hunt/Event'
};

const ShotDetails: React.FC<Props> = (props): JSX.Element => {
  const { shot, reportObjectType } = props;

  const color = useMemo(
    (): string => (['hunting', 'tournament'].includes(shot.sessionType) ? '#C1B8AF' : '#707070'),
    [shot.sessionType]
  );

  const shouldDisplayCharts = useMemo(
    (): boolean =>
      Object.values(_.pick(shot.configuration, ['steadiness', 'duration', 'level', 'torque'])).some(
        Boolean
      ),
    [shot]
  );

  return (
    <Styled.Shot>
      <Styled.UserInfo>
        <Styled.AvatarContainer>
          <Styled.Avatar src={shot.user.profilePictureS3Url || AvatarPlaceholder} />
        </Styled.AvatarContainer>
        {shot.user.username && (
          <Title
            title={truncateName(shot.user.username, 40)}
            type='regular'
            size='lg'
            containerStyles={{ marginLeft: 7 }}
          />
        )}
      </Styled.UserInfo>
      <Styled.Logo>
        <Title
          title={sessionTypeToTitle[shot.sessionType] || shot.sessionType}
          size='xxs'
          color={color}
          type='defaultRegular'
        />
        <Title title='SHOT' size='xxs' color={color} type='defaultRegular' />
        <Icon
          name={shot.sessionType === 'training' ? 'training' : 'hunting'}
          size={31}
          color={color}
        />
      </Styled.Logo>
      <Styled.Date>
        <Title
          title={new Date(shot?.publishedAt || shot?.timestamp).toLocaleDateString('en-US')}
          uppercase={false}
          type='defaultRegular'
          size='sm'
          color='#707070'
        />
      </Styled.Date>
      <Styled.ShotContent color={color}>
        <Styled.Statistic>
          <Styled.StatisticTime>
            <Title title={getShotTime(shot.timestamp).fullTime} variant='dark' color='#231F20' />
          </Styled.StatisticTime>
          <Styled.StatisticDistance>
            <Title
              title={'DISTANCE'}
              variant='light'
              color='#231F20'
              containerStyles={{ marginRight: 10, paddingTop: 2 }}
            />
            <Title title={`${shot.distance} YDS`} type='regular' size='lg' color='#231F20' />
          </Styled.StatisticDistance>
          <Styled.StatisticScore>
            <Title
              title={'SCORE'}
              variant='dark'
              color='#231F20'
              containerStyles={{ flex: 1, display: 'flex', justifyContent: 'center' }}
            />
            <Title
              title={String(shot.shotScoreTotal)}
              type='bold'
              size={'xl'}
              color='#FFD600'
              containerStyles={{
                display: 'flex',
                backgroundColor: '#231F20',
                padding: '0 5px'
              }}
              titleStyles={{ position: 'relative', top: -2 }}
            />
          </Styled.StatisticScore>
        </Styled.Statistic>
        {shouldDisplayCharts && (
          <Styled.ChartGroup>
            {shot.configuration.level && (
              <Level value={shot.shotScoreBowAngle} bowLevelDegrees={shot.bowLevelDegrees} />
            )}
            {shot.configuration.steadiness && (
              <Steadiness
                value={shot.shotScoreAimSteadiness}
                aimSteadinessValueDps={shot.aimSteadinessValueDps}
              />
            )}
            {shot.configuration.torque && (
              <Torque value={shot.shotScoreBowTorque} bowTorque={shot.bowTorque} />
            )}
            {shot.configuration.duration && (
              <Duration value={shot.shotScoreAimTime} aimTime={shot.aimTime} />
            )}
          </Styled.ChartGroup>
        )}
        {shot.configuration.imageType === 'TARGET' ? (
          <Styled.Target>
            <FloatGraph
              radius={131}
              preShotAimGraphData={shot.aimGraphDataPre || []}
              postShotAimGraphData={[]}
              fov={10}
              containerStyles={{ marginBottom: 10 }}
            />
          </Styled.Target>
        ) : (
          <Styled.PictureSection withBorder={reportObjectType === 'picture_shot'}>
            <Styled.PictureContainer>
              <Styled.Picture src={shot.shotPictureS3Url || AvatarPlaceholder} />
            </Styled.PictureContainer>
          </Styled.PictureSection>
        )}
      </Styled.ShotContent>
    </Styled.Shot>
  );
};

export default ShotDetails;
