import React from 'react';
import * as Styled from './styles';
import { Button, Input } from 'antd';
import { useFormik } from 'formik';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { validation } from 'services/validation';
import { login } from 'services/api/authService';
import { useAppDispatch, useNotifications } from 'shared/hooks';
import { loginUserFail, loginUserSuccess } from 'services/store/reducers/authReducer';
import { ESnackbarStyle } from 'shared/types';
import { getFieldError } from 'utils/error-utils';

type FormValues = {
  email: string;
  password: string;
};

const LoginPage = (): JSX.Element => {
  const initialValues: FormValues = {
    email: '',
    password: ''
  };

  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const formik = useFormik({
    initialValues,
    onSubmit: async (values): Promise<void> => {
      try {
        const response = await login(values.email, values.password);
        localStorage.setItem('token', response.data.token);
        dispatch(loginUserSuccess(response.data));
      } catch (e) {
        dispatch(loginUserFail());
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    validationSchema: validation.LOG_IN
  });

  return (
    <Styled.LoginPageContainer>
      <Styled.LoginForm onSubmit={formik.handleSubmit}>
        <Styled.LoginFormTitle>Please login</Styled.LoginFormTitle>
        <Styled.LoginFormInputContainer>
          <Input
            placeholder='Email'
            size='large'
            value={formik.values.email}
            name='email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
          />
          {getFieldError(formik, 'email')}
        </Styled.LoginFormInputContainer>
        <Styled.LoginFormInputContainer>
          <Input.Password
            placeholder='Password'
            size='large'
            value={formik.values.password}
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible): JSX.Element =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            disabled={formik.isSubmitting}
          />
          {getFieldError(formik, 'password')}
        </Styled.LoginFormInputContainer>
        <Button
          htmlType='submit'
          type='primary'
          block
          size='large'
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          Login
        </Button>
      </Styled.LoginForm>
    </Styled.LoginPageContainer>
  );
};

export default LoginPage;
