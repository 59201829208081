import React, { CSSProperties } from 'react';
import * as Styled from './styles';
import AvatarPlaceholder from 'assets/images/bow-ava.png';
import { SessionComment, ShotComment } from '../../types';
import { Title } from 'shared/components';
import { getFullName, formatTimestamp } from 'utils/report-utils';

type Props = {
  comment: SessionComment | ShotComment;
  containerStyles?: CSSProperties;
};

const Comment: React.FC<Props> = (props): JSX.Element => {
  const {
    comment: { user, createdAt, content },
    containerStyles
  } = props;

  return (
    <Styled.Comment style={containerStyles}>
      <Styled.AvatarContainer>
        <Styled.Avatar src={user.profilePictureS3Url || AvatarPlaceholder} />
      </Styled.AvatarContainer>
      <Styled.CommentDetails>
        <Styled.UserInfo>
          <Title
            title={getFullName(user)}
            type='regular'
            size='lg'
            color='#FFF'
            containerStyles={{ flexShrink: 1, marginRight: 15 }}
          />
          <Title
            title={formatTimestamp(createdAt)}
            uppercase={false}
            type='defaultRegular'
            size='xs'
            color='#707070'
            containerStyles={{ paddingTop: 2 }}
          />
        </Styled.UserInfo>
        <Title
          title={content}
          uppercase={false}
          type='defaultRegular'
          size='xs'
          color='#FFF'
          containerStyles={{ marginTop: 10 }}
        />
      </Styled.CommentDetails>
    </Styled.Comment>
  );
};

export default Comment;
