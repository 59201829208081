import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const VersionsGroup = styled.div`
  margin-top: 30px;
  font-weight: bold;
`;

export const ScheduledVersion = styled.div`
  margin-top: 10px;

  & span:last-child {
    margin-left: 30px;
  }
`;

export const SearchInput = styled.div`
  margin: 30px 0 0 auto;
  width: 300px;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow: auto;

  & tbody tr {
    cursor: pointer;
  }

  & tbody tr td:last-child {
    width: 35%;

    @media ${DEVICE.tablet} {
      width: 25%;
    }
  }
`;