import { AxiosResponse } from 'axios';
import { EFirmwareStatus, EFirmwareUpdateChannel, FirmwareVersion, Pagination } from 'shared/types';
import { getQueryParams, getSortQueryParams } from 'utils/query-utils';
import ApiService from './api';

type FirmwaresResponse = {
  items: FirmwareVersion[];
  pagination: Pagination;
};

type UpdateFirmwareRequest = {
  status?: EFirmwareStatus;
  scheduledAt?: string | null;
  changelog?: string;
};

const getURL = (channelId: EFirmwareUpdateChannel, ...rest: string[]): string => {
  const mainUrl = `firmware/channel/${channelId}/versions`;
  return rest.reduce((resultUrl: string, item: string): string => resultUrl + `/${item}`, mainUrl);
};

export const getFirmwareVersions = async (
  page: number,
  limit: number,
  channelId: EFirmwareUpdateChannel,
  sortParams: string[],
  search: string
): Promise<AxiosResponse<FirmwaresResponse>> => {
  const params = getQueryParams({ page, limit, search });
  const urlSortParams = !!sortParams.length
    ? `&${getSortQueryParams(sortParams)}`
    : '&orderBy=-version';
  return await ApiService.get<FirmwaresResponse>(`${getURL(channelId)}?${params}${urlSortParams}`);
};

export const getFirmwareByStatus = async (
  channelId: EFirmwareUpdateChannel,
  status: EFirmwareStatus
): Promise<AxiosResponse<FirmwareVersion>> => {
  const params = getQueryParams({ [status]: true });
  return await ApiService.get<FirmwareVersion>(`${getURL(channelId, 'latest')}?${params}`);
};

export const addFirmwareVersion = async (
  file: FormData,
  channelId: EFirmwareUpdateChannel
): Promise<AxiosResponse<FirmwareVersion>> =>
  await ApiService.post<FormData, FirmwareVersion>(getURL(channelId), file);

export const updateFirmwareVersion = async (
  versionId: string,
  channelId: EFirmwareUpdateChannel,
  firmware: UpdateFirmwareRequest
): Promise<AxiosResponse<FirmwareVersion>> =>
  await ApiService.patch<UpdateFirmwareRequest, FirmwareVersion>(
    getURL(channelId, versionId),
    firmware
  );

export const deleteFirmwareVersion = async (
  versionId: string,
  channelId: EFirmwareUpdateChannel
): Promise<AxiosResponse<FirmwareVersion>> =>
  await ApiService.delete<FirmwareVersion>(getURL(channelId, versionId));

export const downloadFirmware = async (fileId: string): Promise<AxiosResponse<Blob>> =>
  await ApiService.get<Blob>(`firmware/files/${fileId}/download`, { responseType: 'blob' });
