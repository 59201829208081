export const MESSAGES_COLUMNS = [
  {
    title: 'Message',
    dataIndex: 'message',
    ellipsis: false,
  },
  {
    title: 'Author Username',
    dataIndex: 'authorUsername',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Author Email',
    dataIndex: 'authorEmail',
    ellipsis: true,
    width: 260
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    ellipsis: true,
    width: 170,
  },
];
