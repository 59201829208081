// Libs
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

// Components
import { Button, Input, PageHeader, Table } from 'antd';
import { ConfirmModal, ContentContainer } from 'shared/components';

// Services
import {
  deleteEvent, getEventById, getEventUsers,
} from 'services/api/eventsService';

// Hooks
import { useHistory, useParams } from 'react-router-dom';

// Constants
import { COLUMNS, EXPANDED_ROW_COLUMNS } from './constants';

// Types
import { ESnackbarStyle, Event, EventUser } from 'shared/types';

// Styles
import * as Styled from './styles';
import { DeleteOutlined } from '@ant-design/icons';
import {
  closeModal, showModal,
} from '../../services/store/reducers/modalReducer';
import { useAppDispatch, useNotifications } from '../../shared/hooks';

type QueryParams = {
  groupId: string;
  eventId: string;
};

const GroupEventPage = (): JSX.Element => {
  const [event, setEvent] = useState<Event | null>(null);
  const [users, setUsers] = useState<EventUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const history = useHistory();
  const { eventId, groupId } = useParams<QueryParams>();
  const dispatch = useAppDispatch();
  const { openNotification } = useNotifications();

  const filteredUsers = useMemo(() => {
    return users.filter((record: EventUser) =>
      Object.values(_.pick(record, ['id', 'username', 'email', 'firstName', 'lastName'])).some(
        (value) =>
          !_.isUndefined(value) && String(value).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [users, searchValue]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  const initialize = async () => {
    try {
      setLoading(true);
      await fetchEvent(eventId);
      await fetchEventUsers(eventId);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvent = async (eventId: string): Promise<void> => {
    try {
      const response = await getEventById(eventId);
      setEvent(response.data);
    } catch (err: any) {
      // tslint:disable-next-line:no-console
      console.error('[fetchEventUsers] ', err);
    }
  };

  const fetchEventUsers = async (eventId: string): Promise<void> => {
    try {
      const response = await getEventUsers(eventId);
      setUsers(response.data);
    } catch (err: any) {
      // tslint:disable-next-line:no-console
      console.error('[fetchEventUsers] ', err);
    }
  };

  const showRemoveEventModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this event?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await deleteEvent(eventId);
            history.push(`/group-events/${groupId}`);
            openNotification(ESnackbarStyle.SUCCESS, 'Event successfully deleted');
          }}
        />
      )
    );
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={() => history.goBack()} title={`Event - ${event?.name ?? eventId}`} />

        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input.Search
              placeholder='Search by user'
              onSearch={(value): void => setSearchValue(value)}
              allowClear
            />
          </Styled.SearchInput>
          <Styled.RemoveButton>
            <Button
              icon={<DeleteOutlined />}
              type='default'
              onClick={showRemoveEventModal}
            >
              Remove event
            </Button>
          </Styled.RemoveButton>
        </Styled.PageHeader>

        <Styled.TableContainer>
          <Table
            rowKey='id'
            size={'middle'}
            pagination={false}
            loading={loading}
            dataSource={filteredUsers}
            columns={COLUMNS}
            expandable={{
              expandedRowRender: (record: EventUser) => (
                <Table
                  columns={EXPANDED_ROW_COLUMNS}
                  dataSource={record.shots}
                  pagination={false}
                />
              ),
              rowExpandable: (record) => record.shots.length > 0
            }}
          />
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default GroupEventPage;
