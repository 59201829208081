import React, { useEffect } from 'react';
import {  ConfirmModal, ContentContainer } from 'shared/components';
import * as Styled from './styles';
import { Button, Input, PageHeader, Upload } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import { useAppDispatch, useNotifications, useGroup } from 'shared/hooks';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import { updateGroup } from 'services/api/groupsService';
import { ESnackbarStyle } from 'shared/types';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { getFieldError } from 'utils/error-utils';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

type FormValues = {
  name: string;
  image: RcFile | null;
};

type QueryParams = {
  groupId: string;
}

const UserGroupPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { groupId } = useParams<QueryParams>();
  const { openNotification } = useNotifications();
  const { group, isGroupLoading, fetchGroup, removeGroup } = useGroup();

  useEffect((): void => {
    fetchGroup(groupId);
  }, [fetchGroup, groupId]);

  const initialValues: FormValues = {
    name: group?.name || '',
    image: null,
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      try {
        const formData = new FormData();

        formData.append('name', values.name);
        if (values.image) {
          formData.append('image', values.image!);
        }

        await updateGroup(groupId, formData);
        history.push('/groups');
        openNotification(ESnackbarStyle.SUCCESS, 'Group successfully updated');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      }
    },
    initialValues,
    validationSchema: validation.EDIT_GROUP,
    enableReinitialize: true
  });

  const { values, setFieldValue } = formik;

  const handleImageChange = (file: UploadChangeParam<UploadFile<File>>): void => {
    if (file?.fileList[0]) {
      setFieldValue('image', file.fileList[0].originFileObj);
    } else {
      setFieldValue('image', null);
    }
  };

  const goBack = (): void => {
    history.goBack();
  };

  const showRemoveGroupModal = (): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title='Do you really want to remove this group?'
          confirmAction={async (): Promise<void> => {
            dispatch(closeModal());
            await removeGroup(groupId);
            history.push('/groups');
            openNotification(ESnackbarStyle.SUCCESS, 'Group successfully deleted');
          }}
        />
      )
    );
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Edit Group' />
        <Styled.Form onSubmit={formik.handleSubmit}>
          {group?.groupPictureS3Url &&
            <Styled.Img>
              <img src={group?.groupPictureS3Url} alt={''}/>
            </Styled.Img>
          }
          <Styled.FormInputContainer>
            <Upload
              name='image'
              fileList={values.image ? [values.image] : []}
              onChange={handleImageChange}
              beforeUpload={(): boolean => false}
            >
              <Button icon={<UploadOutlined />}>Choose file</Button>
            </Upload>
          </Styled.FormInputContainer>
          <Styled.FormInputContainer>
            <Input
              placeholder='Group name'
              size='large'
              value={formik.values.name}
              name='name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isGroupLoading || formik.isSubmitting}
            />
            {getFieldError(formik, 'firstName')}
          </Styled.FormInputContainer>
          <Styled.FormActions>
            <Button
              type='primary'
              htmlType='submit'
              disabled={isGroupLoading || formik.isSubmitting || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Apply
            </Button>
          </Styled.FormActions>
          <Styled.RemoveButton>
            <Button
              icon={<DeleteOutlined />}
              type='default'
              onClick={showRemoveGroupModal}
              disabled={isGroupLoading || formik.isSubmitting}
            >
              Remove group
            </Button>
          </Styled.RemoveButton>
        </Styled.Form>
      </Styled.PageContainer>
    </ContentContainer>
  );
}

export default UserGroupPage;
