import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 1250px;
  padding: 15px 15px 30px 15px;
  min-height: 100vh;

  @media ${DEVICE.tabletLarge} {
    padding-top: 50px;
  }
`;
