import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const Form = styled.form`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  height: 68px;
`;

export const FormActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const RemoveButton = styled.div`
  margin-top: 30px;
  width: max-content;

  & button {
    background: #f5222d;
    color: #fff;
    border-radius: 4px;
    transition: all .2s;
  }

  & button:hover {
    opacity: 0.8;
    background: #f5222d;
    color: #fff;
    border: 1px solid #fff;
  }
`;
