import { useCallback, useState } from 'react';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, RequestParams, ShotEvent } from 'shared/types';
import { getDeviceEvents } from 'services/api/devicesService';
import { PAGE_SIZE } from 'shared/constants/pagination';

type Result = {
  events: ShotEvent[];
  fetchDeviceEvents: () => Promise<void>;
  requestEventsParams: RequestParams;
  setRequestEventsParams: (requestParams: RequestParams) => void;
  totalPages: number;
  totalEvents: number;
  isEventsLoading: boolean;
  setEventsSortParams: (values: string[]) => void;
};

const useEventsData = (bowSerialNumber: string): Result => {
  const [events, setEvents] = useState<ShotEvent[]>([]);
  const [requestEventsParams, setRequestEventsParams] = useState<RequestParams>({
    dateRange: { start: null, end: null },
    currentPage: 1
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEvents, setTotalEvents] = useState<number>(0);
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true);
  const [eventsSortParams, setEventsSortParams] = useState<string[]>([]);
  const { openNotification } = useNotifications();

  const fetchDeviceEvents = useCallback(async (): Promise<void> => {
    setIsEventsLoading(true);
    try {
      const { start, end } = requestEventsParams.dateRange;
      const { currentPage } = requestEventsParams;
      const response = await getDeviceEvents(
        bowSerialNumber,
        currentPage,
        PAGE_SIZE,
        start,
        end,
        eventsSortParams
      );
      setEvents(response.data.items);
      setTotalPages(response.data.pagination.totalPagesCount);
      setTotalEvents(response.data.pagination.totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsEventsLoading(false);
    }
  }, [openNotification, bowSerialNumber, requestEventsParams, eventsSortParams]);

  return {
    events,
    fetchDeviceEvents,
    requestEventsParams,
    setRequestEventsParams,
    totalPages,
    totalEvents,
    isEventsLoading,
    setEventsSortParams
  };
};

export default useEventsData;
