import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';

const GuardedRoute: React.FC<RouteProps> = ({ ...routeProps }): JSX.Element => {
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);

  if (!isAuth) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: routeProps.location }
        }}
      />
    );
  }

  return <Route {...routeProps} />;
};

export default GuardedRoute;
