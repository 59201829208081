import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { ESnackbarStyle, Pagination, Report } from 'shared/types';
import { useNotifications } from 'shared/hooks';
import { getReports } from 'services/api/reportsService';

type Result = {
  items: Report[];
  pagination: Pagination;
  status: string;
  isReportsLoading: boolean;
  setStatus: Dispatch<SetStateAction<string>>;
  fetchReports: () => Promise<void>;
  onChangePagination: (currentPage: number, pageSize?: number) => void;
};

const useReports = (): Result => {
  const [reports, setReports] = useState<Report[]>([]);
  const [isReportsLoading, setIsReportsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState('');
  const [pagination, setPagination] = useState<Pagination>({
    totalItemsCount: 0,
    totalPagesCount: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE
  });

  const { openNotification } = useNotifications();

  const fetchReports = useCallback(async (): Promise<void> => {
    setIsReportsLoading(true);
    try {
      const response = await getReports({
        page: pagination.currentPage,
        limit: pagination.pageSize,
        status
      });
      const {
        items,
        pagination: { totalItemsCount, totalPagesCount }
      } = response.data;
      setReports(items);
      setPagination((prevState) => ({ ...prevState, totalItemsCount, totalPagesCount }));
    } catch (e: any) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsReportsLoading(false);
    }
  }, [pagination.currentPage, pagination.pageSize, status, openNotification]);

  const onChangePagination = (
    currentPage: number,
    pageSize: number | undefined = PAGE_SIZE
  ): void => {
    setPagination((prevState) => ({ ...prevState, currentPage, pageSize }));
  };

  return {
    status,
    pagination,
    items: reports,
    isReportsLoading,
    setStatus,
    fetchReports,
    onChangePagination
  };
};

export default useReports;
