import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 250px;
  width: 100%;

  @media (max-width: 530px) {
    flex-direction: column;
    height: 370px;
  }
`;

export const DiagramContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const EventListContainer = styled.div`
  margin-left: 30px;
  width: 220px;

  @media (max-width: 530px) {
    margin-left: 0;
  }
`;

export const EventColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background: ${({ color }): string => color};
  border-radius: 4px;
`;

export const EventContainer = styled.div<{ isActiveSection: boolean }>`
  display: flex;
  justify-content: center;
  width: 220px;
  padding: 7px 0;
  transform: ${({ isActiveSection }): string => (isActiveSection ? 'scale(1.03)' : 'scale(1)')};
  border-bottom: ${({ isActiveSection }): string => (isActiveSection ? '1px solid #ccc' : '0')};
  cursor: pointer;
`;

export const EventTitle = styled.div`
  margin: 0 20px;
`;

export const EventTotal = styled.span`
  margin-left: auto;
  font-weight: bold;
`;
