import React, { useEffect, useMemo } from 'react';
import { checkRolePermission } from 'utils/role-utils';
import { EUserRole, Report, User } from 'shared/types';
import { Redirect } from 'react-router';
import { useAppSelector, useReports } from 'shared/hooks';
import * as Styled from './styles';
import { PageHeader, Table } from 'antd';
import { PaginationContainer } from 'shared/styles';
import Pagination from 'antd/lib/pagination';
import { ContentContainer } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { REPORT_COLUMNS } from './constants';
import _ from 'lodash';
import { ColumnType } from 'antd/lib/table/interface';

type TableReport = Report & { key: number };

type Option = {
  value: string;
  label: string;
  disabled: boolean;
};

const ReportsPage = (): JSX.Element => {
  const history = useHistory();
  const user = useAppSelector((state): User | null => state.auth.user);

  const {
    status,
    pagination,
    items,
    isReportsLoading,
    setStatus,
    fetchReports,
    onChangePagination
  } = useReports();

  useEffect((): void => {
    fetchReports();
  }, [fetchReports]);

  const tableReports = useMemo(
    (): TableReport[] => items.map((report): TableReport => ({ ...report, key: report.id })),
    [items]
  );

  const handleRowClick = (report: TableReport): void => {
    history.push(`/reports/${report.id}`);
  };

  const handleOpenUser = (userId: number): void => history.push(`/bow-users/${userId}`);

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.moderator, EUserRole.admin_user, EUserRole.master_admin],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Reports' />
        <Styled.ReportSelect
          defaultValue=''
          onChange={(value): void => setStatus(value as string)}
          options={['', 'pending', 'valid', 'invalid'].map(
            (value): Option => ({
              value,
              label: value ? _.capitalize(value) : 'All',
              disabled: status === value
            })
          )}
        />
        <Styled.TableContainer>
          <Table
            columns={[
              ...REPORT_COLUMNS.map((column): ColumnType<TableReport> => {
                return {
                  ...column,
                  onCell: (record: TableReport): { onClick: () => void } => {
                    return {
                      onClick: (): void => handleRowClick(record)
                    };
                  }
                };
              }),
              {
                title: 'Users',
                width: 180,
                render: (record: TableReport): JSX.Element => {
                  return (
                    <>
                      <Styled.ReportAction onClick={(): void => handleOpenUser(record.accusedId)}>
                        Reported User
                      </Styled.ReportAction>
                      <Styled.ReportAction onClick={(): void => handleOpenUser(record.reporterId)}>
                        Creator
                      </Styled.ReportAction>
                    </>
                  );
                }
              }
            ]}
            dataSource={tableReports}
            pagination={false}
            loading={isReportsLoading}
            size={'middle'}
          />
          <PaginationContainer>
            <Pagination
              pageSize={pagination.pageSize}
              current={pagination.currentPage}
              total={pagination.totalItemsCount}
              onChange={onChangePagination}
            />
          </PaginationContainer>
        </Styled.TableContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default ReportsPage;
