export const USER_COLUMNS = [
  {
    title: 'User name',
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    sorter: { multiple: 1 },
    sortKey: 'firstName'
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    ellipsis: true,
    sorter: { multiple: 1 },
    sortKey: 'role'
  },
  {
    title: 'Added',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: { multiple: 1 },
    sortKey: 'createdAt'
  }
];
