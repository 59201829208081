import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { ContentContainer } from 'shared/components';
import { PageHeader, Table, Input, Typography } from 'antd';
import { useUsers } from 'shared/hooks';
import { EUserRole, EUserStatus } from 'shared/types';
import { format } from 'date-fns';
import Pagination from 'antd/lib/pagination';
import { PaginationContainer } from 'shared/styles';
import { ROLES } from 'shared/constants/roles';
import { handleSortAction } from 'utils/sorting-utils';
import { USER_COLUMNS } from './constants';
import { useHistory } from 'react-router-dom';

type TableUser = {
  id: number;
  key: number;
  firstName: string;
  username: string;
  status: EUserStatus;
  role: string;
  createdAt: string;
  totalShots: number;
  lastShot: string;
};

const BowUsersPage = (): JSX.Element => {
  const history = useHistory();
  const [tableUsers, setTableUsers] = useState<TableUser[]>([]);
  const {
    users,
    pagination,
    searchValue,
    isUsersLoading,
    fetchUsers,
    setSortParams,
    setSearchValue,
    onChangePagination
  } = useUsers();

  useEffect((): void => {
    fetchUsers(true);
  }, [fetchUsers]);

  useEffect((): void => {
    // tslint:disable-next-line:typedef
    const modifiedUsers = users
      .filter((user) => user.role === EUserRole.application_user)
      .map(
        (user): TableUser => ({
          id: user.id,
          key: user.id,
          username: user.username || '-',
          firstName: [user.firstName, user.lastName].filter(Boolean).join(' ') || '-',
          status: user.status,
          role: ROLES.find((item): boolean => item.value === user?.role)?.label || '',
          createdAt: format(new Date(user.createdAt), 'yyyy-MM-dd'),
          totalShots: user.totalShots,
          lastShot: user.lastShot ? format(new Date(user.lastShot), 'yyyy-MM-dd') : '-'
        })
      );
    setTableUsers(modifiedUsers);
  }, [users]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleRowClick = (user: TableUser): void => {
    history.push(`/bow-users/${user.id}`);
  };

  const generateActions = (
    record: TableUser
  ): { label: string; subLabel?: string; action: () => void }[] => {
    return [
      {
        label: `Shots`,
        subLabel: `(${record.totalShots})`,
        action: () => history.push(`/shots/${record.id}`)
      },
      {
        label: 'Sessions',
        action: () => history.push(`/bow-users/${record.id}/sessions`, { userId: record.id })
      },
      { label: 'Logs', action: () => history.push('/activity-log', { userId: record.id }) }
    ];
  };

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader title='Users' />
        <Styled.PageHeader>
          <Styled.SearchInput>
            <Input
              placeholder='Search'
              onChange={handleSearchInputChange}
              value={searchValue}
              allowClear
            />
          </Styled.SearchInput>
        </Styled.PageHeader>
        <Styled.TableContainer>
          <Table
            columns={[
              ...USER_COLUMNS.map((obj): any => {
                return {
                  ...obj,
                  onCell: (record: TableUser): any => {
                    return {
                      onClick: (): void => handleRowClick(record)
                    };
                  }
                };
              }),
              {
                title: 'Actions',
                width: 270,
                render: (record): JSX.Element => (
                  <Styled.TableActionRow>
                    {generateActions(record).map((action, index) => (
                      <Styled.TableAction key={`${action.label}_${index}`} onClick={action.action}>
                        <Typography.Link>
                          {action.label}
                          {action.subLabel && <span className='subLabel'> {action.subLabel}</span>}
                        </Typography.Link>
                      </Styled.TableAction>
                    ))}
                  </Styled.TableActionRow>
                )
              }
            ]}
            dataSource={tableUsers}
            pagination={false}
            loading={isUsersLoading}
            size={'middle'}
            onChange={handleSortAction(setSortParams)}
          />
        </Styled.TableContainer>
        <PaginationContainer>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.currentPage}
            total={pagination.totalItemsCount}
            onChange={onChangePagination}
          />
        </PaginationContainer>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default BowUsersPage;
