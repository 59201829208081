import styled from 'styled-components';

export const Shot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const UserInfo = styled.div`
  position: absolute;
  top: -22px;
  left: -15px;
  display: flex;
`;

export const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-right: 5px solid #363636;
  border-bottom: 5px solid #363636;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

export const Logo = styled.div`
  position: absolute;
  top: -57px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  width: 86px;
  height: 86px;
  background-color: #363636;
  border-bottom-right-radius: 43px;
  border-bottom-left-radius: 43px;
  z-index: 2;
`;

export const Date = styled.div`
  position: absolute;
  top: -22px;
  right: 5px;
`;

export const ShotContent = styled.div<{ color: string }>`
  border-radius: 10px;
  padding: 18px 13px 0;
  background-color: ${(props): string => props.color};
`;

export const Statistic = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatisticTime = styled.div`
  display: flex;
  justify-content: center;
  border: 3px solid #231f20;
  border-radius: 4px;
  width: 86px;
  height: 30px;
`;

export const StatisticDistance = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const StatisticScore = styled.div`
  display: flex;
  justify-content: space-between;
  border: 3px solid #231f20;
  border-radius: 4px;
  width: 86px;
  height: 30px;
`;

export const ChartGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 6px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #231f20;
`;

export const Target = styled.div`
  margin-top: 15px;
  padding-top: 22px;
  padding-bottom: 10px;
  border: 1px solid #707070;
  border-radius: 6px;
  background-color: #363636;
`;

export const PictureSection = styled.div<{ withBorder: boolean }>`
  margin: 0 -13px;
  padding: 15px 13px;
  border: ${({ withBorder }): string => (withBorder ? '3px solid #1677FF' : 'unset')};
`;

export const PictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 155px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #363636;
`;

export const Picture = styled.img`
  width: 100%;
  height: 320px;
`;
