import styled from 'styled-components';

export const LoginPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const LoginForm = styled.form`
  position: relative;
  max-width: 328px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginFormTitle = styled.div`
  line-height: 44px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
  text-align: center;
  margin-bottom: 24px;
`;

export const LoginFormInputContainer = styled.div`
  width: 100%;
  height: 68px;
`;
