import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-page-header {
    padding: 16px 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NewBowButton = styled.div`
  width: 270px;
`;

export const TableContainer = styled.div`
  flex: 1;
  margin-top: 24px;

  & tbody tr {
    cursor: pointer;
  }
`;
