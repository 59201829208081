import { PublicGroupStatus } from 'shared/types';
import { ReactNode } from 'react';
import { TypeRow } from './styles';
import { parseISODateFormat } from '../../utils/date-utils';
import { PUBLIC_GROUPS_STATUSES } from '../../shared/constants/publicGroups';
import { getPublicGroupColor } from '../../utils/public-groups';

export const PUBLIC_GROUPS_COLUMNS = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: (status: PublicGroupStatus): ReactNode => {
      return (
        <TypeRow color={getPublicGroupColor(status)}>{PUBLIC_GROUPS_STATUSES[status]}</TypeRow>
      );
    }
  },
  {
    title: 'Type',
    dataIndex: 'isNew',
    key: 'isNew',
    ellipsis: true,
    render: (isNew: boolean): ReactNode => {
      return (
        <>{isNew ? 'New group' : 'Existing group'}</>
      );
    }
  },
  {
    title: 'Date',
    dataIndex: 'moderationDate',
    key: 'moderationDate',
    ellipsis: true,
    render: (date: string): ReactNode => {
      return <>{parseISODateFormat(date, true)}</>;
    }
  }
];
