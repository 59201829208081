import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ConfirmModal, ContentContainer, Title } from 'shared/components';
import * as Styled from './styles';
import { Modal, PageHeader, Radio, Table } from 'antd';
import { useHistory, useParams } from 'react-router';
import {
  useAppDispatch, useAppSelector, useNotifications, usePublicGroup,
} from 'shared/hooks';
import { ESnackbarStyle, EUserRole, User } from 'shared/types';
import { checkRolePermission } from 'utils/role-utils';
import { Redirect } from 'react-router-dom';
import { parseISODateFormat } from 'utils/date-utils';
import { REPORT_COLUMNS } from './constants';
import { getPublicGroupColor } from 'utils/public-groups';
import { PUBLIC_GROUPS_STATUSES } from 'shared/constants/publicGroups';
import GroupImg from 'assets/images/group-ava.jpg';
import {
  closeModal, showModal,
} from '../../services/store/reducers/modalReducer';

type QueryParams = {
  publicGroupId: string;
};

type TableRow = {
  key: string;
  value: string | ReactNode;
};

const PublicGroupPage = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { publicGroupId } = useParams<QueryParams>();

  const {
    publicGroup,
    isPublicGroupLoading,
    fetchPublicGroup,
    rejectPublicGroup,
    acceptPublicGroup
  } = usePublicGroup();

  const { openNotification } = useNotifications();

  const [rejectModal, setRejectModal] = useState(false);
  const [type, setType] = useState<'name' | 'image'>('name');

  const user = useAppSelector((state): User | null => state.auth.user);

  useEffect((): void => {
    fetchPublicGroup(publicGroupId);
  }, [fetchPublicGroup, publicGroupId]);

  const tableRows = useMemo(
    (): TableRow[] =>
      publicGroup
        ? [
            {
              key: 'Name',
              value: publicGroup.name
            },
            {
              key: 'Status',
              value: (
                <Styled.StatusRow color={getPublicGroupColor(publicGroup.status)}>
                  {PUBLIC_GROUPS_STATUSES[publicGroup.status]}
                </Styled.StatusRow>
              )
            },
            {
              key: 'Type',
              value: publicGroup.isNew ? 'New group' : 'Existing group'
            },
            { key: 'Date', value: parseISODateFormat(publicGroup.moderationDate, true) }
          ]
        : [],
    [publicGroup]
  );

  const goBack = (): void => {
    history.goBack();
  };

  const showApproveModal = (publicGroupId: string): void => {
    dispatch(
      showModal(
        <ConfirmModal
          title={'By clicking the "Accept" button, you confirm that the content of the group complies with the rules. After clicking the "Accept" button, the group became public.'}
          modalTitle={'Confirmation of the request to create a public group.'}
          confirmAction={async (): Promise<void> => {
            try {
              dispatch(closeModal());
              await acceptPublicGroup(publicGroupId);
              history.push('/public-groups');
              openNotification(ESnackbarStyle.SUCCESS, 'Request confirmed!');
            } catch (e) {
              openNotification(ESnackbarStyle.ERROR, e.message);
            }
          }}
        />
      )
    );
  };

  if (
    user?.role &&
    !checkRolePermission(
      [EUserRole.master_admin, EUserRole.admin_user, EUserRole.moderator],
      user.role
    )
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ContentContainer>
      <Styled.PageContainer>
        <PageHeader onBack={goBack} title='Public Group Details' />
        {publicGroup && (
          <Styled.PublicGroupDetails>
            <Styled.TableContainer>
              <Table
                columns={REPORT_COLUMNS}
                dataSource={[
                  ...tableRows,
                  ...(publicGroup.status === 'pending'
                    ? [
                        {
                          key: 'Actions',
                          value: (
                            <Styled.PublicGroupActions>
                              <Styled.PublicGroupAction
                                type='primary'
                                color='#52C41A'
                                onClick={(): void => showApproveModal(publicGroupId)}
                              >
                                Accept
                              </Styled.PublicGroupAction>
                              <Styled.PublicGroupAction
                                type='primary'
                                color='#FF4D4F'
                                onClick={(): void => setRejectModal(true)}
                              >
                                Reject
                              </Styled.PublicGroupAction>
                            </Styled.PublicGroupActions>
                          )
                        }
                      ]
                    : [])
                ]}
                loading={isPublicGroupLoading}
                pagination={false}
                size={'middle'}
                showHeader={false}
              />
            </Styled.TableContainer>
            <Styled.Group>
              <Styled.GroupImg src={publicGroup.imageUrl || GroupImg} />
              <Styled.GroupName>
                <Title
                  title={publicGroup.name}
                  variant='title'
                  titleStyles={{ lineHeight: '23px' }}
                />
                <Title title='GROUP' variant='light' titleStyles={{ lineHeight: '18px' }} />
              </Styled.GroupName>
            </Styled.Group>
          </Styled.PublicGroupDetails>
        )}
        <Modal
          visible={rejectModal}
          title='Rejecting a request to create a public group.'
          onCancel={(): void => setRejectModal(false)}
          onOk={async (): Promise<void> => {
            try {
              await rejectPublicGroup(publicGroupId, type);
              history.push('/public-groups');
              openNotification(ESnackbarStyle.SUCCESS, 'Request rejected!');
            } catch (e) {
              openNotification(ESnackbarStyle.ERROR, e.message);
            }
          }}
        >
          <>
            <Styled.RadioTitle>Choose the reason:</Styled.RadioTitle>
            <Radio.Group
              options={[
                { label: 'INAPPROPRIATE NAME', value: 'name' },
                { label: 'INAPPROPRIATE IMAGE', value: 'image' }
              ]}
              onChange={(e): void => setType(e.target.value)}
              value={type}
              optionType='button'
            />
          </>
        </Modal>
      </Styled.PageContainer>
    </ContentContainer>
  );
};

export default PublicGroupPage;
