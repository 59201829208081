import { useCallback, useState } from 'react';
import { getMessages } from 'services/api/supportMessagesService';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { useNotifications } from 'shared/hooks';
import { ESnackbarStyle, SupportMessage } from 'shared/types';

type Result = {
  messages: SupportMessage[];
  fetchMessages: (useLoader?: boolean) => Promise<void>;
  isMessagesLoading: boolean;
  totalMessages: number;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
};

const useSupportMessages = (userId: string): Result => {
  const [messages, setMessages] = useState<SupportMessage[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalMessages, setTotalMessages] = useState<number>(0);
  const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(true);

  const { openNotification } = useNotifications();

  const fetchMessages = useCallback(async(useLoader: boolean = true): Promise<void> => {
    if (useLoader) {
      setIsMessagesLoading(true);
    }
    try {
      const response = await getMessages(userId, currentPage, PAGE_SIZE);
      const { totalPagesCount, totalItemsCount } = response.data.pagination;
      setTotalPages(totalPagesCount);
      setTotalMessages(totalItemsCount);
      setMessages(response.data.items);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsMessagesLoading(false);
    }
  }, [currentPage, openNotification, userId]);

  return {
    messages,
    totalMessages,
    totalPages,
    currentPage,
    setCurrentPage,
    fetchMessages,
    isMessagesLoading
  }
}

export default useSupportMessages;
