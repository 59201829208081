import { AxiosResponse } from 'axios';
import { User } from 'shared/types';
import ApiService from './api';

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = User & {
  token: string;
};

export const login = async (
  email: string,
  password: string
): Promise<AxiosResponse<LoginResponse>> =>
  await ApiService.post<LoginRequest, LoginResponse>(
    '/auth/login?isAdmin=true',
    { email, password },
    { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }
  );

export const getCurrentUser = async (): Promise<AxiosResponse<LoginResponse>> =>
  await ApiService.get<LoginResponse>('auth/me');
